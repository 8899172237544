@import './fonts.scss';
// @import './antd.scss';
@import '~antd/dist/antd.css';
@import './table.scss';
@import './prints.scss';

@import './helpers';
@import './vars';

@import './components/Export';
@import './components/Metrics';
@import './components/Profile';
@import './components/Management';
@import './components/Skeleton';
@import './components/Signup';
@import './components/PaymentForm';
@import './components/Avatar';
@import './components/Contact';
@import './components/Status';
@import './components/CardList';
@import './components/UnavailableData';
@import './components/ProspectList';

@import './components/ui/Company';
@import './components/ui/Feedback';
@import './components/ui/Table';
@import './components/ui/Juridical';
@import './components/ui/Filter';
@import './components/ui/ResponsiveTable';
@import './components/ui/Alerts';
@import './components/ui/Activity';
@import './components/ui/TreePopup';

@import './components/public/Button.scss';
@import './components/public/Checkbox.scss';
@import './components/public/EmailPage.scss';
@import './components/public/ForgetPassword.scss';
@import './components/public/Input.scss';
@import './components/public/InputPassword';
@import './components/public/PublicHeader.scss';
@import './components/public/PublicFooter.scss';
@import './components/public/Select.scss';

body {
  background: url(/assets/img/base.png) no-repeat #fff;
  font: 14px 'opensans-regular', serif !important;
  font-variant-alternates: unset !important;
  font-variant-ligatures: normal;
  height: 100%;
  width: 100%;
  color: rgb(38, 38, 38);
  font-stretch: normal;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-variant: normal;
  -webkit-text-stroke: 0.2px;
}

.disabled-link {
  color: #c4c4c4;

  svg {
    fill: #c4c4c4 !important;
  }
}

.end-subscription {
  top: 50px !important;
}

.root {
  width: 100%;
  height: 100%;
}

.sbold {
  font-family: 'opensans-semibold';
}

.nowrap {
  white-space: nowrap;
}

.relative {
  position: relative;
}

.loader-block {
  position: relative;
  height: 300px;
  animation: fade-in 1s forwards;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  @extend %margin-absolute;
  width: 64px;
  height: 64px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #7a9cc8;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #7a9cc8 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

.empty-block {
  position: relative;
  height: 300px;
  animation: fade-in 1s forwards;
  margin-top: 77.5px;

  .icon {
    margin: 0 auto 15px;
    position: relative;
    height: 145px;
    width: 145px;
    border-radius: 50%;
    background: #f8fafe;

    svg {
      position: absolute;
      @extend %margin-absolute;
    }
  }

  h2 {
    color: #047cc0;
    font: 20px 'opensans-semibold';
    margin: 0 0 5px;
    text-align: center;
  }

  p {
    color: #152935;
    font: 14px 'opensans-regular';
    margin: 0 0 25px;
    text-align: center;
  }

  .button {
    margin: 0 auto;
    text-align: center;

    button {
      margin: 0 7.5px;
    }
  }

  .not-found {
    width: max-content;
    margin: 0 auto 15px;
  }
}

.zh-text {
  color: #45535e;
}

.zh-tooltip-fixed {
  position: fixed;
}

.no-margin {
  margin: 0 !important;
}

.ant-calendar-picker-input {
  text-align: left;
}

.link-to-more {
  margin-left: 5px;

  svg {
    margin-left: 5px;
    vertical-align: 0px !important;
    fill: #258dc8;
  }
}

.danger-text {
  color: #f86b45;
}

.zh-fixed-tooltip {
  position: fixed;
}

// animations

.fade-in {
  animation: fade-in 0.2s forwards;
  max-width: 1300px;
}

.with-mask {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  animation: fade-in 0.5s forwards;
}

.ant-divider {
  background: #e2ebfa;
}

.ant-btn {
  border-color: #5e6061;
  font: 14px 'opensans-semibold';
  position: relative;

  &:hover {
    background: #5e6061;
    border-color: #5e6061;
    color: #fff;
    box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.05);
  }

  svg {
    position: absolute;
    left: 12.5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.truncated-parent {
  display: flex;

  @include mq($until: wide) {
    opacity: 0;
  }

  .truncated {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-btn-primary {
  border-color: #047cc0;

  &:hover {
    background: #78b8dc;
    border-color: #78b8dc;
    box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.05);
  }
}

.ant-btn-lg {
  border-radius: 0;
  height: 42px;
  font: 14px 'opensans-regular';
}

h1 {
  font-size: 36px;
  margin-bottom: 7.5px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

a {
  color: #047cc0;

  &.disabled-link {
    color: #ccc;
    cursor: not-allowed;
  }

  &:focus {
    text-decoration: none;
  }
}

.span-separator {
  padding: 0 10px;
  color: #dae3eb;
  font-family: 'opensans-semibold';

  @include mq($until: small-desktop) {
    padding: 0 5px;
  }
}

.pull-right {
  margin-top: 7px;
  float: right !important;
  display: flex;
  align-items: center;
  color: #047cc0;
  @include fontPoppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  svg {
    margin-right: 8px;
  }
}

.pull-left {
  float: left !important;
}

.grey {
  color: #ccc;
}

.cursor {
  cursor: pointer;
}

.relative {
  position: relative;
}

.center {
  text-align: center;
}

#root {
  height: 100%;
  width: 100%;
}

// autocomplete component
.ant-select-dropdown-menu-item {
  border-bottom: 1px solid #eaf0f6;
}

.ant-select-dropdown-menu {
  border: 1px solid #e2eefa;
}

// spinner component
.spin-default {
  @extend %clearfix;

  i {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
}

.row-clicked tbody tr td {
  cursor: pointer;
}

// loader component
.zh-spinner-partial {
  position: relative;

  .zh-partial-ring {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.58);
    z-index: 1;
  }
}

.zh-loader-inline {
  color: #ccc;

  i {
    margin-right: 10px;
  }

  &.table-loader {
    display: block;
    width: 100%;
    text-align: center;
    padding: 5px;
  }
}

.pure-loader {
  background-color: #ddd !important;
}

// user-guide
.user-guide-header svg {
  fill: #262626;
  vertical-align: -2px;
}

.user-guide-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(4, 124, 192);
  height: 48px !important;
  width: 48px !important;
  transition: 0.3s ease;
  animation: pulse-shadow 2s infinite;
  z-index: 3;

  &:hover {
    box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.12);
  }
}

// block component
.block-loader {
  top: -1px;
  left: -1px;
  height: 1px;
  width: calc(100% + 2px);
  position: absolute;
  overflow: hidden;
  background-color: #ebebeb;
  z-index: 10;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 1px;
    background-color: #80d1ff;
    animation: loading 2s linear infinite;
  }
}

.pure-block-loader {
  background-color: #e4eefa !important;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.container {
  position: relative;
  max-width: 1360px;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 60px;
  padding-top: 70px;
  padding-bottom: 50px;
  border-left: 1px solid #e2eefa;
  border-right: 1px solid #e2eefa;

  &.container-has-buttons-group {
    padding-top: 0;
    margin-top: 118px;
    border-top: 1px solid #e2eefa;

    @include mq($until: small-desktop) {
      margin-top: 250px;
    }
  }

  @include mq($until: small-desktop) {
    padding: 60px 20px;
  }

  @extend %clearfix;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

// input component
.ant-form-item-label {
  line-height: 25px;

  label {
    color: #395c79;
    font: 12px 'opensans-semibold';

    &:after {
      display: none;
    }
  }
}

.ant-input-group-addon {
  background-color: #f5f7fa;
  border: 1px solid #e2eefa;
  height: 36px;

  &:hover .anticon-plus {
    cursor: pointer;
    color: #00aefa;
  }
}
.ant-input-number-input-wrap {
  height: calc(100% - 2px);
}
.ant-input-number-input {
  height: 100%;
  background: transparent;
}
.ant-input,
.ant-input-number,
.ant-input-number:hover {
  border-radius: 0;
  background-color: #f5f7fa;
  border: 1px solid #e2eefa;
  height: 36px;

  &:focus,
  &.ant-input-number-focused {
    outline: none;
    background-color: #ffffff !important;
    box-shadow: none;
    border: 1px solid #e2eefa !important;
  }
}

.ant-input-number,
.ant-input-number:hover {
  height: 36px;
}

.ant-input-search {
  // min-width: 250px;

  input {
    font-style: italic;
  }

  button {
    background: #f5f7fa !important;
    box-shadow: 0 0 !important;
    border: 1px solid #e2eefa !important;
    border-left: none !important;
    transition: 0.3s ease;

    &:hover i {
      color: #047cc0 !important;
    }

    i {
      margin: 0 !important;
      color: #152935 !important;
    }
  }
}

.ant-input-prefix svg {
  fill: rgba(0, 0, 0, 0.25);
}

// form component
.ant-form-explain {
  font-size: 10px;
  text-align: right;
  margin-top: 1px;
}

.ant-form-item:not(:last-child) {
  margin-bottom: 20px;
}

.ant-checkbox-wrapper {
  display: inline-block;

  span &:not(.ant-checkbox) {
    font-family: 'opensans-semibold';
    color: #152935;
  }
}

// .ant-checkbox-checked .ant-checkbox-inner {
//   background-color: #f5f7fa !important;
//   border-color: #ced9e4 !important;
// }

.form-button {
  display: inline;

  > button {
    margin-left: 10px;
  }
}

.gray {
  color: #cbcfd0 !important;
}

// form half component
.form-half {
  position: relative;
  width: 100%;

  .form-half-toolbar {
    position: absolute;
    right: 0;
    top: 7px;
    opacity: 0;
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 1;
      color: #000;
    }
  }

  &:hover .form-half-toolbar {
    opacity: 0.8;
  }

  .ant-form-item {
    float: left;
    width: calc(50% - 5.5px);

    .ant-select-selection {
      background-color: #f5f7fa;
    }

    .ant-calendar-picker {
      width: 100%;
    }

    &:nth-child(odd) {
      margin-right: 5px;
    }
    &:nth-child(even) {
      margin-left: 5px;
    }
  }

  &.form-half-three .ant-form-item {
    width: calc(33.33% - 5px);
    margin-right: 0px;
    margin-left: 0px;

    &:nth-child(2) {
      margin-right: 7.5px;
      margin-left: 7px;
    }
  }

  .form-half-input {
    .ant-checkbox-wrapper {
      width: 40%;
      float: left;
      font-weight: 100 !important;
    }

    .ant-calendar-picker {
      margin-left: 10px;
      width: calc(40% - 10px);
      float: left;
    }

    .ant-input-affix-wrapper,
    .ant-select {
      width: 60%;
      float: left;
      padding-right: 0;
    }

    @extend %clearfix;
  }

  @extend %clearfix;
}

.text-blue {
  color: #258dc8 !important;
}

.blue {
  color: #3595cc !important;
}

.svg-blue svg {
  fill: #258dc8;
}

.absents-data {
  margin-left: 4px;
}

// download type
.download-type {
  font: 10px 'opensans-semibold';
  display: inline-block;
  padding: 2.5px 5px;
  border-radius: 3px;
  border: 1px dashed #de4772;
}

// download status
.download-status {
  font: 10px 'opensans-semibold';
  display: inline-block;
  padding: 2.5px 5px;
  border-radius: 3px;
  color: #fff;
  white-space: nowrap;

  i {
    margin-right: 10px;
  }

  &.download-status-fail {
    background: #de4772;
  }

  &.download-status-finish {
    background: #07a1bd;
  }

  &.download-status-pending,
  &.download-status-running,
  &.download-status-working {
    background: #5a6872;

    svg {
      margin-right: 5px;

      path {
        fill: #fff;
      }
    }
  }
}

.homepage-row:hover {
  background-color: #e9f2fb;
}

.zh-homepage-title {
  > span {
    display: block;
  }

  .title {
    color: #244b63;
    font-weight: 600;
  }

  &.title-loading {
    display: block;
    width: 100%;

    > span,
    .title {
      color: #ccc;
    }
  }
}

// home-page
.home-back-button {
  a {
    font: 16px 'opensans-semibold';
    color: #244b63;

    &:hover {
      color: #346f93;
    }
  }
}

.ant-tabs-nav-container {
  transition: none !important;
}

// home-filter component
.home-filter {
  width: 100%;
  // display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: middle;

  .ant-tabs-bar {
    border-bottom: 0;
  }

  .ant-tabs-nav {
    width: 100%;
    padding-bottom: 5px;

    > div {
      display: flex;
    }
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-tab-prev,
  .ant-tabs-tab-next {
    height: calc(100% - 5px);
  }

  .ant-tabs-tab {
    padding: 0 0 1px;
    display: block;
    width: 100%;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  a {
    width: 100%;

    &:not(:last-child) {
      margin-right: 8px;
    }

    > .home-filter-item {
      position: relative;
      font-size: 16px;
      color: #152935;
      border: 1px dashed #e2eefa;
      padding: 15px 25px 15px 50px;
      transition: 0.3s ease;

      svg {
        position: absolute;
        fill: #047cc0;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        stroke-width: 0.1px;
        fill-rule: evenodd;
        font-size: 23px;
      }

      &.active {
        color: #fff;
        background-color: #047cc0 !important;
        border: 0;

        svg {
          fill: #fff;
        }

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          width: 0;
          height: 0;
          border-top: solid 7.5px #047cc0;
          border-left: solid 7.5px transparent;
          border-right: solid 7.5px transparent;
        }
      }

      &:not(.active) {
        cursor: pointer;
      }

      &:hover {
        background: #f5f8fb;
      }
    }
  }
}

.home-filter-item {
  .zh-icon {
    font-size: 23px;
  }
  .zh-icon-filter-list,
  .zh-icon-filter-folder {
    vertical-align: -2px;
  }
  .zh-icon-filter-search,
  .zh-icon-filter-export {
    font-size: 25px;
    vertical-align: -2px;
  }
  .zh-icon-filter-clock {
    font-size: 25px;
  }
}

.home-filter-circle {
  position: relative;
  height: 35px;
  width: 35px;
  background: #f5f8fb;
  border-radius: 50%;

  > svg {
    position: absolute;
    left: 50%;
    top: 50% !important;
    transform: translate(-50%, -50%);
    fill: #1fa3ff;
    stroke-width: 0.1px;
    fill-rule: evenodd;
  }
}

// form-inline
.form-inline {
  display: flex;

  .form-inline-label {
    white-space: nowrap;
    font-size: 12px;
    color: #5a6872;
    line-height: 37.5px;
    padding-right: 10px;
  }

  .ant-select {
    min-width: 140px;
  }

  button,
  .ant-input-search {
    margin-left: 10px;
  }
}

.ant-tooltip {
  &.zh-fixed-tooltip {
    position: fixed;
    .ant-tooltip-inner {
      padding: 6px 15px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #e7ecf1;
      color: #263745;
    }
    &.ant-tooltip-placement-right .ant-tooltip-arrow {
      border-right-color: #fff !important;
    }

    &.ant-tooltip-placement-left .ant-tooltip-arrow {
      border-left-color: #fff !important;
    }

    &.ant-tooltip-placement-top .ant-tooltip-arrow {
      border-top-color: #fff !important;
    }

    &.ant-tooltip-placement-bottom .ant-tooltip-arrow {
      border-bottom-color: #fff !important;
    }
  }
  &.zh-fixed-button-tooltip {
    &.zh-fixed-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow {
      border-bottom-color: #346f93 !important;
    }
    &.ant-tooltip-placement-left .ant-tooltip-arrow {
      border-left-color: #346f93 !important;
    }

    &.ant-tooltip-placement-top .ant-tooltip-arrow {
      border-top-color: #346f93 !important;
    }

    &.ant-tooltip-placement-bottom .ant-tooltip-arrow {
      border-bottom-color: #346f93 !important;
    }
    .ant-tooltip-inner {
      background: #346f93;
      color: #fff;
      border: 0;
      font-size: 13px;
    }
  }
}

// actions
.actions svg {
  fill: #262626;
  vertical-align: -2px;
}
.action-modification {
  cursor: pointer;

  span {
    margin-left: 5px;
    vertical-align: 2px;
  }

  &:hover {
    color: #047cc0;
  }
}

.action-section {
  display: flex;

  span {
    &:first-child {
      margin-right: 30px;
    }
  }
}

.action-notification {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:last-child {
      display: none;
    }
  }

  &:hover {
    svg {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
}

.action-edit {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;

  svg {
    position: absolute;
    @extend %clearfix;
    fill: #5b8ba8;
  }

  &:hover svg {
    fill: #4ab5ff;
  }
}

// save list edit component
.save-list-edit {
  position: relative;

  .save-list-close {
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: #00aefa;
    }
  }

  .save-list-inline {
    span {
      color: #244b63;
      font: 14px 'opensans-semibold';
      margin-right: 15px;
    }

    input {
      width: 280px;
    }
  }

  .save-list-blocks {
    padding: 15px 0;

    .save-list-item {
      width: 25%;
      float: left;
      padding-right: 10px;

      .ant-form-item-control {
        max-height: 200px;
        overflow-y: auto;

        @extend %scroll;
      }

      &:not(:first-child) {
        .ant-form-item-label label {
          font: 14px 'opensans-semibold';
          margin: 12.5px 0 10px;
          display: block;
        }
      }
    }

    @extend %clearfix;
  }

  .save-list-buttons button {
    font: 14px 'opensans-semibold';
    margin-right: 10px;
    padding: 0 25px;
  }

  .save-list-search {
    .title {
      font: 16px 'opensans-regular';
      color: #2e404f;
      margin: 10px 0;
      display: block;
    }

    ul {
      max-width: 270px;
      padding: 5px 0;

      li {
        list-style: none;
        color: #191919;
        font: 15px 'opensans-regular';
        margin-bottom: 7px;
      }
    }

    button {
      border-radius: 4px;
      border-color: #ced9e4;
      font: 13px 'opensans-semibold';
      color: #047cc0;

      &:hover {
        border-color: #1fa3ff;
        background: #1fa3ff;
        color: #fff;
      }
    }
  }

  .ant-checkbox-wrapper {
    font: 13px 'opensans-regular' !important;
    color: #344650;
    margin-left: 0;
    margin-bottom: 10px;
    display: flex;
  }

  .ant-radio-wrapper {
    display: block;
    margin: 7.5px 0;
  }
}

.like-header {
  .ant-form-item-label label {
    font: 16px 'opensans-regular';
    margin: 10px 0;
    display: block;
  }
}

// segment
.segment {
  margin-bottom: 20px;

  h1 {
    margin: 0;
    font: 16px 'opensans-bold';
    line-height: 37.5px;
    margin-right: 20px;
    padding-left: 15px;
  }

  .two-rows {
    h1 {
      margin: 0 0 10px;
      font: 16px 'opensans-bold';
      padding-left: 0;
    }

    small {
      color: #152935;
      font: 14px 'opensans-regular';
      font-weight: 600;

      .grey {
        color: #5a6872;
      }
    }
  }

  button {
    padding-left: 25px;
    padding-right: 25px;

    &.ant-input-search-button {
      padding: 0 15px;
    }
  }

  &.segment-header {
    margin-bottom: 0;

    button {
      margin: 7.5px 0;
      font: 14px 'opensans-semibold';
      border: 1px solid #e2eefa;
      border-radius: 4px;
      color: #363839;
      padding-left: 45px;
      padding-right: 45px;

      svg {
        fill: #363839;
      }

      &:hover {
        color: #fff;
        svg {
          fill: #fff;
        }
      }
    }
  }

  &.segment-box {
    padding: 22.5px 0;
    border-top: 1px solid #e2eefa;
    border-bottom: 1px solid #e2eefa;
    margin-bottom: 0;

    button {
      border-color: #047cc0 !important;
      color: #363839;
      padding-left: 40px;
      padding-right: 40px;
      font: 14px 'opensans-semibold';

      &:hover {
        color: #fff;
        background: #047cc0;
      }
    }

    h1 {
      padding-left: 0;
    }
  }

  @extend %clearfix;
}

// header text component

.header-text {
  font: 16px 'opensans-semibold';
  padding: 30px 0;
  margin: 0;
  border-top: 1px solid #e2eefa;
  border-bottom: 1px solid #e2eefa;
}

// pop action
.pop-action {
  padding: 7.5px 40px 7.5px 10px;
  color: #152935;
  cursor: pointer;

  > i {
    color: #047cc0;
    margin-right: 5px;
  }

  > svg {
    fill: #047cc0;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-bottom: 3px;
  }

  span {
    font: 14px 'opensans-regular';
  }

  &:hover {
    color: #047cc0;
  }
}

// half sections
.half-sections {
  display: flex;
  height: 100%;
  border: 1px solid #e2eefa;

  @include mq($until: desktop) {
    display: block;
  }

  .ant-spin-nested-loading {
    padding: 20px;

    &:first-child {
      width: 35%;
      background: #f5f8fb;
      border-right: 1px solid #e2eefa;

      @include mq($until: desktop) {
        width: 100%;
      }
    }

    &:last-child {
      width: 65%;
      background: #fff;
      margin: 1px;

      @include mq($until: desktop) {
        width: 100%;
      }
    }
  }

  @extend %clearfix;
}

// button component
.btn-icn {
  float: left;
  position: relative;

  button {
    padding: 0 37.5px;
  }

  > i,
  > svg {
    fill: #047cc0;
    position: absolute;
    left: 12.5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }

  &:hover {
    svg {
      fill: #fff !important;
      transition: 0s;
    }

    i {
      color: #fff !important;
      transition: 0s;
    }
  }

  @extend %clearfix;
}

// form header component
.form-title {
  position: relative;
  color: #152935;
  // font-weight: 600;
  font: 16px 'opensans-semibold';
  // font-size: 16px;
  margin-bottom: 5px;
}

.form-helper {
  position: relative;
  color: #152935;
  font: 14px 'opensans-semibold';
  margin-bottom: 0px;
  margin-top: 7.5px;

  &.form-helper-large {
    font-size: 14px;
    margin: 0 0 15px;
  }
}

.btn-form button {
  margin-top: 26px;
  color: #047cc0;
  border-color: #ced9e4;
  border-radius: 4px;
  transition: 0.3s ease;
  font-family: 'opensans-semibold';

  &:hover,
  &:focus {
    background: #1fa3ff;
    border-color: #1fa3ff;
    color: #fff;
    box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.12);
  }
}

.btn-short {
  max-width: 220px;
}

// tabs component
.zh-profile-tabs {
  .ant-tabs-tab {
    padding: 0;

    a,
    span.grey {
      padding: 15px 0 11px;
      display: inline-block;
      color: #5a6872;
      font-family: 'opensans-semibold';
      border-bottom: 4px solid transparent;

      &.active {
        color: #152935;
        border-bottom: 4px solid #047cc0;
      }
    }

    &:hover a {
      color: #1585c4;
    }

    span.grey {
      color: #ccc !important;
      cursor: not-allowed;
    }
  }
}

.no-tabs-m {
  .tabs {
    margin: 0;
  }
}

.tabs {
  width: 100%;
  border-bottom: 1px solid #e9f1fa;
  padding-bottom: 15px;
  padding-left: 0px;
  padding-top: 0px;
  margin-bottom: 20px;
  clear: both;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 3%;
    }

    &.disabled {
      cursor: not-allowed !important;
      color: #ccc !important;
    }

    a {
      position: relative;
      color: #5a6872;
      border-bottom: 1px solid rgba(4, 123, 192, 0);
      padding-bottom: 15px;
      font-family: 'opensans-semibold';
      transition: 0.3s ease;

      &:hover {
        color: #1585c4;
      }
    }

    &.active {
      a {
        color: #152935;
        border-bottom: 2px solid #047cc0;

        &:after {
          content: ' ';
          position: absolute;
          width: 75%;
          left: 50%;
          bottom: 0;
          height: 1px;
          transform: translateX(-50%);
          box-shadow: 0px 0px 20px 0px #0e48a1;
        }
      }
    }
  }
}

.form-footer {
  text-align: center;
  padding-top: 20px;

  > button {
    margin-right: 10px;
    min-width: 120px;
    font-family: 'opensans-semibold';
  }

  > a button {
    margin-right: 10px;
  }
}

// profile tab
.profile-line {
  height: 1px;
  background: #e2eefa;
  margin: 10px -20px 17.5px;
}

// profile avatar form
.profile-avatar {
  user-select: none;
  width: 250px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  h2 {
    font: 16px 'opensans-semibold';
    margin: 20px 0;
  }

  > button {
    display: block;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    font-family: 'opensans-semibold';

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .change-avatar {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 2px;
    margin: 0 auto;

    > .close {
      position: absolute;
      color: #fff;
      right: -7.5px;
      top: -7.5px;
      z-index: 1;
      box-shadow: 0px 3px 11.52px 0.48px rgba(21, 41, 53, 0.07);
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
      height: 22px;
      width: 22px;
      transition: 0.3s ease;

      > i {
        position: absolute;
        font-size: 12px;
        color: #5a6872;
        @extend %margin-absolute;
      }
    }

    img {
      animation: fade-in 1s forwards;
      pointer-events: none;
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      box-shadow: 0px 3px 11.52px 0.48px rgba(21, 41, 53, 0.07);
    }
  }
}

.avatar-alt {
  user-select: none;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 2px;
  margin: 0 auto;
  background: #ecf1f5;

  > span {
    position: absolute;
    @extend %margin-absolute;
    color: #346f93;
    font: 25px 'opensans-bold';
  }
}

.avatar-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
  border-radius: 2px;
  margin: 0 auto;
  background: #ecf1f5;

  > i {
    position: absolute;
    @extend %margin-absolute;
    color: #346f93;
    font-size: 25px;
  }

  span {
    position: absolute;
    @extend %margin-absolute;
    color: #202020;
  }
}

.small-avatar-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 33px;
  height: 33px;
  border-radius: 2px;
  margin: 0 auto;
  background: #ecf1f5;

  > i {
    position: absolute;
    @extend %margin-absolute;
    color: #346f93;
    font-size: 15px;
  }

  span {
    position: absolute;
    @extend %margin-absolute;
    color: #202020;
    font-size: 10px;
  }
}

// small avatar
.small-avatar-img {
  pointer-events: none;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.small-avatar {
  user-select: none;
  position: relative;
  width: 33px;
  height: 33px;
  border-radius: 4px;
  background: #f5f8fb;

  span {
    position: absolute;
    @extend %margin-absolute;
    font: 13px 'opensans-bold';
    color: #047cc0;
  }
}

.atn-file {
  height: 38px;
  border-color: #047cc0;
  background: #047cc0;
  width: 100%;
  color: #fff;
  display: block;
  text-align: center;
  padding: 7.5px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background: #78b8dc;
    border-color: #78b8dc;
    box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.05);
  }

  input[type='file'] {
    display: none;
  }
}

// api page
.api-access {
  padding-top: 10vh;
  text-align: center;

  img {
    display: block;
    margin: 0 auto 25px;
  }

  h1 {
    font-size: 20px;
    color: #047cc0;
    font-family: 'opensans-semibold';
    margin-bottom: 2.5px;
  }

  h2 {
    color: #152935;
    font: 14px 'opensans-semibold';
    margin-bottom: 25px;
  }

  button {
    min-width: 220px;
  }
}

// notifications
.notifications {
  h1 {
    margin: 0;
    padding: 20px 5px;
    color: #152935;
    text-align: center;
    font: 16px 'opensans-semibold';
    border: 1px solid #e2eefa;
  }

  .notifications-block {
    display: flex;
    border: 1px solid #e2eefa;
    border-top: none;

    > div {
      padding: 20px 20px 7.5px;
    }
  }
}

// radio block
.radio-block {
  .ant-radio-group {
    margin-top: 5px;

    .ant-radio-wrapper {
      margin: 5px 0;
      display: block;
      font-family: 'opensans-semibold';
      color: #152935;
    }
  }
}

// credits
.credits-header {
  margin: 0;
  padding: 15px;
  color: #152935;
  text-align: center;
  border: 1px solid #e2eefa;
  margin-bottom: -15px;
  font: 16px 'opensans-semibold';
}

.table-border {
  border: 1px solid #e2eefa !important;
}

.credits-more {
  margin-top: -15px;
  border: 1px solid #e2eefa;
  text-align: center;
  padding: 25px;

  h2 {
    margin: 0 0 20px;
    font: 16px 'opensans-semibold';
  }

  button {
    min-width: 180px;
  }
}

// team page
.btn-ellipsis {
  color: #4b7f9f;
  cursor: pointer;
  font: 20px 'opensans-bold';
  transition: 0.3s ease;

  &:hover,
  &:focus {
    color: #35acff;
  }
}

.in-two-rows {
  padding-left: 5px !important;

  > span {
    display: block;
    font: 16px 'opensans-semibold' !important;
    color: #152935 !important;
  }

  small {
    display: block;
    font-size: 14px;
    color: #5a6872;
  }

  &.cursor {
    cursor: pointer;

    &:hover {
      > span {
        color: #047cc0 !important;
      }
    }
  }
}

.zh-loader-screen {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  animation: fade-in 1s forwards;
  z-index: 99;
  background: rgba(255, 255, 255, 0.75);
  .zh-spinner-ring {
    position: absolute;
    @extend %margin-absolute;
    fill: #7a9cc8;
    font-size: 50px;
    font-weight: 1000;
    z-index: 2;
  }
}

.row-name {
  width: 500px;
}

// searches item
.searches-item div {
  cursor: pointer;
  position: relative;
  display: block;
  border-bottom: 1px solid #eaf0f6;
  padding: 16px 30px;
  color: #047cc0 !important;

  i {
    font-size: 18px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #acb3b8;
  }

  svg {
    position: absolute;
    fill: #258dc8;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background: #e9f2fb;
  }
}

// notification status
.notification-status {
  a {
    color: rgba(0, 0, 0, 0.87);

    &:hover {
      color: #047cc0;
    }

    svg {
      margin-bottom: -4px;
    }
  }

  span {
    vertical-align: 4px;
  }
}

// modal component
.modal-body-0 {
  .ant-modal-body {
    padding: 0 !important;
  }
}

.modal-has-header .ant-modal-header {
  padding: 17px 25px;
}

.modal-nofooter .ant-modal-footer {
  padding: 0;
}

.modal-content {
  padding: 20px 25px;
}

.modal-nocontent .ant-modal-body {
  padding: 0;
}

.modal-footer-alternative {
  text-align: center;
  margin-top: 25px;

  button:not(:last-child) {
    margin-right: 15px;
  }
}

.modal-bordered-header {
  .ant-modal-header {
    border-bottom: 1px solid #e4eefa !important;
    padding: 17px 25px !important;
  }
}

.modal-bordered-footer {
  .ant-modal-footer {
    border-top: 1px solid #e8e8e8 !important;
    padding: 15px 25px !important;
  }
}

.modal-header-right {
  position: absolute;
  right: 15px;
  top: 15px;

  @extend %btn-aqua;
  button {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modal-footer-center {
  display: flex;
  justify-content: center;

  button {
    margin: 0 5px;
    min-width: 120px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modal-footer-left {
  button {
    margin-right: 10px;
    min-width: 120px;
  }

  .pagination {
    margin: 5px 0;
  }
}

.modal-footer-right {
  button {
    margin-left: 10px;
    min-width: 120px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .pagination {
    margin: 5px 0;
  }
}

// description component
.description {
  max-width: 550px;
  margin: 0 auto;

  svg {
    margin: 5px auto 15px;
    display: block;
  }

  img {
    margin: 5px auto 15px;
    display: block;
  }

  h2 {
    font: 20px 'opensans-semibold';
    color: #047cc0;
    margin: 0 0 10px;
    text-align: center;
  }

  p {
    text-align: center;
    font: 14px 'opensans-regular';
    color: #152935;
    margin-bottom: 5px;
  }

  .description-footer {
    padding-top: 35px;
    text-align: center;

    button {
      margin: 0 5px;
      min-width: 120px;
    }
  }
}

.description-input {
  margin-top: 15px;
}

// credits popup
.report-popup {
  .credits-available {
    h2 {
      font: 48px 'opensans-regular';
      text-align: center;
      margin: 0;
      color: #152935;
    }

    p {
      font: 14px 'opensans-semibold';
      color: #047cc0;
      text-align: center;
      margin: 0 0 7.5px;
    }
  }

  .ant-modal-footer {
    background: #f5f8fb;
    padding: 20px 25px 15px;

    .report-footer {
      text-align: left;
      ul {
        padding: 0;
        padding-left: 20px;
        margin-bottom: 30px;

        li {
          margin: 12px 0;
          font-size: 14px 'opensans-regular';
          list-style: none;
        }
      }

      div {
        display: flex;
        justify-content: center;

        button {
          font: 14px 'opensans-semibold';
          margin: 0 5px;
        }
      }
    }
  }
}

// confirmation plugin
.confirmation-true,
.confirmation-false {
  font-size: 20px;
  margin-right: 7.5px;
  vertical-align: -2px;
}

.confirmation-true {
  color: #1fa3ff;
}

.confirmation-false {
  color: #5a6872;
}

// modal
.modal-clean {
  .ant-modal-footer {
    border-top: 0;
    min-height: 77px;

    & > div {
      float: left;
      width: 100%;
    }
  }
}

.ant-modal-footer {
  min-height: 58px;

  & > div {
    float: left;
    width: 100%;
  }

  .ant-btn-primary {
    border: none;
  }
}

.save-results-modal {
  .ant-modal-body {
    padding-bottom: 15px;
    padding-top: 30px;
  }
  .ant-modal-close {
    right: 22px;
    top: 20px;
    color: #616263;
  }
}

// zh-hidden input
.zh-input-hidden {
  background: transparent;
  border: 0;
  outline: none !important;
  box-shadow: none !important;
}

.confirmation-modal {
  z-index: 1031;

  .ant-modal-body {
    padding: 20px 25px 0;
  }

  .ant-modal-footer {
    padding: 20px 25px;
    border-top: 1px solid #eaf0f6;
  }

  &.center {
    text-align: center;
    svg {
      margin: 20px 0;
      font-size: 120px;
    }
  }
}

.radio-flex {
  display: flex;
  align-items: center;
  .zh-icon-filter-list {
    font-size: 23px;
  }
}
.zh-btn.create-btn {
  float: left;
  font: 14px 'opensans-semibold';
  color: #363839;
  border: 1px solid rgb(4, 124, 192);
  background-color: rgb(255, 255, 255);
  padding-left: 35px;

  &:hover {
    color: #fff;
    background: rgb(4, 124, 192);
    svg path {
      fill: #fff;
    }
  }

  svg {
    margin-right: 10px;
    font-size: 19px;
    path {
      fill: #278ec9;
    }
  }
}
.map-pin {
  margin-left: -42.5px;
  margin-top: -76px;
}
.add-company-list {
  overflow-y: auto;
  max-height: 300px;
  margin-top: 15px;

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #cbd6e2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    margin-left: -10px;
    left: -10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    width: 6px;
    background-color: #2083be;
    margin-left: -10px;
  }

  .home-filter-circle {
    margin-right: 12px;

    @include mq($until: small-tablet) {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
      margin: 0 5px 0 -5px;
    }

    svg {
      transform: scale(0.7) translate(-50%, -50%);
      transform-origin: left top;
    }
  }

  .ant-radio-group {
    display: block;
    padding: 0;

    .ant-radio-wrapper {
      display: block;
      padding: 10px 25px;
      margin: 0;
      position: relative;

      @include mq($until: small-tablet) {
        padding: 10px;
      }

      &:after {
        content: '';
        background: #eaf0f6;
        height: 1px;
        left: 25px;
        right: 25px;
        bottom: 0;
        position: absolute;
      }

      > span {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .ant-radio {
        right: 25px;
        margin-top: -9px;
        position: absolute;
        top: 50%;

        @include mq($until: small-desktop) {
          right: 10px;
        }
      }
      .ant-radio-inner {
        width: 18px;
        height: 18px;
        background: #f5f7fa;
        border: 1px solid #ced9e4;
        border-radius: 0;

        &:after {
          border-radius: 0;
          width: 10px;
          height: 10px;
        }
      }

      i {
        position: absolute;
        right: 7.5px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
      }

      &.ant-radio-wrapper-checked {
        i {
          opacity: 1;
        }
      }

      h2 {
        font: 14px 'opensans-semibold';
        color: #152935;
        margin: 0;
      }

      small {
        font: 12px 'opensans-regular';
        color: #5a6872;
        margin: 0;
      }

      &:not(.ant-radio-wrapper-disabled):not(.ant-radio-wrapper-checked):hover {
        background: #e9f2fb;

        h2 {
          color: #047cc0;
        }
        .home-filter-circle {
          background: #047cc0;

          svg path {
            fill: #fff;
          }
        }
      }

      &.ant-radio-wrapper-checked {
        background: #047cc0;

        &:after {
          background: #047cc0;
        }
        h2,
        small {
          color: #fff;
        }
        .home-filter-circle {
          background: #ffffff;

          svg path {
            fill: #1fa3ff;
          }
        }
      }
    }

    .zh-input-hidden {
      width: 100%;
      display: block;
    }
  }

  .add-company-list-unactive {
    .ant-radio-wrapper-disabled {
      cursor: default;
    }
    .home-filter-circle {
      svg path {
        fill: #bcbec0;
      }
    }
    h2.disabled {
      color: #bcbec0;
      + small {
        color: #bcbec0;
      }
    }

    .ant-radio-wrapper {
      &.ant-radio-wrapper-checked {
        background: #fff;

        small {
          color: #152935;
        }
        &:after {
          background: #eaf0f6;
        }

        .home-filter-circle {
          background: #f5f8fb;
        }
        h2 {
          color: #152935;
        }
      }

      &.ant-radio-wrapper-disabled {
        background: #fff !important;

        > span > * {
          color: #ccc !important;
          cursor: not-allowed;
        }
      }
    }
  }
}

// flex
.flexible {
  display: flex;
  flex-wrap: wrap;
}

.container-flex {
  display: flex;

  @include mq($until: small-tablet) {
    display: block;
  }
}

// margin within segment
.within-segment {
  margin-left: -20px;
  margin-right: -20px;
}

.within-segment-2 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.segment-margins {
  margin: -20px;
}

// whole information div
.whole-information {
  display: block;
  padding: 20px;
  text-align: center;
  color: #7a7c7d;
  border-top: 1px solid #e2eefa;
  border-bottom: 1px solid #1fa3ff;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  cursor: pointer;

  svg {
    margin: 0 10px;
    fill: #737576;
    vertical-align: -1px;
  }

  &:hover {
    color: #51b1ff;

    svg {
      fill: #51b1ff;
    }
  }
}

.segment-more {
  padding: 20px;
  text-align: center;
  border: 1px solid #e2eefa;
  border-top: 1px solid #047cc0;
  margin-top: 0px;
  color: #7a7c7d;
  cursor: pointer;

  svg {
    margin: 0 10px;
    fill: #737576;
    vertical-align: -1px;
  }

  &:hover {
    color: #51b1ff;

    svg {
      fill: #51b1ff;
    }
  }
}

// rotate arrow
.rotate-arrow {
  transform: rotate(90deg) !important;
}

// company-segment
.company-segment {
  display: block;
  width: 100%;
  border: 1px solid #e2eefa;
  margin-top: 20px;
  position: relative;

  .pagination {
    float: right;
  }

  &.active {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  }

  .company-segment {
    border: unset;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 0;
    width: calc(100% + 40px);
  }

  > .title {
    position: relative;
    color: #152935;
    font: 16px 'opensans-semibold';
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid #e2eefa;
    display: block;

    &.gray {
      background: #fafbfd;
    }

    .title-desc {
      display: block;
      font: 14px 'opensans-regular';
      color: #244b63;
      margin-top: 5px;
    }

    &.title-number {
      padding-left: 70px;
    }

    .title-number-span {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #047cc0;
      font: 16px 'opensans-semibold';
    }

    > i {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #7a9cc8;
      fill: #7a9cc8;
    }

    small {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      display: block;

      svg {
        fill: #7a9cc8;
        transition: 0.2s ease;

        &.active {
          transform: rotate(90deg);
          fill: #047cc0;
        }

        &:hover {
          fill: #1fa3ff;
        }
      }
    }
  }

  .sub-title {
    background-color: #f5f8fb;
    color: #152935;
    font-size: 14px;
    font-family: 'opensans-regular';
    border-bottom: 1px solid #e2eefa;
    padding: 15px 20px;
  }

  > .content {
    padding: 20px;

    .table-footer {
      margin-bottom: 5px;
    }

    > p {
      font: 16px 'opensans-regular';
      color: #152935;
      margin: 0;

      @include mq($until: small-desktop) {
        font-size: 13px;
      }
    }

    &.content-grey {
      background: #fafbfd;
    }

    @extend %clearfix;
  }

  &.no-padding > .content {
    padding: 0;
    margin: 1px;
  }

  &.no-border {
    border-left: 0;
    border-right: 0;
  }

  .company-segment-section {
    width: 100%;
    padding: 20px;
    float: left;

    &:not(:last-child) {
      border-right: 1px solid #e2eefa;
    }

    h2 {
      font: 16px 'opensans-semibold';
      color: #346f93;
      margin: 0 0 20px;
    }
  }
}

.text-blue {
  color: #047cc0;
}

.timeline-chart {
  height: calc(100% - 64px);
}

.company-segment-halves {
  display: flex;

  @include mq($until: small-desktop) {
    display: block;
  }

  .company-segment,
  .segment-vertical {
    width: 100%;

    &:first-child:not(:last-child) {
      margin-right: 20px;
    }
  }

  &.company-with-map .company-segment:first-child {
    margin-right: 0 !important;
  }
}

.ant-modal {
  @include mq($until: wide) {
    max-width: 93% !important;
  }
  @include mq($until: small-desktop) {
    margin: 30px;
  }
}

.google-map {
  position: relative;

  > .content {
    height: 100%;
  }

  &.google-subdivision-map {
    height: 212px;
    width: 100%;
    margin: 0 -20px;
    position: absolute;
    bottom: 0;

    @include mq($until: small-desktop) {
      display: none;
    }

    .openlayers-map {
      height: 212px;
    }
  }

  .google-map-board {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 310px;
    min-height: 100px;
    background: #fff;
    border: 1px solid #e2eefa;
    z-index: 1;
    padding: 20px 25px;
    padding-right: 50px;

    @include mq($until: small-desktop) {
      padding: 20px 25px;
      position: relative;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      border: none;
    }

    div {
      &:not(:first-child) {
        h2 {
          margin-top: 10px;
        }
      }

      svg {
        margin-right: 12.5px;
        display: flex;
        fill: rgb(52, 111, 147);
      }

      h2 {
        font: 16px 'opensans-semibold';
        margin: 0 0 10px;
      }

      p {
        position: relative;
        font: 15px 'opensans-semibold';
        margin: 0;
        padding-left: 20px;
        color: #45667a;

        svg {
          position: absolute;
          left: 0;
          top: 5px;
        }
      }
    }

    &.not-found {
      padding: 0;

      h2 {
        position: absolute;
        @extend %margin-absolute;
        width: 100%;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}

.google-map-loader {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f8fb;
  z-index: 1000;

  i {
    position: absolute;
    @extend %margin-absolute;
    font-size: 30px;
    color: #7a9cc8;
  }
}

.hide {
  display: none;
}

// company media
.company-media {
  position: relative;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;

  i {
    position: absolute;
    @extend %margin-absolute;
    font-size: 30px;
  }

  &:not(.disabled) {
    &:hover {
      @extend %pulse-blue;
    }
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

// company timeline
.company-segment-section {
  .ant-timeline {
    padding: 0;

    .ant-timeline-item {
      padding: 0 0 15px;
    }

    .ant-timeline-item-head-blue {
      background: #78b8dc;
      border: 0;
      width: 5px;
      height: 5px;
      left: 3px;
      top: 3px;
    }

    .ant-timeline-item-tail {
      border-left: 1px solid #e2eefa;
      left: 5px;
      top: 0.2em;
    }

    .ant-timeline-item-content {
      font: 15px 'opensans-semibold';
      color: #152935;

      // &:hover {
      //   color: #047cc0;
      //   cursor: pointer;
      // }
    }

    .ant-timeline-item {
      &:nth-last-child(2) .ant-timeline-item-tail {
        border-left: 1px dashed #e2eefa;
      }
    }
  }

  .timeline-more {
    padding: 0;
    height: 20px;
    cursor: pointer;

    .ant-timeline-item-head {
      display: none;
    }

    .ant-timeline-item-content {
      font: 13px 'opensans-regular';
      color: #787a7c;
      min-height: auto;

      svg {
        margin-left: 8px;
        fill: #787a7c;
        vertical-align: -6px;
      }
    }

    &:hover .ant-timeline-item-content {
      color: #2a9ded;

      svg {
        fill: #2a9ded;
      }
    }
  }
}

.company-graph {
  margin-bottom: 20px;

  .title {
    font: 16px 'opensans-semibold';
    padding-bottom: 20px;
    border-bottom: none;
  }

  .content {
    padding-top: 0;
    padding-bottom: 10px;

    p {
      padding: 0 0 20px;
      color: #047cc0;
      font: 16px 'opensans-semibold';
    }
  }
}

.chart {
  margin-top: 12.5px;
  max-width: 100%;

  .highcharts-container,
  .highcharts-container svg {
    max-width: 100%;
  }

  svg {
    g text {
      font-family: 'opensans-bold';
      color: #152935 !important;
    }
  }

  .chart-tooltip {
    margin: 0 15px;
  }
}

.chart-info {
  color: #7db5ec !important;
  font-family: 'opensans-semibold' !important;
  font-size: 18px !important;
}

.chart-empty {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;

  span {
    position: absolute;
    @extend %margin-absolute;
    font: 20px 'opensans-semibold';
  }
}

// first table
.company-first-table {
  margin-top: 40px;

  &.no-m-t {
    margin-top: 0;
  }
}

// company shelude
.company-subdivision-shelude {
  background: #fafbfd;
  border-top: 1px solid #e2eefa;
  border-bottom: 1px solid #e2eefa;
  padding: 25px;

  h2 {
    font: 14px 'opensans-semibold';
    margin: 0 0 20px;
    text-align: center;
  }

  .container-flex {
    justify-content: space-between;

    > div {
      border: 1px solid #c1e6fe;
      padding: 5px 5px;
      min-width: 160px;
      text-align: center;
      background: #fff;

      small {
        display: block;
        font: 12px 'opensans-semibold';
        color: #244b63;
      }

      span {
        display: block;
        font: 14px 'opensans-semibold';
        color: #152935;
      }

      &.holiday {
        background: #dae3eb;
        border-color: #dae3eb;

        small,
        span {
          color: #5e6061;
        }
      }

      &.today {
        background: #7a9cc8;
        border-color: #7a9cc8;

        small,
        span {
          color: #f5f5f5;
        }
      }
    }
  }
}

// company more
.more-svg {
  text-align: center;
  display: block;
  margin-top: -15px;
  margin-bottom: -5px;
  padding: 20px;
  cursor: pointer;
  color: #7a7c7d;

  svg {
    vertical-align: -5px;
    fill: #7a7c7d;
    margin-left: 5px;
  }

  &:hover {
    color: #1fa3ff;

    svg {
      fill: #1fa3ff;
    }
  }
}

// company segment header
.company-segment-header {
  border-top: 1px solid #e2eefa;
  margin-top: 20px;
  margin-bottom: -20px;
  padding: 20px 0;

  h2 {
    font: 16px 'opensans-semibold';
    margin: 7.5px 0;
    float: left;
  }

  &.header-noborder {
    margin-top: 0;
    margin-bottom: 21px;
    padding: 0;
    border-top: none;
  }

  @extend %clearfix;
}

// publications
.publications {
  .publication-item {
    background: #fff;
    padding: 22.5px;
    border-top: 1px solid #e2eefa;

    small {
      font: 14px 'opensans-regular';
      color: #152935;
      display: block;
      margin: 0 0 10px;
    }

    h2 {
      font: 16px 'opensans-semibold';
      color: #152935;
    }

    p {
      font: 14px 'opensans-regular';
      color: #152935;
      margin: 0;
    }

    &:hover h2 {
      color: #047cc0;
    }
  }

  a {
    &:nth-child(even) {
      .publication-item {
        background: #f5f8fb;
      }
    }

    &:last-child {
      .publication-item {
        border-bottom: 1px solid #1fa3ff;
      }
    }
  }
}

// juridical
.juridical-tabs {
  display: flex;

  @include mq($until: small-desktop) {
    display: block;
  }

  > a,
  > span {
    position: relative;
    display: block;
    width: 100%;
    padding: 13.5px 20px;
    padding-left: 60px;
    border: 1px dashed #e2eefa;
    color: #152935;
    box-shadow: 0px 3px 11.52px 0.48px rgba(21, 41, 53, 0.05);

    i,
    svg {
      fill: #047cc0;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }

    &:hover {
      background: #f5f8fb;
      color: #152935;
    }

    &.active {
      background: #047cc0 !important;
      color: #fff !important;
      border: 1px solid #047cc0 !important;
      box-shadow: 0px 3px 11.52px 0.48px rgba(14, 73, 161, 0.1);

      svg {
        fill: #fff !important;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0%;
        left: 50%;
        width: 0;
        height: 0;
        border-top: solid 7.5px #047cc0;
        border-left: solid 7.5px transparent;
        border-right: solid 7.5px transparent;
        animation: arrow-move 0.5s forwards;
      }
    }

    &.disabled {
      background: #fff !important;
      border-color: #ccc;
      color: #ccc;
      cursor: not-allowed;

      svg {
        fill: #ccc;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

// juridical publication
.juridical-header {
  margin-top: 20px !important;
}

// google map
.gmnoprint,
.gm-style-cc {
  display: none !important;
}

.gm-style {
  button {
    display: none !important;
  }

  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"] {
    display: none !important;
  }
}

// employee
.employer-line {
  margin: 20px 0;
  height: 2px;
  background: #f5f8fb;
  clear: both;
}

.title-no-border {
  border-bottom: 0 !important;
}

// Edit/Add Template
.form-header-inputs {
  width: 50%;
  float: left;
  text-align: left;
  z-index: 5;
}

.right-column.right-two-column {
  display: flex;

  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.row-flex-template {
  display: flex;
  margin-bottom: 10px;

  .form-header-inputs {
    width: 100%;
  }

  > * {
    width: 100%;

    &:not(:last-child) {
      padding-right: 10px;
    }
  }
}

.ant-row {
  &:last-child {
    margin-bottom: 0;
  }

  .left-column,
  .right-column {
    float: left;
    width: 50%;
    text-align: left;
  }
  .left-column {
    .ant-checkbox-wrapper {
      float: right;
      margin: 10px 15px 0;
    }
  }
  label {
    display: inline-block;
    position: relative;
    padding-right: 5px;
  }
}

.ant-form-item-required:after {
  right: -8px;
}

// Accounts Page
.edit-button {
  margin-top: 7px;
}

// Edit Acces Modal
.permissions-list,
.access-item {
  float: left;
}

.permissions-list {
  margin-top: 96px;
  button {
    display: table;
    margin: 30px auto 29px auto;
  }
  .ant-list-item {
    padding: 20.5px 0;
  }
}

.added-value {
  display: block;
  margin-bottom: 15px;
}

.access-item {
  .ant-checkbox-wrapper {
    width: 100%;
    display: block;
    .ant-checkbox-inner {
      margin: 0 auto;
    }
  }
  .ant-list-item,
  .ant-select {
    padding-right: 15px;
    padding-left: 15px;
  }
  .ant-select {
    .ant-select-selection-selected-value {
      font-size: 12px;
    }
  }
  .permission-checkbox {
    padding: 10.5px 0;
  }
  .access-name {
    border: none;
    text-align: center;
    .ant-list-item-content {
      display: inline-block;
      font-weight: 600;
    }
  }
  .select-access {
    padding-top: 0;
  }
}
.acces-button {
  display: block;
}

.autocomplete {
  margin-bottom: 12.5px;

  input {
    font-style: normal;
  }
}

.ant-tree {
  > li {
    white-space: unset !important;
    margin: 0;

    > span,
    > span > .ant-tree-title {
      float: left !important;
    }

    > .ant-tree-node-content-wrapper {
      display: unset !important;
      height: auto;
      width: calc(100% - 65px);
    }

    > ul {
      clear: both;
    }

    @extend %clearfix;
  }
}

.tree-empty {
  text-align: center;
  color: #ccc;
  display: block;
}

.autocomplete-count {
  display: block;
  margin: 0 0 5px;

  b {
    color: #f86b45;
  }
}

.ratio {
  // width: 100px;
  height: 35px;

  .ratio-item {
    position: relative;
    display: inline-block;
    width: 15px;
    margin-right: 2.5px;
    max-height: 100%;
    cursor: pointer;
    min-height: 1px;

    &.positive {
      vertical-align: super;
    }

    &.negative {
      position: relative;
      vertical-align: text-top;
      top: 8px;
    }

    &:after {
      clear: both;
      content: ' ';
      display: block;
      width: 100%;
      height: 0;
      background: #006ea8;
      position: absolute;
      transition: 0.3s ease;
    }

    &.positive:after {
      bottom: 0;
    }

    &.negative:after {
      top: 0;
    }

    &:hover {
      // opacity: 0.8;

      &:after {
        height: 100%;
      }
    }
  }
}

.info-detail {
  position: relative;
  padding-right: 5px;
  margin-bottom: 10px;

  .block {
    display: block;
  }

  .show-more-button {
    color: #005281;
    cursor: pointer;
  }

  span {
    word-break: break-word;
    font: 14px 'opensans-regular';
    color: #152935;
  }

  &.info-detail-icon {
    padding-left: 22.5px;

    .icon {
      position: absolute;
      top: 2.5px;
      left: 0;
      width: 15px;
      height: 15px;

      svg {
        position: absolute;
        @extend %margin-absolute;
        fill: rgb(52, 111, 147);
      }
    }
  }
}

.company-segment {
  .ant-pagination {
    margin: 0 15px 15px;
    float: right;
  }
}

div.map-blur {
  filter: blur(1.5px);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    background-color: #fff;
    opacity: 0.7;
  }
}

.contact-info-w-map {
  padding-bottom: 210px !important;

  @include mq($until: small-desktop) {
    padding-bottom: 20px !important;
  }
}

.zh-filter-button {
  border-style: dashed;
  width: 100%;
  margin-top: 16px;
  border-color: #c1e6fe !important;

  .anticon {
    position: absolute;
    left: 10px;
    top: 5px;
    font-weight: 700;
    font-size: 13px;
  }
}

.map-loader {
  cursor: not-allowed;
  filter: blur(1.5px);
  background: url('/assets/img/MapPreloader.png');
  background-size: cover;
  height: 100%;
  width: 100%;
}

.iconed-text {
  display: inline-flex;
  align-items: center;
  svg {
    margin-top: 1px;
    ~ span {
      margin-left: 3px;
    }
  }
}

.zh-hidden-contact {
  &.disabled {
    cursor: not-allowed;
    color: #ccc !important;

    svg path {
      fill: #ccc;
    }
  }

  &:hover {
    color: #00aefa;
  }
}

.dot-tabs {
  .ant-tabs-ink-bar-animated {
    background-color: #047cc0;
  }
  .ant-tabs-nav .ant-tabs-tab {
    text-transform: capitalize;
    font-family: 'opensans-semibold';
    margin-right: 40px;
    white-space: nowrap;
    position: relative;
    a {
      color: rgba(0, 0, 0, 0.65);
      padding: 12px 16px;
      margin: -12px -16px;
    }

    &:not(:last-child):after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      top: 50%;
      margin-top: -2.5px;
      background: #ccc;
      right: -22.5px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab-active a {
    color: #152935;
  }
}
.cart-modal {
  width: 600px;
  margin: 10% auto;
  border: 1px solid #eaf0f6;
  border-radius: 7px;
  transform: translateY(12%);
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 7px 27px 3px rgba(31, 105, 179, 0.05);
  width: 600px;
  &.modal {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-animation: fade-it 0.3s cubic-bezier(0.8, 0.02, 0.45, 0.91) forwards;
    animation: fade-it 0.3s cubic-bezier(0.8, 0.02, 0.45, 0.91) forwards;
    z-index: 10;
  }
  // Responsive
  // @include mq($until: small-desktop) {
  //   width: 80%;
  // }
  .header-wrapper {
    padding: 35px 50px 15px 50px;
    .title {
      font-size: 18px;
      color: #45535e;
      padding: 1.5625rem 1.25rem;
      font-family: 'opensans-bold';
    }
    .fail {
      padding-bottom: 14px;
    }
    .text {
      color: #45535e;
      font-size: 16px;
    }
  }
  .contacts {
    padding: 15px 55px;
    border-top: 1px solid #eaf0f6;
    border-bottom: 1px solid #eaf0f6;
    background-color: #fbf9f2;
    color: #45535e;
  }
  .close-icon {
    position: absolute;
    right: -45px;
    cursor: pointer;
    // Responsive
    // @include mq($until: small-tablet) {
    //   right: rem(-30px);
    // }
  }
  button {
    width: 280px;
    margin: 30px 0;
    padding: 13.5px 0;
    // Responsive
    // @include mq($until: small-tablet) {
    //   width: 85%;
    // }
    span {
      margin-right: 10px;
      font-size: 17px;
      text-transform: uppercase;
    }
    &.zh-btn-fail {
      margin-top: 0;
    }
  }
}

.tabs.tabs-dotted {
  li {
    margin-right: 20px;
    white-space: nowrap;
    &:not(:last-child):after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #ccc;
      margin-left: 20px;
    }
  }
}
// .ant-tabs.tabs-dotted {
//   .ant-tabs-tab {
//     margin-right: 20px;
//     white-space: nowrap;
//     &:not(:last-child):after {
//       content: '';
//       display: inline-block;
//       vertical-align: middle;
//       width: 5px;
//       height: 5px;
//       border-radius: 50%;
//       background: #ccc;
//       margin-left: 20px;
//     }
//   }
// }

@keyframes drawer-animation {
  0% {
    opacity: 0;
    transform: translateX(343px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.drawer {
  background-color: #fff;
  width: calc(100% - 25px);
  position: fixed;
  top: 0;
  z-index: 100;
  overflow-y: auto;

  &.drawer-right {
    animation: drawer-animation 0.2s;
    right: 0;
  }

  &.drawer-right,
  &.drawer-left {
    height: 100%;
    max-width: 256px;
  }

  &.drawer-top,
  &.drawer-bottom {
    width: 100%;
    max-height: 256px;
  }
}

@keyframes alerts-drawer-animation {
  from {
    right: -700px;
  }
  to {
    right: 0;
  }
}

.alerts-drawer-table {
  width: 100%;

  tr:not(:last-child) {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  td {
    padding: 15px 12px;

    &:first-child {
      background: #f5f8fb;
    }
  }
}

.payment-layout {
  max-width: 600px;
  background: transparent;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;

  .payment-block {
    background: #fff;
    border: 1px solid #eaf0f6;
    box-shadow: 0 7px 27px 3px rgba(31, 105, 179, 0.05);
    padding: 30px 45px;
    @include mq($until: tablet) {
      width: 95%;
      margin: auto;
      padding: 30px 30px;
    }
    @include mq($until: small-tablet) {
      padding: 30px 15px;
    }
    .card-block label {
      margin-right: 55px;
      @include mq($until: small-tablet) {
        margin-right: 0px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .ant-radio-group {
      width: 100%;
      text-align: center;
    }
    .ant-radio-wrapper {
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .form-half .ant-form-item {
      @include mq($until: small-tablet) {
        width: auto;
        float: none;
      }
      &:nth-child(2n + 1) {
        @include mq($until: small-tablet) {
          margin-right: 0px;
        }
      }
      &:nth-child(2) {
        @include mq($until: small-tablet) {
          margin-left: 0px;
        }
      }
    }
  }
  .info-block {
    text-align: center;
    margin-bottom: 35px;
  }
  .info {
    margin-top: 10px;
    font-size: 16px;
  }
  .block-title {
    span {
      font-size: 26px;
      margin-left: 10px;
      vertical-align: bottom;
    }
    svg {
      font-size: 31px;
      fill: rgb(52, 111, 147);
    }
  }
  .submit-wrapper {
    margin-top: 35px;
    text-align: center;
    button:first-child {
      margin-right: 2%;
    }
  }
  .ant-checkbox-wrapper {
    display: flex;
  }
  .ant-checkbox + span {
    font: 13px 'opensans-regular';
    line-height: normal;
  }
  .link {
    color: #047cc0;
  }
  .zh-card-block {
    margin: 20px 0;
  }
  .title {
    margin-bottom: 20px;
    font: 18px 'opensans-bold';
    text-align: center;
    color: #20323f;
  }
}

.payment-modal {
  .ant-modal-footer {
    border-top: 0;
  }

  .payment-layout {
    max-width: 100%;
  }

  .submit-wrapper {
    margin-top: 0;
  }

  .payment-block {
    background: transparent;
    border: unset;
    box-shadow: unset;
  }
}

.custom-modal {
  position: absolute !important;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation: fade-it 0.3s cubic-bezier(0.8, 0.02, 0.45, 0.91) forwards;
  animation: fade-it 0.3s cubic-bezier(0.8, 0.02, 0.45, 0.91) forwards;
  z-index: 10;
  margin: 10% auto;
  border: 1px solid #eaf0f6;
  border-radius: 7px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 7px 27px 3px rgba(31, 105, 179, 0.05);
  width: 600px;
  .close-icon {
    position: absolute;
    right: -2.8125rem;
    cursor: pointer;
  }

  .header-wrapper {
    padding: 35px 50px 15px;
  }

  .title {
    padding: 25px 0 15px;
  }
  .zh-btn-secondary {
    margin-bottom: 30px;
  }
}

.zh-portal {
  position: fixed;
  -webkit-transition: all 3s ease;
  transition: all 3s ease;
  overflow-y: auto;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.vat-table {
  background-color: #f5f8fb;
  padding: 35px 50px;
  .ant-input {
    background: #ecf1f5;
    width: 200px;
  }
  .ant-form-inline .ant-form-item {
    vertical-align: baseline;

    .ant-form-item-label {
      display: block;
      text-align: left;
    }
  }
  .vat-description {
    margin-bottom: 15px;
  }
  .title {
    font-size: 18px;
    color: #304251;
  }
}

// Article
.styled-article {
  color: #455064;
  .ck.ck-label.ck-voice-label {
    display: none;
  }
  p {
    font-size: 14px;
    line-height: 1.857;
  }
  img {
    max-width: 100%;
    margin: 20px 0;
  }
  h1 {
    font-size: 22px;
    color: #000;
    line-height: 1.444;
    font-family: 'opensans-bold';
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
    font-family: 'opensans-bold';
    font-weight: normal;
  }
  p {
    line-height: 1.857;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  i {
    font-style: italic;
  }
  b,
  strong {
    font-weight: normal;
    font-family: 'opensans-bold';
  }
  .v-more {
    position: relative;
    color: blue;
    font-size: 14px;
    font-family: 'opensans-bold';
    &:hover {
      color: blue;
      svg {
        fill: blue;
      }
    }
    &:after {
      content: '';
      background: url('/img/arrow-right.png') no-repeat center;
      width: 6px;
      height: 10px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  ul,
  ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
}
.add-wrapper {
  padding: 5px 0 20px;
  .back-btn {
    background-color: #416a8d;
    float: right;
    border: 0;
    i {
      margin-right: 0;
      vertical-align: middle;
    }
  }
  form {
    margin-top: 50px;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 6px;
  }
  .mce-tinymce {
    box-shadow: none;
    border-color: #e2eefa;
  }
  .mce-panel {
    border-color: #e2eefa;
  }
  .mce-stack-layout-item {
    border-color: #e2eefa;
  }
}

.mce-widget.mce-notification {
  display: none;
  opacity: 0;
  pointer-events: none;
}
.tabs-flex {
  height: calc(100vh - 50px);
  .zh-icon-plus {
    font-size: 20px;
    fill: #416a8d;
  }
}
.updates-container {
  padding: 50px 0px 0;

  @include mq($until: small-desktop) {
    padding-top: 20px;
  }

  .custom-tabs {
    @include mq($until: desktop) {
      width: 35%;
    }

    .tabs-elem {
      @include mq($until: desktop) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .tabs-content {
    @include mq($until: desktop) {
      width: 65%;
      padding: 20px;
    }
  }
}
.updatesEdit-container {
  padding-bottom: 0;

  .sub-title {
    color: #395c79;
    font-size: 12px;
    font-weight: 600;
  }

  .access-settings-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    label {
      width: 150px;
    }

    span {
      color: #152935;
      font-size: 13px;
      font-family: 'opensans-regular';
      margin: 5px 0;
    }

    .ant-checkbox-wrapper {
      & + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
  }

  .title {
    color: #244b63;
    font-family: 'opensans-semibold';
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: #00aefa;
    }
  }

  .alerts .alert-header .check {
    width: 16px;
    margin-left: 16px;
  }
  .tabs-flex {
    height: calc(100vh - 140px);
  }
  .custom-tabs {
    border: 1px solid #e0e4e6;
  }
  .tabs-content {
    padding-top: 0;
  }
}
.static.tabs {
  .tabs-elem::before {
    content: '';
    background: url('/img/arrow-right.png') no-repeat center;
    width: 6px;
    height: 10px;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -5px;
  }
}
.custom-tabs {
  border-right: 1px solid #e2ecfa;
  width: 23%;
  height: 100%;
  float: left;
  overflow: auto;
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #547998;
  }

  .custom-tabs-header {
    pointer-events: inherit;
    padding: 15px 30px 15px 20px;
    cursor: auto;
    border-bottom: 1px solid #e0e4e6;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    color: #003366;
    position: relative;
    z-index: 1;
  }
  .anticon.anticon-delete {
    position: absolute;
    top: 50%;
    right: 10px;
    margin: 0;
    margin-top: -7px;
  }
  .add-p {
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 50%;
    margin-top: -10px;
    z-index: 999;
    width: 20px;
    height: 20px;
    svg {
      margin: 0;
    }
  }
  .tabs-elem {
    position: relative;
    background: #f5f7fa;
    padding: 14px 30px 14px 20px;
    color: #000;
    font-size: 14px;
    border-bottom: 1px solid #e0e4e6;
    height: auto;
    cursor: pointer;

    &.tab-active {
      background: #fff;
      border-right: 0;
      &:after {
        top: 0;
        bottom: 0;
        width: 1px;
        background: #fff;
        position: absolute;
        content: '';
      }
    }
  }
}

.tabs-content {
  padding: 40px;
  color: #455064;
  font-size: 14px;
  height: 100%;
  overflow: auto;
  float: right;
  width: 77%;

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #547998;
  }

  .ant-btn-primary {
    margin-bottom: 0;
  }

  .mce-tinymce {
    box-shadow: none;
    border-color: #e2eefa;
  }
  .mce-panel {
    border-color: #e2eefa;
    max-width: calc(100% - 2px);
  }
  .mce-stack-layout-item {
    border-color: #e2eefa;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 6px;
  }
}

.ant-btn-danger {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
  &:hover {
    background: rgba(245, 34, 45, 0.7);
    border-color: rgba(245, 34, 45, 0.7);
  }
}
.empty-block-title {
  padding: 55px 0;
  font-size: 18px;
  text-align: center;
  color: #45535e;
  &.grey {
    background: #f6f9fc;
  }
}

.zh-description {
  padding-bottom: 42.5px;

  a {
    padding: 10px;
    display: block;
    width: 100%;
    border-top: 1px solid #f5f7fa;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.iconed-status {
  display: block;
  position: relative;
  padding-left: 27px;
  color: rgb(42, 56, 70);
  text-transform: capitalize;
  .status-dot {
    top: 2px;
    position: absolute;
    left: 0px;
    display: inline-block;
    vertical-align: -5px;
    border-radius: 50%;
    border: 5px solid rgb(233, 244, 244);
    padding: 5px;
    &.status-active {
      background-color: rgb(32, 186, 133);
    }
    &.status-inactive {
      background-color: rgb(204, 204, 204);
    }
  }
}

.company_has-switch {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .margin-right {
    margin-right: 5px;
  }

  .margin-left {
    margin-left: 5px;
  }
}

.half-form {
  max-width: 600px;
  .title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .status-switch-title {
    font-size: 13px;
    margin-left: 10px;
  }

  .half-form-footer {
    margin-top: 25px;
    &:after {
      clear: both;
      content: '';
    }
    .half-form-footer-right {
      float: right;
      > a,
      > button {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
      }
    }
  }
}

.drawer-form {
  border: 1px solid #eaf0f6;
  box-shadow: 0 7px 27px 3px rgba(31, 105, 179, 0.05);
  background-color: #fff;
  height: 100vh;
  overflow: auto;
  padding-top: 20px;

  .title {
    font-family: 'opensans-bold';
    font-size: 1rem;
    color: #20323f;
    margin-bottom: 20px;
  }
  .form-part {
    padding: 20px 30px;
    &:not(:last-child) {
      border-bottom: 1px solid #eaf0f6;
    }
  }
  .form-info {
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 50%;

      > span {
        display: block;
        margin-right: 5px;
      }

      .info-label {
        font-family: 'opensans-bold';
        color: #036;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .info-desc .iconed-text {
        svg ~ span {
          margin-left: 5px;
        }
      }

      &:not(:last-child):not(:nth-last-child(2)) {
        margin-bottom: 10px;
      }
    }
  }
  .form-footer {
    padding: 20px 30px;
    text-align: right;

    button {
      width: auto;
      margin-left: 15px;
    }
  }
}

.table-user-activity {
  .ant-table-footer {
    background: #fff;
    text-align: right;
  }
  .ant-select-selection--single {
    background: #fff;
  }
  .dropdowns-wrapper {
    text-align: right;
    .ant-input {
      width: 300px;
    }
  }
  .table-title {
    font-size: 18px;
  }
  .ant-table-title {
    padding: 0 0 10px;
  }
  .ant-table-thead > tr > th {
    text-transform: uppercase;
    font-size: 11px;
    background: #fafbfd;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    word-break: normal !important;
    padding: 16px 25px;
  }
  tr:nth-child(even) {
    background: #fafbfd;
  }
  tbody {
    tr:first-child td {
      border-top: 1px solid #1fa3ff !important;
    }
    tr:nth-child(even) {
      background: #fafbfd;
    }
  }
  .ant-select {
    width: 100%;
  }

  .ant-table-body {
    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: #fff;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar {
      height: 5px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #547998;
    }
  }
}

.table-user-activity .dropdowns-wrapper,
.table-user-activity .table-title {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}

.head-title {
  border-bottom: 1px solid #ebf0f6;
  padding: 0 0 16px;
}

.ant-btn-background-ghost.ant-btn-primary.zh-filter-button {
  color: #047cc0;
  border-color: #047cc0;
}

.tree-popup-count {
  float: right;
  margin-right: 25px;
}

.person-card-desc {
  min-height: 19px;
}

.recaptcha-field {
  text-align: center;

  > div {
    display: inline-block;
  }
}

.switch-group {
  margin-top: 15px;
  width: 100%;

  .ant-radio-button-wrapper {
    font: 13px 'opensans-semibold';
    color: #244b63;
    background: #eff2f5;
    text-transform: capitalize;
    border: 0;
    height: 24px;
    line-height: 24px;
    width: 50%;
    text-align: center;

    &.ant-radio-button-wrapper-checked {
      color: #fff;
      background: #047cc0;
      border-radius: 4px;
    }
  }
}

.terms-wrapper {
  cursor: auto;

  .table-title {
    font-size: 18px;
  }

  .terms-and-conditions {
    .item-title {
      font-size: 17px;
      color: black;
    }

    li:hover {
      color: #036;
    }

    .ant-menu-item {
      height: auto !important;
      cursor: auto;
    }

    .terms-content {
      white-space: normal;
      color: #036;
      line-height: 27px;

      ul {
        font-size: 13px;
        list-style: circle;
      }

      ol {
        list-style: none;

        li {
          margin-bottom: 10px;
        }
      }

      div ul {
        margin-left: 30px;
      }
    }

    b {
      color: black;
    }
  }
}

.ant-popover {
  @include mq($until: small-desktop) {
    margin-left: -60px;
  }
}
.ant-popover-arrow {
  right: 16px;
  z-index: 3;
  border: none;
  top: 0px;
  width: 20px;
  height: 11px;
  background-color: transparent;
  transform: rotate(0);
  box-shadow: none !important;
  border-bottom: 1px solid #fff;

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: 8px solid #fff;
    border-right: 8px solid #fff;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    transform: rotate(-45deg);
    position: absolute;
    top: 3px;
    left: 2px;
  }

  &::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-top: 1px solid #cbd6e2;
    border-right: 1px solid #cbd6e2;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    transform: rotate(-45deg);
    position: absolute;
    top: 2px;
    left: 1px;
  }

  @include mq($until: small-desktop) {
    opacity: 0;
  }
}

.ant-popover-placement-bottomRight,
.ant-popover-placement-bottomLeft {
  .ant-popover-arrow {
    top: 0 !important;
  }
}

.ant-popover-inner {
  border: 1px solid #e2eefa;
  border-radius: 4px;
  z-index: 2;
  position: relative;
}

.ant-popover-inner-content {
  @include mq($until: desktop) {
    min-width: 200px;

    a {
      color: #152935;
    }
  }
}

.ant-modal-wrap.ant-design-draggable-modal {
  z-index: 4 !important;

  .ant-modal-content {
    max-width: 100% !important;
    min-width: 350px !important;

    @include mq($until: mobile) {
      min-width: 310px !important;

      .ant-modal-header {
        padding: 17px 10px;
      }
    }
  }
}

.switch--always-true {
  background-color: #1890ff;
}

.ant-input-affix-wrapper .ant-input-suffix {
  cursor: pointer;
  svg {
    fill: rgba(0, 0, 0, 0);
  }
}

.ant-input-suffix {
  .ant-btn {
    height: 30px !important;
    margin-right: 1px;
  }
}

.ant-radio-inner {
  background-color: #f5f7fa;
}

.ant-checkbox-inner {
  background-color: #f5f7fa;
}

.ant-checkbox {
  display: inline-block;
}
.filter-dropdown-wrapper {
  .subdivision-checkbox {
    padding: 0 10px;
    display: block;
    border: 1px solid #e2eefa;
    margin: 15px 15px 10px 15px;
    border-radius: 4px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    color: #363839;
    font-size: 13px;
    font-family: 'opensans-semibold';

    .ant-checkbox {
      float: left;
      position: relative;
      top: 6px;
      border-radius: 100%;
      overflow: hidden;
      border: 2px solid #e3eefa;
      height: 18px;
      width: 18px;

      .ant-checkbox-inner {
        border: none;
        background-color: #fff;

        &:after {
          border-color: #1890ff;
        }
      }
    }

    &.subdivision-checked {
      background-color: #047cc0;
      color: #fff;
      border: 2px solid #fff;
    }
  }
}

// .ant-tree-checkbox-checked {
//   .ant-tree-checkbox-inner {
//     background-color: #f5f7fa !important;
//     border: 1px solid #d9d9d9 !important;

//     &:after {
//       border: 2px solid #1890ff !important;
//       border-top: none !important;
//       border-left: none !important;
//     }
//   }
// }

.zh-icon-remove-trash-3 {
  height: 17px;
  width: 16px;
}

.ant-modal-mask {
  background-color: rgba(32, 50, 63, 0.25);
  // z-index: 99998 !important;
}

// .ant-modal-wrap {
//   z-index: 99999 !important;
// }

.per-page {
  color: #5a6872;
  font-size: 12px;
  font-family: 'opensans-regular';

  .ant-select-selection--single {
    margin-left: 10px;
  }
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='button'],
input[type='submit'],
textarea,
button {
  border-radius: 4px;
}

.logo-company-wrap {
  width: 35px;
  height: 35px;
  background-color: rgb(247, 163, 0);
  border-radius: 4px;
  overflow: hidden;
  color: #fff;
  text-align: center;

  span {
    line-height: 35px;
  }

  img {
    width: 100%;
  }
}

.filter-btn {
  margin: 15px 10px;
  background-color: #047cc0;
  font-size: 14px;
  color: #fff;
  font-family: 'opensans-semibold';
  border: none;
  width: 115px;
  padding: 0 20px 0 0;
  text-align: right;
  height: 38px;
  float: none;
  svg {
    path {
      stroke: #ffff;
    }
  }
}
.filter-btn:hover {
  background-color: #047cc0;
}

.tabs-popover-permission {
  .ant-popover-inner-content {
    color: black;
    font-weight: 600;
    p {
      margin: 0px;
    }
  }
}

.ant-message {
  z-index: 1052;
}

.ant-notification {
  z-index: 1052;
}

.footer-sms-verification {
  padding-bottom: 50px;
}

.sms-retrive {
  .ant-form-item-children {
    display: flex;
    justify-content: space-between;

    .resize-btn {
      max-width: 160px;
      width: 100%;
    }

    @include mq($until: small-tablet) {
      .resize-btn:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}

.name-filtered {
  cursor: pointer;
}
