@import '../helpers';

// messages area component
.messages-area {
  width: calc(100% - 280px);

  .table-above {
    padding: 0 20px;
    margin: 20px 0;

    .messages-count {
      color: #152935;
      font-size: 16px;
      font-family: 'opensans-semibold';
      line-height: 37px;
    }
  }
  .table-footer {
    padding: 0 20px;
    margin: 10px 0;

    .messages-count {
      color: #152935;
      font-size: 16px;
      font-family: 'opensans-semibold';
      line-height: 37px;
    }
  }

  .table-footer {
    .pagination {
      margin: 5px 0;
    }
  }

  .messages-table {
    color: #262626;
    font-size: 14px;
    font-family: 'opensans-regular';
    border-top: 1px solid #e2eefa;

    tr {
      &:hover,
      .ac {
        background-color: #f5fbf5;
      }

      td {
        vertical-align: top;
        padding: 20px !important;
        min-width: 150px;
        border-bottom: 1px solid #e2eefa !important;

        .zh-icon-arrow-right {
          position: relative;
          top: 3px;
        }
      }
      th {
        vertical-align: top;
        padding: 20px !important;
        min-width: 150px;

        .zh-icon-arrow-right {
          position: relative;
          top: 3px;
        }
      }
    }

    th {
      td {
        font-family: 'opensans-semibold';
        color: #152935;
        border-bottom: 1px solid #e2eefa !important;
      }
    }

    .new-unread-message {
      background: #f5fbf5 !important;
    }

    .name-column {
      width: 30%;

      .name-wrap {
        display: flex;

        img {
          width: 34px;
          height: 34px;
        }

        .name {
          margin-left: 15px;
          margin-top: 0px;
          width: calc(100% - 50px);
          font-size: 14px;
          color: #244b63;
          font-weight: 600;
          display: flex;
          align-items: center;
        }

        .tag-new {
          height: 20px;
          width: 52px;
          display: inline-block;
          vertical-align: middle;
          padding: 0 4px;
          margin: 0 7px;
          color: #fffeff;
          font-size: 14px;
          background-color: #68ba8a;
          border-radius: 4px;
          line-height: 19px;
        }
      }
    }

    .edited-column {
      width: 50%;
    }

    .date-column {
      width: 20%;
      min-width: 175px;
      font-size: 15px;
      color: #152935;

      .zh-icon-clock {
        margin: 0 8px 0 0;
        position: relative;
        top: 2px;

        path {
          fill: #879aac;
        }
      }
    }
  }
}
// .ant-table-tbody {
//   border-top: 1px solid #1fa3ff !important;
// }
