@import '../helpers';

// search component
.search-container {
  font-family: 'opensans-regular';
  padding-bottom: 0;
  margin-bottom: -1px;

  .title {
    color: #262626;
    font-family: 'opensans-semibold';
  }
}
