@import './helpers';

.activity-table-wrap {
  margin-top: -20px;

  @include mq($until: desktop) {
    .item-id {
      display: none;
    }
    .ant-checkbox-wrapper {
      position: relative;

      .item-info {
        padding: 0.625rem 1.125rem;
      }

      .icon-column,
      .desc-column {
        display: inline-block;
        vertical-align: middle;
        border-bottom: none;
      }
      .icon-column {
        width: 53px;
        padding-right: 0;
      }
      .desc-column {
        width: calc(100% - 53px);
        padding-top: 13px;
      }
      .date-column {
        position: absolute;
        bottom: 54px;
        left: 0;
        right: 0;
        padding-left: 71px;
        border-top: 1px solid #eaf0f6 !important;
        min-width: 175px;

        .info-content {
          margin-bottom: 0 !important;
        }
      }
      .arrow-column {
        text-align: center;
        margin-top: 68px;
      }
    }
  }
}
