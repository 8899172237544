.button-public {
  margin-top: 30px;
  display: block;
  width: 100%;
  max-width: 380px;
  height: 52px;
  background: #047cc0;
  box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
  border-radius: 4px;
}
