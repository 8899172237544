.auth-container {
  text-align: center;
  max-width: 480px;
  width: 100%;

  @include mq($until: small-tablet) {
    width: 95%;
  }

  &:not(.auth-container-wide) {
    position: absolute;
    @extend %margin-absolute;
  }

  &.auth-container-wide {
    width: 550px;
    position: relative;
    margin: 100px auto 170px;

    @include mq($until: tablet) {
      width: 95%;
    }
  }

  h1 {
    @include fontPoppins;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #262626;
  }

  .auth-wide-h1 {
    @include mq($until: small-tablet) {
      font-size: 30px;
    }
  }

  .reg-text {
    @include fontRoboto;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #262626;
    display: block;
    margin-bottom: 30px;
  }

  p {
    @include fontRoboto;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #262626;
    margin-bottom: 30px;
  }

  .auth-block {
    text-align: left;
    background-color: #ffff;
    padding: 35px 50px 50px 50px;
    border: 1px solid #dfe4ec;
    box-sizing: border-box;
    box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
    border-radius: 12px;

    button {
      svg {
        top: 30%;
      }
    }

    @include mq($until: small-tablet) {
      padding: 30px;
    }

    .ant-form-item-control-wrapper {
      .zh-btn {
        height: 52px;
      }
    }

    .form-half .ant-form-item {
      @include mq($until: small-tablet) {
        width: auto;
        float: none;
      }

      &:nth-child(2n) {
        @include mq($until: small-tablet) {
          margin: 0px;
        }
      }
      &:nth-child(2n + 1) {
        @include mq($until: small-tablet) {
          margin: 0px;
        }
      }
    }

    .reg-now {
      position: relative;
      margin-top: 65px;
      display: flex;
      justify-content: center;

      span {
        @include fontPoppins;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      &__link {
        margin: 0 5px;
        @include fontPoppins;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #047cc0;
      }
    }

    .reg-now::after {
      top: -40px;
      position: absolute;
      content: '';
      width: 480px;
      height: 1px;
      background: #dfe4ec;

      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  .auth-footer {
    padding: 0;
    // border: 1px solid #e2eefa;
    border-top: 0;

    a {
      padding: 13px;
      display: block;
      transition: 0.3s ease-out;

      &:hover {
        background-color: #fff;
        color: #78b8dc;
      }
    }
  }
}

.registration-step-one {
  .ant-select-selection {
    &__rendered {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 5px;
    }
  }
}

.auth-block {
  .ant-input,
  .ant-select-selection {
    background: #ffff;
    @include fontRoboto;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    border: 1px solid #dae3eb;
    border-radius: 4px;
    height: 48px !important;
    padding: 4px 15px !important;
    box-sizing: border-box;
  }

  .ant-select-selection__rendered {
    line-height: 41px;
  }

  .ant-select-open .ant-select-selection {
    background: #ffff;
  }

  .signup-title {
    font: 16px 'opensans-semibold';
    display: block;
    margin-bottom: 10px;
    color: #152935;
  }

  .signup-desc {
    display: block;
    margin-bottom: 22.5px;

    &.warning {
      color: #faad14;
    }
  }

  .signup-company-desc {
    display: block;
    margin-bottom: 20px;
    padding: 7.5px 15px;
    border-radius: 4px;
    border: 1px solid #78b8dc;
  }

  .signup-verification-statuses {
    .signup-status {
      margin: 15px 0;
      display: block;
      font-size: 16px;

      &:first-child {
        margin-top: 35px;
      }

      &.hold {
        span {
          color: #ccc;
        }

        i {
          color: #ccc;
        }
      }

      &.checking {
        span {
          color: #faad14;
        }

        i {
          color: #faad14;
        }
      }

      &.success {
        span {
          color: #52c41a;
        }

        i {
          color: #52c41a;
        }
      }

      &.fail {
        span {
          color: #f5222d;
        }

        i {
          color: #f5222d;
        }
      }

      i {
        vertical-align: -2px;
        font-size: 22px;
        margin-right: 10px;
      }
    }
  }

  .signup-email-validation {
    svg {
      margin: 0 auto 35px;
      display: block;
    }

    .signup-desc {
      margin-bottom: 35px;
    }
  }

  .signup-sms-input {
    margin: 0 auto 25px;
  }
}

.signup-email-validation {
  .zh-icon-sign-up-sms-confirmation,
  .zh-icon-sign-up-email-success {
    font-size: 146px;
  }
}

.auth-form-item {
  .ant-checkbox-wrapper {
    display: inline-flex;

    .ant-checkbox {
      margin-top: 2px;

      .ant-checkbox-inner {
        background: #f5f7f9;
        border: 1px solid #dae3eb;
        box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
        border-radius: 4px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #1890ff !important;
      }
    }

    span:last-child {
      display: flex;
      align-items: center;
      color: #5e6061;
      @include fontRoboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
