@import '../helpers';

.search-results {
  .list-count {
    color: #152935;
    font-size: 16px;
    font-family: 'opensans-semibold';
    margin: 30px 0;
  }

  .company-info {
    border: 1px solid #dfe6ee;

    .company-header {
      padding: 25px 30px 15px 30px;
      display: flex;

      .left {
        display: block;
        width: 72px;
        min-width: 72px;
        float: left;

        .wrap-icon {
          background-color: #f5f7fa;
          height: 72px;
          width: 72px;
          border-radius: 5px;
          position: relative;
          text-align: center;

          .zh-icon-buildings {
            width: 38px;
            height: 29px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }

      .right {
        display: block;
        float: left;
        position: relative;
        width: 100%;
        padding-left: 21px;

        .title,
        .title a {
          color: #152935;
          font-size: 20px;
          font-family: 'opensans-bold';
          margin-top: 6px;
        }

        .address {
          color: #162934;
          font-size: 14px;
          font-family: 'opensans-semibold';
          margin-top: 6px;
          float: left;

          .zh-icon-location {
            width: 13px;
            height: 17px;
            position: relative;
            top: 2px;
            margin-right: 16px;
          }
        }

        .monitoring {
          float: right;
          border: 1px solid #e2eefa;
          background-color: #fff;
          color: #363839;
          font-size: 14px;
          font-family: 'opensans-regular';
          padding: 0 30px 0 33px;
          height: 35px;
          border-radius: 4px;
          margin: 5px;

          svg {
            left: 10px;
            top: 16px;

            path {
              stroke: #047cc0;
            }
          }

          .zh-icon-menu-check {
            position: absolute;
            right: 10px;
            top: 21px;
            left: auto;

            path {
              fill: #047cc0;
              stroke: transparent;
            }
          }

          &.zh-btn-disabled {
            background-color: #fff !important;
            color: #363839 !important;
            border: 1px solid #e2eefa !important;

            svg {
              left: 12px;
              top: 20px;
              right: auto;
              bottom: auto;
            }
          }
        }
      }
    }

    .info {
      padding: 0 30px 15px 30px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, minmax(320px, 1fr));

      .column {
        width: 100%;
        padding-right: 20px;

        .item {
          font-size: 14px;
          display: flex;
          margin: 10px 0;

          .sub-title {
            color: #222d38;
            font-family: 'opensans-regular';
            margin: 0 10px 0 0;
            font-size: 14px;
          }

          .desc {
            color: #162935;
            font-size: 14px;
            font-family: 'opensans-bold';

            .status {
              display: inline-block;
              vertical-align: middle;
              height: 19px;
              padding: 0 4px;
              color: #fff;
              font-size: 14px;
              border-radius: 2px;
              background-color: #00c183;

              &.deleted,
              &.removed {
                color: #fff;
                background-color: #162934;
              }
            }

            &.contacts {
              color: #aaabac;
            }

            a,
            .link {
              color: #162935;
              text-decoration: none;
              cursor: pointer;

              &:hover {
                color: #467bbc;
              }
            }

            .p {
              & + .p {
                margin-top: 10px;
              }

              svg {
                width: 19px;
                height: 13px;
                position: relative;
                top: 2px;
              }

              .zh-icon-search-phone {
                height: 17px;
              }

              .zh-icon-search-website {
                height: 16px;
              }
            }
          }
        }
      }
    }

    .company-footer {
      padding: 25px 30px;
      color: #222d38;
      font-size: 14px;
      font-family: 'opensans-regular';
      border-top: 1px solid #dfe6ee;

      .sub-title {
        color: #222d38;
        font-size: 14px;
        font-family: 'opensans-regular';
        margin: 0 7px 0 0;
      }

      .desc {
        color: #222d37;
        font-size: 14px;
        font-family: 'opensans-bold';
      }
    }
  }

  .table-footer {
    margin-top: 50px;
    position: relative;
    float: left;
    width: 100%;

    &:after {
      content: '';
      display: block;
      height: 1px;
      background-color: #1fa3ff;
      position: absolute;
      top: -25px;
      left: -60px;
      right: -60px;
    }
  }
}

@media (max-width: 1024px) {
  .search-results .company-info {
    .info {
      display: block;
    }
  }
}

@media (max-width: 770px) {
  .search-results {
    .table-footer {
      &:after {
        left: -20px;
        right: -20px;
      }
    }

    .company-info {
      .company-header {
        .left {
          width: 40px;
          height: 40px;
          min-width: 40px;
          max-width: 40px;

          .wrap-icon {
            width: 40px;
            height: 40px;

            svg {
            }
          }
        }

        .right {
          .monitoring {
            float: none;
          }
        }
      }
    }
  }
}
.personal-page-pagination {
  padding-top: 20px;
  padding-left: 20px;
}
