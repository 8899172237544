@import '../helpers';

// footer component
.footer {
  background-color: #fff;
  float: left;
  width: 100%;

  .container {
    border: none;
    padding-top: 48px;
  }

  .footer-top {
    display: flex;
    padding: 0 0 45px 0;
    border-top: 1px solid #e2eefa;

    .left-column {
      display: flex;
      width: 75%;
      float: left;

      .links-list {
        width: 100%;

        .title {
          color: #152935;
          font-size: 16px;
          font-family: 'opensans-bold';
        }

        .soon {
          cursor: not-allowed;
          opacity: 0.5;

          span {
            color: red;
          }
        }

        ul {
          padding: 0;
          margin: 20px 0 0 0;
          list-style-type: none;

          li {
            margin: 12px 0;

            a {
              color: #5a6872;
              font-size: 15px;

              &:hover,
              .active {
                color: #262626;
              }
            }
          }
        }
      }

      .report-problem-button {
        background-color: #fff;
        color: #263745;
        font-size: 15px;
        border: 1px solid #ced9e4;
        border-radius: 4px;
        height: 50px;
        padding: 0 38px 0 47px;
        position: relative;
        margin-top: 20px;

        &:hover {
          border: 1px solid #de4747;
        }

        svg {
          width: 18px;
          height: 18px;
          position: absolute;
          left: 15px;
          top: 16px;
          transform: translateY(0);

          path {
            fill: #de4747;
          }
        }
      }
    }

    .right-column {
      width: 25%;
      float: left;

      .contacts {
        font-family: 'opensans-regular';
        color: #5e6061;
        text-align: right;

        .phone {
          margin-top: 10px;
        }

        .mail a,
        .phone a {
          color: #152935;
          font-size: 16px;
          font-family: 'opensans-bold';
        }

        .schedule {
          margin-top: 15px;
        }

        .social {
          margin-top: 25px;

          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
              display: inline-block;
              vertical-align: bottom;
              margin-left: 23px;

              a {
                .zh-icon-footer-facebook {
                  width: 18px;
                  height: 21px;
                }

                .zh-icon-footer-linkedin {
                  width: 21px;
                  height: 21px;
                }

                .zh-icon-footer-youtube {
                  width: 23px;
                  height: 20px;
                }

                &:hover {
                  svg {
                    path {
                      fill: #047cc0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .localization-btn,
      .localization-item {
        height: 50px;
        width: 240px;
        padding: 0 0 0 50px;
        border-radius: 4px;
        border: 1px solid #ced9e4;
        text-align: left;
        line-height: 18px;
        font-size: 14px;
        color: #263745;
        margin-top: 35px;

        &:hover,
        &.active {
          color: #047cc0;
          background-color: #fff;

          .zh-icon-lang-arrow-right {
            transform: rotate(90deg);
            top: 40%;
          }
        }

        .icon-size-small {
          left: 20px;
          width: 12px;
          height: 12px;
        }
        .flag-wrap {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;

          .icon-size-small {
            width: 18px;
            height: 18px;
          }
        }

        .zh-icon-lang-arrow-right {
          left: auto;
          right: 21px;
          transition: all 0.1s ease-in-out;

          path {
            fill: transparent;
            stroke: #1f323e;
          }
        }
      }
    }

    @media (max-width: 1100px) {
      .left-column {
        width: 100%;

        .report-problem-button {
          padding: 0 15px 0 42px;
        }
      }

      .right-column {
        width: 100%;
        padding: 35px 0 15px 0;

        .contacts {
          text-align: left;

          .social {
            ul {
              li {
                margin-right: 23px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    @media (max-width: 700px) {
      .left-column {
        display: block;

        .links-list {
          padding-bottom: 45px;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #5e6061;

    .container {
      padding: 0 60px;
      display: flex;
      justify-content: space-between;
    }

    a {
      color: #047cc0;
    }

    .left {
      float: left;
      width: 100%;
    }

    .center {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style-type: none;
          display: inline-block;
          // margin: 0 8px;

          a {
            font-family: 'opensans-semibold';

            &:hover {
              color: #5e6061;
            }
          }
        }
      }
    }

    .right {
      float: left;
      width: 15%;
      text-align: right;

      > div {
        text-align: right;
      }

      img {
        margin-top: 10px;
      }
    }

    @media (max-width: 760px) {
      .right {
        float: left;
        width: 15%;
        text-align: left;
        > div {
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 760px) {
    .footer-bottom {
      display: block;

      .left {
        width: 100%;
      }

      .center {
        width: 100%;
        // padding: 35px 0;
        text-align: left;

        ul {
          li {
            margin-left: 0;
          }
        }
      }

      .right {
        width: 100%;
      }
    }
  }

  @media (max-width: 700px) {
    .footer-top {
      padding: 10px 0 10px 0;
    }
  }

  .footer-bottom {
    padding: 51px 0 52px 0;
    border-top: 1px solid #e2eefa;
  }
}

.ant-dropdown-menu-item.localization-item {
  height: 41px;
  width: 240px;
  padding: 0 0 0 50px;
  text-align: left;
  line-height: 40px;
  font-size: 14px;
  color: #263745;
  position: relative;

  &:hover {
    color: #047cc0;
    background-color: #e2eefa;
  }

  .icon-size-small {
    position: absolute;
    top: 49%;
    transform: translateY(-50%);
    left: 20px;
    width: 18px;
    height: 18px;
  }

  .zh-icon-menu-check {
    position: absolute;
    left: auto;
    right: 21px;
    transition: all 0.1s ease-in-out;
    top: 23px;

    path {
      fill: #047cc0;
      stroke: transparent;
    }
  }
}
