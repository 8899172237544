@import '../helpers';

.wrap-sidebar-legal-bulletin {
  display: flex;

  .main-title {
    color: #152935;
    font-size: 16px;
    font-family: 'opensans-semibold';
    padding: 17px 18px;
    border-bottom: 1px solid #e2eefa;
  }

  .sidebar-legal-bulletin {
    width: 250px;
    min-width: 250px;
    border-right: 1px solid #e2eefa;

    .legal-menu {
      font-size: 16px;
      font-family: 'opensans-semibold';
      border-right: 0;

      .act-folder {
        color: #244b63;
      }

      svg {
        margin: 0 15px 0 0;
        position: relative;
        top: 4px;
        width: 19px;
        height: 19px;

        path,
        rect {
          transition: all 0.2s ease-in-out;
          fill: #087ec1;
        }
      }

      .ant-menu-submenu-title,
      .ant-menu-item {
        padding: 16px 18px !important;
        line-height: 0;
        height: auto;
        position: relative;

        &:hover {
          color: #fff;
        }
      }

      .ant-menu-item {
        padding-left: 18px !important;
        margin: 0;
        border-bottom: 1px solid #e2eefa;
      }

      .ant-menu-submenu-arrow:before,
      .ant-menu-submenu-arrow:after {
        background: #ced9e4;
      }

      .ant-menu-sub {
        border-bottom: 1px solid #e2eefa;
      }

      .ant-menu-sub {
        path,
        rect {
          stroke: transparent !important;
          fill: #244b63 !important;
        }
      }

      .ant-menu-submenu:hover {
        background-color: #047cc0;
        color: #fff;

        .tag {
          background-color: #fff;
          color: #262626;
        }

        svg {
          path {
            fill: #fff;
          }
        }

        .ant-menu-submenu-arrow {
          transform: rotate(0deg);
        }
        .ant-menu-submenu-arrow:before,
        .ant-menu-submenu-arrow:after {
          background: #fff;
        }
      }

      .industry-list {
        .ant-menu-item {
          padding-bottom: 0;
        }

        .ant-menu-sub {
          border-bottom: 0;
        }

        .ant-menu-item-selected {
          background-color: transparent;
        }

        .ant-menu-item:after {
          display: none;
        }

        li:first-child {
          border-bottom: none;
        }

        .ant-input-search-icon {
          height: 16px;
          width: 15px;
          color: #087ec1;
          font-size: 20px;
          position: relative;
          top: -2px;
        }
      }
    }

    .add-list-item {
      padding: 15px !important;

      &:before {
        display: none !important;
      }

      .link-list {
        color: #047cc0;
        font-size: 13px;
        font-family: 'opensans-semibold';
        text-align: center;
        height: 28px;
        border: 1px dashed #c1e6fe;
        line-height: 26px;
        position: relative;
        transition: all 0.2s ease-in-out;
        border-radius: 2px;
        width: 100%;

        .zh-icon-plus {
          position: absolute;
          left: 10px;
          top: 13px;
          width: 10px;
          height: 10px;

          path {
            fill: #047cc0;
          }
        }

        &:hover {
          background-color: #8fc3e5;
          color: #fff;
          border-color: transparent;

          .zh-icon-plus {
            path {
              fill: #fff !important;
            }
          }
        }
      }
    }

    .inner-list {
      padding: 0;
    }

    .checks-wrap {
      padding: 15px 0 0 0;

      &.hide-nodes {
        li:not(.ant-tree-treenode-checkbox-indeterminate):not(.ant-tree-treenode-checkbox-checked) {
          display: none;
        }
      }

      li {
        border: none !important;
        white-space: normal !important;

        .ant-tree-node-content-wrapper {
          max-width: 75%;
          height: auto;
        }
      }
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: transparent;
    }

    .ant-tree-child-tree {
      li {
        padding: 4px 0;
        color: #152935;
        font-size: 13px;
        line-height: 21px;

        ul {
          span.ant-tree-checkbox {
            width: 16px;
            height: 16px;
            margin-top: 0;
          }
        }

        span.ant-tree-switcher {
          line-height: 23px;
        }
        span.ant-tree-switcher.ant-tree-switcher_open:after {
          font-size: 14px;
        }
      }
    }

    .previous-bulletins {
      border-top: 1px solid #e2eefa;
      margin-top: -1px;
      padding: 10px 0 0 0;

      .main-title {
        border-bottom: none;
      }

      .previous-bulletins-list {
        list-style-type: none;
        margin: 0;
        padding: 0 20px;

        li {
          display: block;
          margin-bottom: 25px;
          font-size: 14px;
          font-family: 'opensans-semibold';

          a {
            color: #244b63;

            &:hover {
              color: #047cc0;
            }
          }

          .date {
            color: #152935;
            font-size: 12px;
            font-family: 'opensans-regular';
            margin-top: 12px;

            .zh-icon-clock {
              margin-right: 8px;
              position: relative;
              top: 1px;

              path {
                fill: #879bac;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-legal-bulletin-sub {
    width: 170px;
    min-width: 170px;
    border-right: 1px solid #e2eefa;

    .menu {
      margin-top: 5px;

      li {
        height: auto;
        line-height: 30px;
        position: relative;

        .link-list {
          position: relative;

          .zh-icon-bulletin-arrow-right {
            position: absolute;
            right: 0;
            top: 11px;
            display: none;
          }

          svg {
            width: 17px;
            height: 16px;
            margin-right: 10px;
            position: relative;
            top: 2px;

            path {
              fill: #5a6872;
            }
          }

          &:hover,
          &.active {
            color: #047cc0;

            .zh-icon-bulletin-arrow-right {
              display: block;
            }

            svg {
              path {
                fill: #047cc0;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-legal-bulletin-list {
    padding: 20px 15px;
    color: #263745;
    font-size: 14px;
    width: 280px;

    .date-picker-period {
      padding: 20px 0;
      display: flex;

      .ant-calendar-picker {
        width: 48%;

        &:last-child {
          margin-left: 4%;
        }
      }
    }

    .date-radio-group {
      padding: 10px 0;

      & > label {
        display: block;
        padding: 5px 0;
        font-size: 13px;
        color: #152935;
      }
    }
  }

  .sidebar-legal-bulletin-acts {
    & > .main-title {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1px;
        width: 1px;
        background-color: #fff;
        height: 100%;
      }
    }

    .legal-menu {
      .ant-menu-item:not(.add-list-item) {
        font-size: 13px;
        border-bottom: none;
        line-height: 2;
        padding: 7px 18px !important;

        &:after {
          display: none;
        }

        .zh-icon-bulletin-arrow-right-stroke {
          position: absolute;
          right: -10px;
          top: 40%;

          path {
            fill: #ced9e4;
            stroke: #ced9e4;
          }
        }

        .zh-icon-edit {
          position: absolute;
          right: -5px;
          top: 30%;

          path {
            fill: #ced9e4;
            stroke: #ced9e4;
          }
        }

        .zh-icon-filter-folder {
          path {
            fill: #5a6872;
          }
        }

        &:hover {
          background-color: #f5f8fb;

          a {
            color: #5a6872;
          }
        }

        &.ant-menu-item-selected {
          background-color: #047cc0;

          .act-folder {
            color: #ffff;
          }

          li {
            color: #fff;
          }

          a {
            color: #fff;
          }

          svg,
          svg.zh-icon-filter-folder {
            path {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
      }

      .add-list-item {
        margin-top: 15px;
        border: none;

        &:hover {
          background-color: transparent;
        }
        &.ant-menu-item-selected {
          background-color: transparent;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .drawer-legal-sidebar {
    .ant-drawer-body {
      padding: 0;
    }
  }
}
@media screen and (max-width: 450px) {
  .drawer-legal-sidebar {
    .ant-drawer-body {
      padding: 0;

      .wrap-sidebar-legal-bulletin {
        display: block;
        width: 100%;
      }

      .previous-bulletins {
        display: none;
      }

      .sidebar-legal-bulletin-sub {
        width: 100%;
        margin-top: 25px;

        .ant-menu {
          border-right: none;
        }
      }
    }
  }
}
