@import '../helpers';

// search-box components
.search-box {
  width: 100%;
  position: relative;

  > div {
    width: 100%;
  }

  .ant-select-open {
    transition: 0.3s ease;
    box-shadow: 3px 3px 3px 0 #313434;

    .ant-select-selection {
      background-color: #ffffff;
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field {
      color: #2c3e49 !important;
      padding-left: 0;
    }

    .ant-select-selection__clear {
      color: #2c3e49 !important;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    & + .zh-icon-search-billing {
      path {
        stroke: #047cc0;
      }
    }
  }

  .ant-select-selection__clear {
    background: transparent;
    color: #047cc0 !important;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .search-box-total.ant-select-dropdown-menu-item-selected {
    background-color: unset;
    font-weight: unset;
    color: unset;
  }

  .ant-select-dropdown-menu-item {
    padding: 10px 12px !important;
  }

  .ant-select-selection {
    background-color: #313434;
    border: 0;
  }

  .ant-select-selection--single {
    height: 36px;
    font-size: 16px;
    padding-left: 40px;
    border-radius: 4px;
  }

  .ant-select-selection__rendered {
    line-height: 38px;
    padding-left: 0;
    margin-left: 0;
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field {
    color: #fff;
    padding-left: 0;
    font: 14px 'opensans-regular';
  }

  .zh-icon-search-billing {
    font-size: 20px;
    font-size: 20px;
    position: absolute;
    left: 9px;
    top: 45%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;

    path {
      stroke: #fff;
    }
  }

  &.search-company-box {
    border-radius: 4px;
    height: 46px;

    .ant-select-selection {
      background-color: #fff;
      height: inherit;
      color: #152935;
      font-size: 15px;
      font-family: 'opensans-regular';

      .ant-select-selection__placeholder {
        height: inherit;
        color: #152935;
        font-size: 15px;
        font-family: 'opensans-regular';
      }
    }

    .ant-select {
      height: 46px;
      border-radius: 4px;

      &.ant-select-open {
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      }

      .ant-select-selection__clear {
        right: 140px;
        top: 14px;
        width: 28px;
        height: 28px;
        background-color: #f5f8fb;
        border: 1px solid #e2eefa;
        border-radius: 2px;

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          background-image: url('/svg/searchbox-clear.svg');
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }

      .ant-select-selection--single {
        height: 46px !important;
        border: 1px solid #c3c8c9;
        border-radius: 4px;

        .ant-select-selection__rendered {
          line-height: 44px !important;
        }
      }
    }

    .ant-select-search__field__wrap {
      height: 46px;

      .ant-select-search__field {
        height: 46px;
        color: #152935;
        font-size: 15px;
        font-family: 'opensans-regular';
      }
    }

    .zh-icon-add-company-search {
      position: absolute;
      top: 15px;
      left: 16px;
      width: 16px;
      height: 16px;
    }

    .search-btn {
      background-color: #047cc0;
      color: #fff;
      font-size: 15px;
      font-family: 'opensans-semibold';
      padding: 0 40px;
      height: inherit;
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      border-radius: 0 4px 4px 0;
      z-index: 2;

      &:hover {
        background-color: #78b8dc;
      }
    }
  }
}

.search-box-total.ant-select-dropdown-menu-item-active .search-block-total {
  color: #1fa3ff;
  padding: 10px 17.5px;

  svg {
    fill: #1fa3ff;
  }
}

.search-block {
  @extend %clearfix;

  .search-logo {
    float: left;
    display: block;
    margin: 7.5px 0;
    position: relative;
    border: 1px solid #e2eefa;
    border-radius: 4px;
    background: #f5f8fb;
    height: 33px;
    width: 33px;

    span {
      position: absolute;
      @extend %margin-absolute;
      font: 12px 'opensans-bold';
      position: absolute;
      color: #7a9cc8;
    }
  }

  img {
    border: 1px solid #e2eefa;
    border-radius: 4px;
    height: 33px;
    width: 33px;
    object-fit: contain;
    margin: 7.5px 0;
    display: block;
    float: left;
  }

  .search-block-text {
    float: left;
    margin-left: 12.5px;

    width: calc(100% - 66px);

    span {
      font: 14px 'opensans-semibold';
      color: #152935;
      display: block;
      top: 5px;
      position: relative;
    }

    small {
      font-size: 12px;
      color: #244b63;
      display: block;
      margin-top: 4px;
    }
  }
}

.search-block-total {
  display: block;
  background: #f5f8fb;
  margin: -5px -12.5px;
  padding: 10px 25px;
  color: #5e6061;
  transition: 0.3s ease;
  font-weight: 100 !important;

  .zh-icon {
    position: relative;
    top: 5px;
    margin-left: 5px;
    fill: #5e6061;
    font-size: 18px;
    vertical-align: -2px;
  }

  &:hover {
    color: #1fa3ff;
    padding: 10px 17.5px;

    svg {
      fill: #1fa3ff;
    }
  }
}
