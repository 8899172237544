@import '../helpers';

.modal-another-list {
  .ant-modal-body {
    padding-bottom: 0;
  }

  .search-field-group {
    margin-top: 0;

    .center-search {
      .search-box {
        border-left: 1px solid #ced9e4;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
      }
    }
  }

  .ant-modal-close {
    top: 0px;
    right: 5px;
    color: #5e6061;
  }

  .ant-modal-title {
    color: #262626;
    font-size: 16px;
    font-family: 'opensans-semibold';
    line-height: 14px;
  }

  .section-wrap {
    border-bottom: 1px solid #e2eefa;
    margin: 0 -24px;
    padding: 0 25px 10px 25px;
  }

  .companies-list {
    max-height: 310px;
    width: calc(100% + 45px);

    ul {
      margin: 0 -25px;
      padding: 0;
      list-style-type: none;

      li {
        padding: 9px 22px;
        position: relative;
        transition: all 0.2s ease-in-out;

        &:before {
          content: '';
          display: block;
          height: 1px;
          background-color: #eaf0f6;
          position: absolute;
          bottom: 0;
          left: 25px;
          right: 25px;
        }
        &.active:after {
          content: '';
          display: block;
          height: 1px;
          background-color: #047cc0;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        &:hover {
          background-color: #e9f2fb;
        }
        &.active {
          background-color: #e9f2fb;
        }

        &.pink {
          .list-company-wrap {
            background-color: #ab47bb;
          }
        }
        &.violet {
          .list-company-wrap {
            background-color: #5c6bbf;
          }
        }
        &.red {
          .list-company-wrap {
            background-color: #dc3c2f;
          }
        }
        &.aqua {
          .list-company-wrap {
            background-color: #27c6da;
          }
        }
        &.purple {
          .list-company-wrap {
            background-color: #c12d5e;
          }
        }

        .list-company-wrap {
          width: 42px;
          height: 42px;
          position: relative;
          background-color: #ab47bb;
          border-radius: 100%;
          display: inline-block;
          vertical-align: middle;
          border: 3px solid #fff;

          svg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }

        .company-info {
          display: inline-block;
          vertical-align: middle;
          margin-left: 15px;

          .name {
            color: #152935;
            font-size: 16px;
            font-family: 'opensans-semibold';
          }

          .meta-info {
            color: #5a6872;
            font-size: 14px;
            font-family: 'opensans-regular';

            .dot {
              margin: 0 10px;
            }
          }
        }

        .checkbox-wrap {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 18px;
          cursor: pointer;
          height: 16px;
          margin: auto;

          .ant-checkbox {
            border-radius: 0;
            background-color: #f5f7fa;

            .ant-checkbox-inner {
              border-radius: 0;
              background-color: #f5f7fa;
            }
          }

          .ant-checkbox-checked {
            width: 16px;
            height: 16px;

            .ant-checkbox-inner {
              border: 1px solid #ced9e4;
              border-radius: 0;
              width: 16px;
              height: 16px;

              &:after {
                display: block;
                width: 10px;
                height: 10px;
                background-color: #047cc0;
                transform: rotate(0);
                border: none;
                left: 2px;
                top: 2px;
              }
            }
          }
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    padding: 20px 0;

    .left {
      width: 50%;
      float: left;

      .add-list-button {
        height: 36px;
        padding: 0 37px 0 43px;
        position: relative;
        border: 1px solid #047cc0;
        border-radius: 4px;

        svg {
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          background-color: #047cc0;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .right {
      width: 50%;
      float: right;
      text-align: right;

      .place-list-button {
        height: 38px;
        padding: 0 27px;
        border-radius: 4px;
        border: none;
        background-color: #047cc0;
        color: #fff;

        &:hover {
          background-color: #78b8dc;
        }

        &:disabled {
          background-color: #edeff1;
          color: #b3b5b7;
        }
      }
    }
  }
}
