@import '../vars';

@mixin management-form {
  border: 1px solid #e3eefa;
  padding: 25px !important;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0 7px 27px 3px rgba(31, 105, 179, 0.05);
}

.half-form {
  max-width: 800px;
  margin-bottom: 25px;

  &:not(.pure) {
    @include management-form;
  }

  .title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .status-switch-title {
    font-size: 13px;
    margin-left: 10px;
  }

  .half-form-footer {
    margin-top: 25px;

    &:after {
      clear: both;
      content: ' ';
      display: block;
    }

    a,
    button {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
    }

    .half-form-footer-left {
      float: left;
      a,
      button {
        margin-left: 0;
        margin-right: 15px;
      }
    }

    .half-form-footer-right {
      float: right;
    }
  }
}

// permissions
.mn-permissions-title {
  margin-top: 15px;

  .actions {
    display: inline-block;

    > i {
      margin-left: 7.5px;
      font-size: 15px;
    }

    > span {
      cursor: pointer;
      border: 1px solid #e3edfa;
      text-transform: uppercase;
      display: inline-block;
      font-size: 10px;
      margin-left: 7.5px;
      padding: 1px 5px;
      border-radius: 4px;
      vertical-align: 2.5px;
      color: #436480;
      transition: 0.1s;

      &.loading {
        cursor: not-allowed;
        color: #ccc !important;
        background: transparent !important;
        border-color: #ccc !important;
      }

      &:hover {
        color: #fff;
        border: 1px solid #436480;
        background: #436480;
      }
    }
  }
}

.mn-permissions-alert {
  margin-bottom: 15px;
}

.mn-permissions {
  display: flex;
  flex-wrap: wrap;

  .mn-perm {
    margin-right: 5px;

    svg {
      position: absolute;
      top: 45%;
      right: 10px;
    }
  }
}

.mn-perm {
  vertical-align: top;
  min-width: 227px;
  position: relative;
  display: inline-block;
  background-color: #f7fafc;
  border: 1px solid #cbd6e2;
  border-radius: 3px;
  margin-top: 5px;
  padding: 5px 35px 15px 5px;

  .zh-icon {
    color: #009be9;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &.active {
    border-color: #009be9;

    .mn-perm-title {
      color: #009be9;
    }

    .anticon {
      color: #009be9;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .permission-switch {
      p {
        color: black !important;
      }
    }
  }

  .mn-perm-title {
    display: block;
    font-size: 12px;
    color: #ccc;
  }

  .mn-perm-input {
    margin-top: 2.5px;

    .ant-input-number {
      width: 66.5px;
      height: 26px;

      &:not(:last-child) {
        margin-right: 2.5px;
      }

      .ant-input-number-input {
        font-size: 12px;
      }
    }

    .permission-switch {
      display: flex;
      justify-content: space-between;
      padding-top: 6px;

      p {
        display: block;
        font-size: 12px;
        color: #ccc;
      }
    }
  }

  .anticon-bulb {
    cursor: pointer;
    position: absolute;
    font-size: 17px;
    right: 7.5px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
    transition: 0.2s;

    &:hover {
      color: #00aaff;
    }
  }
}

.mn-product-edit {
  .half-form {
    max-width: 66.5%;
  }
}

.permission-block {
  .permission-container {
    margin-bottom: 15px;
    display: block;

    .all-category-permission {
      vertical-align: top;
      max-width: 246px;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      background-color: #f7fafc;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
      margin-top: 5px;
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;

      span {
        font-size: 18px;
        color: #009be9;
      }

      .zh-icon {
        color: #047cc0;
        margin-top: 7px;
      }
    }

    .active-category {
      border: 1px solid #009be9 !important;
    }
  }
}

.mn-product-form {
  @include management-form;

  .mn-product-footer {
    margin-top: 25px;
    padding-top: 25px;
    text-align: right;

    a,
    button {
      display: inline-block;
      margin-left: 15px;
    }
  }
}

.filtered-table::after {
  clear: both;
  display: block;
  content: '';
}

.ml-1 {
  margin-left: $spacing;
}

.width-50 {
  width: 50%;
}

.link {
  color: #063467;

  &:hover {
    color: #1784c7;
  }

  &.title {
    font-family: 'opensans-semibold';
  }
}

.form-header-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.65);
}

.mn-empty-permissions {
  h2 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
