@import '../../helpers';

.profile-section-tooltip {
  max-width: 300px;
  width: 100%;

  .ant-popover-inner {
    box-shadow: 0px 13px 30.08px 1.92px rgba(21, 41, 53, 0.25);
    border: 1px solid rgb(203, 214, 226);
    border-radius: 2px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .profile-section-item {
    padding: 21px;

    &.small {
      padding: 0 22.5px;
    }

    > ul {
      padding: 0;
      margin: 7.5px -22.5px;

      li {
        list-style: none;
        position: relative;

        &:hover {
          a,
          span {
            color: #047cc0;
          }

          svg {
            path {
              fill: #047cc0;
            }

            &.zh-icon-help-menu {
              path {
                stroke: #047cc0 !important;
              }
            }
          }

          .zh-icon-shipment-box {
            path {
              fill: #fff;
              stroke: #047cc0;
            }
          }
        }

        &.active {
          background: #fafbfd;

          a,
          span {
            color: #047cc0;
          }

          svg {
            path {
              fill: #047cc0;
            }

            &.zh-icon-help-menu {
              path {
                stroke: #047cc0;
              }
            }
          }
        }

        a,
        > span {
          cursor: pointer;
          display: block;
          padding: 10px 0 10px 60px;
          font: 14px 'opensans-semibold';
          color: #263745;
        }

        .svg-container {
          position: absolute;
          top: 50%;
          left: 22.5px;
          width: 25px;
          height: 25px;
          transform: translateY(-50%);

          > svg {
            @extend %margin-absolute;
          }

          svg {
            font-size: 22px;
            fill: rgb(31, 50, 62);

            &.zh-icon-shipment-box {
              path {
                fill: transparent;
              }
            }

            &.zh-icon-help-menu {
              path {
                fill: transparent;
                stroke: #244b63;
              }
            }
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #cbd6e2;
    }
  }
}
