.input-phone-text {
  span {
    margin-left: 20px;
  }
}

.phone-input {
  position: relative;
  display: inline-block;
  width: calc(100% - 31px);

  .flag-container {
    position: absolute;

    .iti-flag {
      display: none;
    }

    .selected-dial-code {
      height: 36px;
      width: 50px;
      padding-left: 11px;
    }
  }

  input {
    width: 100%;
    background-color: #f5f7fa;
    border: 1px solid #e2eefa;
    height: 36px;
    transition: all 0.3s;
    border-radius: 4px;
    outline: none;
    padding: 4px 11px 4px 50px;
  }

  input::placeholder {
    color: #d6c9bf;
  }

  input:hover {
    border-color: #40a9ff;
  }

  input:focus {
    background-color: #ffff;
    border: 1px solid #e2eefa;
  }
}
