@import '../helpers';

.prospect-list-card-header {
  width: 100%;
  margin-bottom: 25px;
  display: flex;

  .card-header-avatar {
    background: #f5f7fa;
    color: #acacad;
    margin-right: 20px;

    @include mq($until: small-desktop) {
      background: none;
    }
  }

  .card-header-info {
    padding-top: 10px;

    &.card-header-info-padding {
      padding-top: 20px;
    }

    > .link,
    > .link-disabled {
      font-size: 20px;
      font-family: 'Open Sans';
      font-weight: 700;
    }

    .card-header-avatar-address,
    .card-header-avatar-company {
      font-size: 14px;
      font-family: 'Open Sans';
      font-weight: 600;

      i {
        color: #849bc5;
        margin-right: 2.5px;
      }
    }
  }
}
