@import '../helpers';

.locations-list {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  .form-inline {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-right: 20px;

    .add-location-button {
      padding-left: 40px;
      padding-right: 40px;
      border-color: #047cc0;
      transition: 0s;

      .zh-icon-marker-small {
        left: 15px;
      }
    }

    .add-location-button:nth-child(1) {
      margin-left: 20px;
    }

    .add-location-button:hover {
      background-color: #047cc0;
      border-color: #047cc0;
      color: #ffff;

      .zh-icon-marker-small {
        fill: #ffff;
      }
    }
  }

  form {
    border-left: 1px solid #e2eefa;
    border-right: 1px solid #e2eefa;
    margin-top: 0px;
  }

  .fade-in {
    border: none;
  }

  .table-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 20px;
    border-left: 1px solid #e2eefa;
    border-right: 1px solid #e2eefa;
    border-top: 1px solid #e2eefa;

    .table-above {
      border-bottom: 1px solid #e2eefa;
      margin-bottom: 0px;

      .pull-right {
        margin-top: 20px;
        margin-right: 25px;
        margin-bottom: 20px;
      }

      .pull-right:hover {
        path {
          stroke: #ffff;
          fill: #ffff;
        }
      }
    }

    .in-two-rows {
      padding-bottom: 15px;
    }

    .table-action-text {
      margin-right: 30px;
    }

    .clearfix {
      border-bottom: 1px solid #e2eefa;
      margin-left: 25px;
      margin-top: 20px;

      .pull-left {
        margin: 0px;
      }
    }

    .collapsing {
      padding: 0px !important;
    }
  }

  .left-side-div {
    border-bottom: 1px solid #e2eefa !important;
  }

  .pull-left {
    margin-left: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .pull-right {
    margin-right: 20px;
    margin-top: 25px;
  }

  .form-footer {
    padding-bottom: 20px;
  }

  .sort-select {
    .ant-select-selection {
      background-color: #fff;
    }
  }

  .table-no-header {
    tr:nth-child(2n) {
      background-color: #fff;
    }

    td:first-child {
      padding-right: 0px !important;
      padding-left: 0 !important;
    }

    td.collapsing {
      width: 0;
      white-space: nowrap;
    }

    .control-wrap {
      width: 50px !important;
    }

    .control-wrap {
      svg {
        width: 16px;
        margin-right: 7px;

        path {
          fill: #047cc0;
        }
      }

      i {
        width: 16px;
        margin-right: 7px;
        color: #047cc0;
        font-size: 16px;
      }
    }

    @include mq($from: small-tablet) {
      .control-wrap-mobile {
        display: none;
      }

      .popover-more {
        display: none;
      }
    }
  }

  .zh-icon-trash {
    height: 16px;
  }

  .in-two-rows {
    padding-left: 0 !important;
  }

  .popover-more {
    position: absolute;
    right: 15px;
    top: 30px;
  }
}

div.control-wrap-desktop {
  display: none !important;
}

.notification-form {
  margin-top: 20px;

  .anticon-delete {
    width: 16px;
    margin-right: 7px;
    color: #047cc0;
    font-size: 16px;
  }
}

.location-name {
  font: 16px 'opensans-semibold';
  margin-top: -7px;

  .ico-block {
    vertical-align: middle;
    background-color: #047cc0;
    margin: 0;

    svg {
      font-size: 18px;

      path {
        fill: #fff;
      }
    }
  }

  & > span {
    padding-left: 15px;
    vertical-align: middle;
  }
}

.ico-block {
  display: inline-block;
  background-color: #f5f7fa;
  width: 34px;
  height: 34px;
  margin: 5px 10px 0 0;
  border-radius: 5px;
  position: relative;

  svg {
    fill: #4273b1;
    position: absolute !important;
    margin: auto;
    left: 0;
    right: 0;
    top: 0 !important;
    bottom: 0;
  }
}

.ant-table-footer {
  background-color: #fff;
  padding: 16px 0;
}

.address-rows {
  padding: 20px 18px;
  border-top: 1px solid #e2eefa;
  border-bottom: 1px solid #1fa3ff;
  margin: 20px 0;

  @include mq(mobile, tablet) {
    display: block;
  }

  & > .clearfix {
    display: flex;
    width: 100%;
    max-width: 700px;

    > div {
      margin-right: 15px;
    }

    &.half {
      width: 50%;
    }
  }

  .ant-row {
    width: 100% !important;
  }

  @include mq(mobile, tablet) {
    .ant-row {
      margin: 15px 0 !important;
    }
  }

  input[type='text'],
  .ant-select-selection {
    background-color: #fff;
    border: 1px solid #78b8dc;
  }

  .no-right-border {
    .ant-select-selection {
      border-right: none;

      @include mq(mobile, tablet) {
        border-right: 1px solid #1fa3ff;
      }
    }
  }
}

.notifications-info {
  .title {
    margin: 0;
    padding: 20px 5px;
    color: #152935;
    text-align: center;
    font: 16px 'opensans-semibold';
  }

  .notifications-wrap {
    h1 {
      border: none;
    }

    .setting-rows {
      display: flex;
      // border-left: 1px solid #e2eefa;

      @include mq(mobile, tablet) {
        display: block;
      }

      .ant-checkbox-group {
        margin-top: 10px;
      }

      .form-helper {
        &.form-helper-large {
          font-size: 16px;
          font-family: 'opensans-regular';
          margin-bottom: 20px;
        }
      }

      .ant-row {
        width: 50%;
        border: 1px solid #e2eefa;
        border-left: none;
        padding: 18px;
        margin-bottom: 0;

        @include mq(mobile, tablet) {
          display: inline-block;
        }
      }

      .ant-row:last-child {
        width: 100%;
        border-right: none;

        @include mq(mobile, tablet) {
          border-top: none;
        }
      }

      .ant-checkbox-wrapper,
      .ant-radio-wrapper {
        width: 100%;
        padding: 5px 0;
        font-family: 'opensans-semibold';
        color: #152935;
        font-size: 13px;
        line-height: 15px;
        margin: 0;
        padding: 10px 0;
        display: block;

        .ant-checkbox {
          float: left;

          & + span {
            font-size: 13px;
          }
        }

        & + .ant-checkbox-wrapper,
        & + .ant-radio-wrapper {
          margin-left: 0;
        }
      }

      .method-wrap {
        .ant-row {
          width: 100%;
          border: none;
          padding: 0;

          .ant-checkbox-wrapper,
          .ant-radio-wrapper {
            width: auto;
            display: inline-block;
            padding: 0;
          }

          label {
            padding-right: 15px !important;
          }

          .ant-input {
            display: inline-block;
            width: calc(100% - 31px);
          }
        }

        .separator {
          margin: 20px -18px 20px -18px;
          height: 1px;
          background-color: #e2eefa;
        }
      }
    }
  }
}

.wrap-options {
  padding: 6px 0 0 28px;

  & > div {
    border: 1px solid #c1e6fe;
    border-radius: 4px;
    margin: 3px 6px 0 3px;
    padding: 0 9px;
    display: inline-block;
  }

  .zh-icon-close {
    font-size: 16px;
    position: relative;
    top: 3px;
  }

  .remove-option {
    cursor: pointer;
  }
}
