@import '../helpers';

.table-item:last-child {
  border-bottom: 1px #1fa3ff solid;
}

.table-item:nth-child(1) {
  border-top: 1px #1fa3ff solid;
}

.table-item:nth-child(2n) {
  background: #fafbfd !important;
}

.ant-table-row:nth-child(2n) {
  background: #fafbfd !important;
}

.ant-table-row:last-child {
  border-bottom: 1px #1fa3ff solid;
}

.ant-table-row:hover {
  background-color: none;
}

.ant-table-row {
  margin-top: 3px;
}

.ant-table-thead {
  border-bottom: 1px #1fa3ff solid !important;
}

.ant-table .ant-table-content .ant-table-body table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ant-table-row:first-child {
  border-top: 1px #1fa3ff solid !important;
}

.item-number {
  width: 40px;
  height: 20px;
}

.item-name {
  font-size: 14px;
  font-family: opensans-semibold;
  max-width: 278px;
  width: 100%;

  a {
    color: #063467;
  }
  a:hover {
    color: #047cc0;
  }
}

.item-users {
  max-width: 168px;
  width: 100%;
  color: #262626;
  font-size: 14px;
  font-family: opensans-regular;
}

.item-manager {
  max-width: 168px;
  width: 100%;
  color: #262626;
  font-size: 14px;
  font-family: opensans-regular;
}

.item-created-at {
  max-width: 168px;
  width: 100%;
  color: #262626;
  font-size: 14px;
  font-family: opensans-regular;

  svg {
    top: 0px !important;
  }
}

.item-expired-at {
  max-width: 168px;
  width: 100%;
  color: #262626;
  font-size: 14px;
  font-family: opensans-regular;

  svg {
    top: 0px !important;
  }
}

.item-status {
  max-width: 168px;
  width: 100%;
  color: #262626;
  font-size: 14px;
  font-family: opensans-regular;
}
