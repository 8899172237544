@import '../helpers';

// search component
.search-start {
  .title {
    margin-top: 15px;
    font-size: 36px;
  }

  .sub-title {
    color: #5e6061;
    font-size: 24px;
    margin-top: 20px;

    .count {
      font-size: 24px;
      color: #047cc0;
      font-family: 'opensans-semibold';
    }
  }

  @media (max-width: 780px) {
    .title {
      font-size: 20px;
    }

    .sub-title {
      font-size: 17px;

      .count {
        font-size: 16px;
      }
    }
  }

  .popular-searches {
    color: #5a6872;
    font-size: 13px;
    margin-top: 20px;
    font-family: 'opensans-semibold';
  }
}

.search-field-group {
  display: flex;
  margin-top: 35px;
  position: relative;

  &.search-field-group-nocountry {
    .center-search {
      max-width: 700px;
      width: 100%;
      border-radius: 4px 0 0 4px;
      border-left: 1px solid #ced9e4;
      overflow: hidden;
    }

    .change-type-select {
      max-width: 300px;
      width: 100%;
      background-color: #f5f7fa;
      color: #152935;
      height: 50px;
      border: 1px solid #ced9e4;

      .ant-select-selection--single {
        background-color: #f5f7fa;
        height: 48px;
        border: none;
      }

      .ant-select-selection__rendered {
        height: 48px;

        > div {
          margin-top: -10px;
        }

        .ant-select-selection-selected-value {
          margin-top: 14px;
        }
      }

      .ant-select-selection {
        background-color: #f5f7fa;

        .ant-select-search__field {
          color: #152935;
          padding-left: 0;
        }

        .ant-select-selection__placeholder,
        .ant-select-search__field {
          color: #152935;
          font: 14px 'opensans-regular';
        }

        .ant-select-selection-selected-value {
          color: #152935;
          font: 14px 'opensans-regular';
        }
      }
    }
  }

  .country-btn {
    height: 50px;
    width: 240px;
    padding: 0 0 0 50px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #ced9e4;
    text-align: left;
    line-height: 18px;
    font-size: 14px;
    color: #263745;

    &:hover,
    &.active {
      color: #047cc0;
      background-color: #fff;

      .zh-icon-lang-arrow-right {
        transform: rotate(90deg);
        top: 40%;
      }
    }

    .icon-size-small {
      left: 20px;
      width: 12px;
      height: 12px;
    }
    .flag-wrap {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      .icon-size-small {
        width: 18px;
        height: 18px;
      }
    }

    .zh-icon-lang-arrow-right {
      left: auto;
      right: 21px;
      transition: all 0.1s ease-in-out;

      path {
        fill: transparent;
        stroke: #1f323e;
      }
    }
  }

  @media (max-width: 450px) {
    .country-btn {
      width: 30px;

      .flag-wrap {
        left: -2px;
      }

      .zh-icon-lang-arrow-right {
        display: none;
      }
    }
  }

  .countries-popup-overlay {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced9e4;
    border-radius: 4px;
    box-shadow: 0 5px 12px #ebebeb;
    padding: 20px;

    .countries-popup {
      width: 100%;

      .title {
        font-size: 16px;
        color: #262626;
        margin: 0;
      }

      .countries-list {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        margin: 0;
        padding: 10px 0;

        li {
          cursor: pointer;
          padding: 8px 0;
          color: #152935;
          font-size: 15px;

          &:hover {
            color: #047cc0;
          }
          &.active {
            font-family: 'opensans-semibold';
          }

          .flag {
            margin: -3px 10px 0 0;
          }

          svg {
            vertical-align: middle;
          }

          .zh-icon-menu-check {
            margin: 3px 0 0 5px;
          }
        }
      }
    }
  }

  .center-search {
    width: 100%;

    .search-box {
      height: 50px;
      border-top: 1px solid #ced9e4;
      border-bottom: 1px solid #ced9e4;

      & > div {
        height: 48px;
      }

      .ant-select-open {
        box-shadow: 0 5px 12px #ebebeb;
      }

      .ant-select-selection--single {
        height: 48px;
        border-radius: 0;

        .ant-select-selection__rendered {
          height: 48px;
        }
      }

      .ant-select-selection {
        background-color: #f5f7fa;

        .ant-select-search__field {
          color: #152935;
          padding-left: 0;
        }

        .ant-select-selection__placeholder,
        .ant-select-search__field {
          color: #152935;
        }
      }

      .zh-icon-search-billing {
        top: 51%;
        left: 12px;

        path {
          stroke: #152935;
        }
      }

      .help-link {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 2;

        &:hover {
          svg {
            path {
              fill: #047cc0;
            }
          }
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .search-button {
    height: 50px;
    width: 160px;
    padding: 0;
    margin: 0;
    text-align: center;
    background-color: #047cc0;
    font-size: 16px;
    font-family: 'opensans-semibold';
    border-radius: 0 4px 4px 0;

    &:hover {
      background-color: #78b8dc;
    }
  }
}
