.pagination {
  .pages-wrapper {
    .button {
      display: inline-block;
      border: 1px solid #e2eefa;
      color: #152935;
      border-radius: 4px;
      padding: 5px 10px;
      margin-right: 5px;
    }

    .selected {
      color: #047cc0;
      border: 1px solid #047cc0 !important;
    }

    span {
      margin-right: 5px;
      cursor: pointer;
    }
  }
}
