@import '../../../assets/scss/helpers';

.navigation-block-buttons {
  position: absolute;
  left: 12.5px;
  top: 65px;

  @include mq($until: small-desktop) {
    width: 200px;
    text-align: center;
    left: 50%;
    top: 155px;
    transform: translate(-50%, -50%);
  }

  .button-active {
    background: #047cc0 !important;
    color: #ffff !important;
  }

  .button-active:hover {
    background: #047cc0 !important;
    color: #ffff;
  }

  button {
    border: none;
    background: #eff2f5 !important;
    color: #434546 !important;

    @include mq($until: small-desktop) {
      margin-bottom: 10px;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
}
