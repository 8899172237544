@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@font-face {
  font-family: 'opensans-bold';
  src: url('./fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'opensans-bolditalic';
  src: url('./fonts/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'opensans-extrabold';
  src: url('./fonts/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'opensans-extrabolditalic';
  src: url('./fonts/OpenSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'opensans-italic';
  src: url('./fonts/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'opensans-light';
  src: url('./fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'opensans-lightitalic';
  src: url('./fonts/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'opensans-regular';
  src: url('./fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'opensans-semibold';
  src: url('./fonts/OpenSans-Semibold.ttf');
}

@font-face {
  font-family: 'opensans-semibolditalic';
  src: url('./fonts/OpenSans-SemiboldItalic.ttf');
}

@mixin fontPoppins {
  font-family: 'Poppins', sans-serif;
}

@mixin fontRoboto {
  font-family: 'Roboto', sans-serif;
}
