.success-modal-wrapper {
  text-align: center;
  padding: 0 50px;

  .title {
    font-family: 'opensans-semibold';
  }

  svg,
  img {
    margin: 20px 0;
  }

  button {
    margin-top: 25px;
  }

  .validation-title {
    margin-bottom: 10px;
    font-size: 16px;
    color: #000;
  }
}
