@import '../helpers';

// sidebar component
.sidebar {
  width: 280px;
  min-width: 280px;
  padding: 15px 0 0 0;
  border-right: 1px solid #e2eefa;
  background-color: #fff;

  .messages-menu {
    color: #244b63;
    font-size: 16px;
    font-family: 'opensans-semibold';
    border-right: 0;

    a {
      font-size: 16px;
    }

    .zh-icon-alert-settings {
      width: 17px;
      height: 17px;

      path {
        stroke: transparent;
        fill: #244b63;
      }
    }

    svg {
      margin: 0 17px 0 0;
      position: relative;
      top: 2px;

      path,
      rect {
        transition: all 0.2s ease-in-out;
        stroke: #244b63;
        fill: transparent;
      }
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      padding: 20px 18px !important;
      line-height: 0;
      height: auto;
      position: relative;
      font-size: 16px;

      &:hover {
        color: #fff;
      }

      .ant-menu-item-selected {
        .zh-icon-unread-list {
          svg {
            path {
              fill: #047cc0 !important;
              stroke: #047cc0 !important;
            }
          }
        }
      }
    }

    .ant-menu-item {
      padding-left: 18px !important;
      margin: 0;
    }

    .ant-menu-submenu-arrow {
      transform: rotate(-90deg);
    }

    .ant-menu-submenu-arrow:before,
    .ant-menu-submenu-arrow:after {
      background: #ced9e4;
    }

    .ant-menu-sub {
      border-bottom: 1px solid #e2eefa;
    }

    .ant-menu-sub {
      path,
      rect {
        stroke: transparent !important;
        fill: #244b63 !important;
      }
    }

    .ant-menu-submenu-open {
      background-color: #047cc0;
      color: #ffff;

      svg {
        path {
          stroke: #ffff;
        }
      }

      .ant-menu-submenu-arrow {
        transform: rotate(0deg);
      }

      .tag {
        background-color: #ffff !important;
        color: #262626 !important;
      }
    }

    .ant-menu-submenu {
      a {
        font-size: 13px;
        font-weight: 500;
      }

      .arrow-right {
        position: absolute;
        top: 22px;
        right: -6px;
      }

      .tag2 {
        background-color: #047cc0;
        color: #ffff;
      }
    }

    .ant-menu-submenu:hover {
      background-color: #047cc0;
      color: #fff;

      .tag {
        background-color: #fff;
        color: #262626;
      }

      svg {
        path {
          stroke: #fff;
          fill: transparent;
        }
      }

      .ant-menu-sub {
        .tag {
          background-color: #047cc0;
          color: #fff;
        }
      }

      .ant-menu-submenu-arrow {
        transform: rotate(0deg);
      }

      .ant-menu-submenu-arrow:before,
      .ant-menu-submenu-arrow:after {
        background: #fff;
      }
    }

    .tag {
      display: inline-block;
      vertical-align: middle;
      padding: 0 6px;
      height: 20px;
      font-size: 14px;
      color: #fff;
      border-radius: 10px;
      background-color: #047cc0;
      line-height: 20px;
      position: absolute;
      right: 39px;
      transition: all 0.2s ease-in-out;
      top: 33%;
    }

    .tag2 {
      display: inline-block;
      vertical-align: middle;
      padding: 0 6px;
      height: 20px;
      font-size: 14px;
      color: #fff;
      border-radius: 10px;
      background-color: #047cc0;
      line-height: 20px;
      position: absolute;
      right: 39px;
      transition: all 0.2s ease-in-out;
      top: 33%;
    }
  }
}

.messages-container {
  .sidebar {
    .messages-menu {
      li {
        svg {
          width: 18px;
          height: 18px;

          &.zh-icon-task-list-approve {
            top: 3px;
          }

          &.zh-icon-alert-settings {
            top: 5px;
          }
        }
      }

      .ant-menu-sub {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
