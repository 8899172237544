@import '../helpers';

.informer-avatar {
  background: #f5f7fa;
  position: relative;

  .informer-avatar-alt {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #416a8d;
    height: auto;
    width: auto;
    display: block;
    font-size: 18px;
  }

  .ant-avatar {
    background: transparent !important;
    color: #416a8d;
  }

  .ant-avatar-string {
    transform: translate(-50%) !important;
  }
}
