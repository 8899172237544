@import '../helpers';

// monitoring component
.container.monitoring-container {
  max-width: 100%;
  display: flex;
  padding: 0;
  padding-top: 50px;
  padding-bottom: 0;
  min-height: auto;

  .monitoring-area {
    width: 100%;

    .table-above {
      margin: 20px 0px;
    }
  }

  @media screen and (max-width: 930px) {
    .table-above {
      .pull-right {
        width: 100%;
        padding: 10px 0;
      }
    }
  }
}

.add-monitoring-modal {
  .ant-modal-body {
    padding: 0;

    .blue {
      background-color: #047cc0;
    }
    .pink {
      background-color: #ab47bb;
    }
    .violet {
      background-color: #5c6bbf;
    }
    .red {
      background-color: #dc3c2f;
    }
    .aqua {
      background-color: #27c6da;
    }
    .burgundy {
      background-color: #800020;
    }
    .green {
      background-color: #5fb761;
    }
    .light-green {
      background-color: #9dcc65;
    }
    .orange {
      background-color: #ffa81f;
    }
    .white {
      background-color: #f2f2f2;
    }
    .yellow {
      background-color: #ffef58;
    }
    .grey {
      background-color: #798f9c;
    }
    .braun {
      background-color: #8d6e63;
    }
    .home-filter-circle {
      svg {
        fill: #fff;
      }
    }
  }
}

.monitoring-container {
  @media screen and (max-width: 930px) {
    display: block !important;

    .monitoring-all {
      margin: 0 auto;
      padding: 0 10px;
      width: 100%;

      .info-wrapper {
        .info-content {
          .name {
            margin-top: 10px;
          }

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
