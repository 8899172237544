.zh-icon {
  fill: rgb(4, 124, 192);
  &.zh-icon-size {
    &-small {
      font-size: 14px;
    }

    &-big {
      font-size: 45px;
    }
  }

  &.disabled {
    cursor: not-allowed;

    path {
      fill: #ccc;
    }
  }
}

.story-icons {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 40px;
  grid-template-rows: 1fr;
  > div {
    padding: 20px;
    text-align: center;
    transition: 0.3s all;
    &:hover {
      color: #fff;
      background-color: #1890ff;
      border-radius: 4px;
      > div {
        transform: scale(1.2);
        transition: 0.3s all;
      }
      svg {
        fill: #fff;
      }
    }
    > div {
      margin-bottom: 10px;
      .zh-icon {
        font-size: 36px;
      }
    }
  }
}

.zh-icon-loading {
  animation: rotate 1.2s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
