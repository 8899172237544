@import './../../helpers';

.data-rows {
  display: flex;
  width: 100%;

  @include mq($until: desktop) {
    display: block;
  }

  &.second {
    margin-top: 4px;
  }

  > div {
    position: relative;
    width: 100%;
    padding: 17px 45px;
    background: #f5f8fb;

    @include mq($until: desktop) {
      width: 100%;
      padding: 15px;
      display: block;
      border: none;
      margin-top: 4px;
    }

    small {
      display: block;
      color: #152935;
      font: 14px 'opensans-regular';
      margin-bottom: 15px;
    }

    span {
      display: block;
      font: 22px 'opensans-semibold';

      &:not(.grey) {
        color: #152935;
      }

      &.data-rows-small,
      &.data-rows-small > span {
        font: 14px 'opensans-semibold';
      }
    }

    &:not(:last-child) {
      border-right: 4px solid #fff;
      @include mq($until: desktop) {
        border-right: none;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background: #7a9cc8;
      width: 0;
      transition: width 0.3s ease;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}
