.person-card {
  display: flex;

  .avatar-alt,
  img {
    margin: 0;
    height: 48px;
    width: 48px;
    border-radius: 2px;
    margin-right: 13px;
    object-fit: contain;
    overflow: hidden;

    > span {
      font-size: 12px;
    }
  }

  .person-card-data {
    padding: 2.5px 0px;

    .person-card-name {
      font: 16px 'opensans-semibold';
    }

    .person-card-job {
      font: 13px 'opensans-regular';
      color: #244b63;
    }
  }
}
