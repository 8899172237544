@import './helpers';

.alerts {
  .table-above {
    border-bottom: 1px solid #f7fafc;
    margin: 0;
    padding: 0 0 15px;

    .pull-left,
    .pull-right {
      @include mq($until: small-desktop) {
        float: none;
        width: 100%;
        text-align: center;
      }
    }
    .ant-calendar-picker {
      @include mq($until: small-desktop) {
        width: 100%;
      }
    }
  }

  .pagination {
    padding-top: 15px;
  }

  .alert-header {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #f7fafc;

    @include mq($until: desktop) {
      padding: 0 0 15px 0;
    }

    .tabs-wrapper {
      display: inline-block;
      width: calc(100% - 60px);

      @include mq($until: desktop) {
        width: calc(100% - 45px);
      }

      .tabs {
        margin: 0;
        padding: 0;
        border-bottom: 0;
        li {
          @include mq($until: desktop) {
            margin-right: 10px;
            margin: 15px 10px 10px 0;
          }
          a {
            padding-bottom: 16px;

            @include mq($until: desktop) {
              padding-bottom: 8px;
            }
          }
        }
        li:not(:last-child):after {
          @include mq($until: desktop) {
            margin-left: 10px;
          }
        }
      }
    }

    .check {
      width: 12px;
      height: 8px;
      margin-right: 20px;
      margin-left: 24px;
      display: inline-block;

      @include mq($until: desktop) {
        margin-left: 11px;
        margin-top: 13px;
      }
    }
  }

  .alert-card {
    padding: 15px 25px;
    padding-left: 45px;
    background-color: #ffffff;
    position: relative;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: #f7fafc;
    }

    &:hover {
      background: #edf4f9;
    }

    @include mq($until: desktop) {
      padding-left: 40px;
    }

    &:last-child .alert-separator {
      display: none;
    }

    &.checked {
      background-color: #fafaf5;
    }

    > .alert-suitcase {
      position: absolute;
      left: 15px;
      top: 15px;

      path {
        fill: #047cc0;
      }
    }

    .ant-checkbox-wrapper {
      @include mq($until: desktop) {
        position: absolute;
        top: 15px;
        left: 10px;
      }
    }

    .check {
      width: 12px;
      height: 8px;
      margin-right: 20px;
    }
  }

  .list-details-selected {
    svg {
      overflow: visible;
    }

    @include mq($until: small-tablet) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background: #fff;
    }

    .actions {
      button {
        @include mq($until: small-tablet) {
          text-indent: -999px;
          overflow: hidden;
          width: 0;
          padding-left: 8px;
        }
      }
    }
  }
}

.alert-content {
  .title {
    display: block;
    color: #047cc0;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .sub-title {
    color: #244b63;

    svg {
      margin-right: 10px;
    }
  }

  .alert-separator {
    position: absolute;
    width: 95%;
    bottom: 0;
    margin: 0;
    border: none;
    height: 1px;
    background-color: #eaf0f6;
  }

  .alert-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin: 7.5px 0 10px;
  }

  .meta {
    display: flex;
    margin-top: 5px;

    @include mq($until: desktop) {
      display: block;
    }

    > .item {
      border-right: 1px solid #ced9e4;
      padding-right: 10px;
      margin-right: 10px;
      color: #244b63;

      @include mq($until: desktop) {
        padding: 5px 0;
        border-right: none;
      }

      &:last-child {
        border-right: none;
      }

      svg {
        font-size: 14px;
        margin-right: 10px;
        fill: #899cad;
        vertical-align: middle;
      }
    }
  }
}

.alerts-drawer {
  padding: 20px;

  > * {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .pagination {
    margin-top: 20px;
    text-align: right;
  }

  .alerts-drawer-type {
    color: #244b63;
  }

  .alerts-drawer-close {
    margin-bottom: 30px;

    svg {
      cursor: pointer;
      fill: #6f747e;
      font-size: 16px;

      &:hover {
        fill: #2083be;
      }
    }
  }

  .alerts-drawer-header {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaf0f6;

    .avatar {
      width: 72px;
      height: 72px;
      border-radius: 0;
      border: 1px solid #f2f3f7;
      background-color: #395c79;

      span {
        font-size: 25px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
      }
    }

    &:hover .alert-content .title span {
      color: #047cc0;
      cursor: pointer;
    }

    .alert-content {
      margin-left: 15px;

      > * {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        margin-bottom: 0;
        display: inline-block;

        span {
          color: #20323f;
          font-size: 22px;
          font-family: 'opensans-bold';
        }
      }

      .alert-description {
        display: flex;

        > *:nth-child(odd) {
          padding-right: 15px;
        }

        > *:nth-child(even) {
          padding-left: 15px;
        }

        > * {
          border-right: 1px solid #eaf0f6;

          &:last-child {
            border-right: none;
          }
        }

        .country {
          span {
            color: #45535e;
            font-size: 14px;
            font-weight: 400;
          }

          img {
            vertical-align: -5px;
            margin-right: 5px;
          }
        }

        .type span {
          color: #20323f;
          font-size: 16px;
          font-family: 'opensans-bold';
        }
      }
    }
  }

  .alerts-drawer-actions {
    display: flex;
    align-items: center;
    margin: 15px 0;

    button:first-of-type {
      margin-right: 15px;
    }

    .alert-category {
      display: flex;
      .icon {
        vertical-align: unset;
      }

      .text {
        margin-left: 10px;
      }
    }

    .alerts-buttons-wrapper {
      margin-left: auto;

      .zh-btn {
        min-width: 124px;
        height: 42px;
        padding: 8.5px 12px;

        &.zh-btn-disabled {
          color: #ccc;
          border-color: #ccc;
        }
      }
    }
  }
}
