.public-footer {
  width: 100%;

  @include mq($until: extra-wide) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__content {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include mq($until: tablet) {
      display: block;
    }

    .leftside {
      @include fontPoppins;
      max-width: 1280px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include mq($until: tablet) {
        text-align: center;
      }

      span:nth-child(1) {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      span:nth-child(2) {
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        font-weight: 500;
        color: #5e6061;
      }

      span:nth-child(3) {
        color: #152935;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .rightside {
      @include fontPoppins;

      @include mq($until: tablet) {
        text-align: center;
        margin-top: 10px;
      }

      span:nth-child(1) {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      span:nth-child(2) {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: #5e6061;
      }
    }
  }
}

.position-bottom {
  position: absolute;
  bottom: 25px;
}
