@import '../helpers';

.container.saved-list-container {
  padding: 0px 20px;
  margin-top: 130px;
  position: relative;
  border-top: 1px solid #e2eefa;
}

.segment {
  margin-top: 20px;

  .pull-right {
    input {
      z-index: 3;
    }

    .ant-btn {
      border: none !important;
      height: 30px !important;
      margin-right: 1px !important;
    }
  }
}

.saved-list-wrapper {
  .saved-list-wrapper-item {
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;

    .saved-list-wrapper-name {
      width: 38px;
      background: #f5f7fa;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }

    .upper_title {
      span:first-child {
        font-weight: 600;
      }

      .iconed-text {
        span {
          font-weight: 400;
        }
      }
    }
  }

  .saved-list-wrapper-item:hover {
    background: #eff6f8;
  }

  .saved-list-wrapper-item:last-child {
    border-bottom: 1px #1890ff solid;
  }

  .in-two-rows {
    text-align: left;
    padding-left: 0px !important;
    display: flex;
  }

  .settings {
    text-align: right;
    margin-left: 25px;
    padding-left: 120px;
    padding-top: 12px;
    svg {
      margin-right: 5px;
    }
  }

  .wrap-popover {
    padding-top: 12px;
    text-align: right;
    margin-right: 20px;
  }
}
