@import './helpers/colors';
@import './helpers/media-queries';

@keyframes fade-in-pulse {
  0% {
    opacity: 0;
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(30, 123, 193, 0.7);
  }

  25% {
    opacity: 1;
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(226, 238, 250, 0.7);
  }

  75% {
    box-shadow: 0 0 0 12px rgba(226, 238, 250, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(226, 238, 250, 0);
  }
}

@keyframes arrow-move {
  0% {
    top: 75%;
  }

  100% {
    top: 100%;
  }
}

@keyframes click-primary {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 123, 193, 0.7);
  }
  70% {
    box-shadow: 0 0 0 4px rgba(30, 123, 193, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(30, 123, 193, 0);
  }
}

@keyframes click-secondary {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 123, 193, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(30, 123, 193, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(30, 123, 193, 0);
  }
}

@keyframes fade-in-pulse-08 {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  50% {
    opacity: 1;
  }

  100%,
  70% {
    transform: translateY(0);
  }
}

@keyframes fade-right {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes filter-general {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes filter-close {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fade-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

%fade {
  animation: fade-in 0.5s forwards;
}

%scroll {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #395c79;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

%btn-aqua {
  button {
    border-radius: 4px;
    border-color: #ced9e4;
    font: 13px 'opensans-semibold';
    color: #047cc0;

    &:hover {
      border-color: #1fa3ff;
      background: #1fa3ff;
      color: #fff;
    }
  }
}

%pulse-blue {
  animation: pulse-shadow 2s infinite;
}

.with-scroll::-webkit-scrollbar {
  width: 4px;
  background: #f1f1f1;
  border-radius: 10px;
}

.with-scroll::-webkit-scrollbar-thumb {
  background-color: #047cc0;
  border-radius: 10px;
}

.hidden-scroll::-webkit-scrollbar {
  width: 0;
}

// Mixin
@mixin width($width) {
  max-width: $width !important;
  width: 100% !important;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

@keyframes fade-right {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

%fade {
  animation: fade-in 0.5s forwards;
}

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

%margin-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clear-fix {
  @extend %clearfix;
}

@mixin skeleton {
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  animation: pulse 2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}

.skeleton,
.skeleton-auto {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);

  height: 100%;
  width: 100%;
  @include skeleton;
}

.skeleton-fluid {
  @include skeleton;
}

.skeleton-auto {
  height: auto !important;
}

.skeleton-text {
  color: transparent !important;
}
