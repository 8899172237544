.zh-tree {
  .tree-select-all-wrapper {
    padding: 5px;
    text-align: right;

    .tree-select-all {
      display: inline-block;
      white-space: nowrap;
      cursor: pointer;
      color: #063467;
      position: relative;

      &.selected {
        color: #1182c3;
        border-bottom-color: #1182c3;

        &:hover {
          color: #037cc0;
          border-bottom-color: #037cc0;
        }
      }

      &.selected-excluded {
        color: #565758;
        border-bottom-color: #565758;

        &:hover {
          color: #2c5169;
          border-bottom-color: #2c5169;
        }
      }

      &.unactive {
        color: #a3cfe9 !important;
        border-bottom-color: #a3cfe9 !important;
        cursor: pointer;
      }

      &:hover {
        color: #1784c7;
        border-bottom-color: #1784c7;
      }
    }
  }

  .zh-tree-step {
    position: relative;
    padding: 5px 0;
    border-top: 1px solid #eaf0f6;

    .zh-tree-expand {
      font-size: 16px;
      position: absolute;
      left: -15px;
      top: 9px;
      cursor: pointer;
      color: #063467;
      font-weight: 600;

      &.expanded {
        top: 7px;
        left: -24px;
        transform: rotate(90deg);
      }

      &:hover {
        color: #1784c7;
      }
    }

    .ant-checkbox-wrapper {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
  }
}

.zh-tree-scroll {
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #047cc0;
    border-radius: 10px;
  }

  .ant-checkbox-group-item {
    display: flex;
    margin: 5px 0;
  }
}
