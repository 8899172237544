@import '../helpers';

.card-list-item {
  position: relative;
  border: 1px solid #e0e5ed;
  padding: 25px 30px;
  border-radius: 4px;
  transition: 0.1s;
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:hover,
  &.active {
    border: 1px solid rgb(136, 179, 215);
    box-shadow: 0px 10px 27.44px 0.56px rgba(34, 45, 56, 0.12);
  }

  .card-list-item-row {
    display: block;
    width: 33.33%;

    @include mq($until: small-desktop) {
      width: 100%;
    }
  }

  .card-list-item-footer {
    display: block;
    width: 100%;
    border-top: 1px solid #e0e5ed;
    margin-top: 25px;
    padding-top: 25px;

    b {
      color: #152835;
    }
  }

  .card-list-item-spin {
    color: #467bbc;
    font-size: 16px;
  }

  .ant-checkbox-wrapper,
  .card-list-item-spin {
    position: absolute;
    right: 30px;
    top: 25px;
  }

  @include mq($until: wide) {
    padding: 25px 10px;
  }
}

.card-list-item-value {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 7.5px;
  }
  @include mq($until: tablet) {
    &:last-child {
      margin-bottom: 7.5px;
    }
  }

  .card-list-item-value-title {
    font-size: 14px;
    color: #9eadb9;
    margin-right: 5px;

    @include mq($until: small-desktop) {
      width: 50%;
      margin-bottom: 3px;
    }
    &:first-child {
      @include mq($until: small-desktop) {
        width: 30%;
      }
    }
  }

  .card-list-item-value-content {
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 700;
    color: #000;

    > div {
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    > span,
    > .contacts {
      display: block;
    }

    @include mq($until: small-desktop) {
      width: 70%;
      font-size: 13px;
    }
  }
}

.card-list {
  display: flex;

  .card-list-title {
    margin: 30px 0px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    color: #152935;

    svg {
      margin-right: 7.5px;
    }

    @include mq($until: wide) {
      padding-left: 15px;
      padding-right: 7.5px;
    }
  }

  .card-list-actions {
    width: auto;
    display: flex;
    margin: 21px 0px;

    @include mq($until: wide) {
      padding-left: 7.5px;
      padding-right: 15px;
    }
  }
}

.card-list-content {
  padding-bottom: 50px;

  .ant-pagination {
    text-align: right;
  }

  @include mq($until: extra-wide) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
