.zh-license-table {
  td {
    cursor: pointer;
  }

  tr {
    th {
      word-break: normal;
    }
  }

  .zh-license-active td {
    background: #fafaf5 !important;
  }

  .zh-license-unactive td {
    cursor: not-allowed;
    color: #ccc;
  }
}

.profile-approved {
  position: absolute;
  right: 0px;
  top: 0;
  padding: 3.5px 7.5px;
  border-radius: 4px;
  color: #fff;
  background: #ccc;
  text-transform: uppercase;
  font-size: 12px;

  &.approved {
    background: #20ba85;
  }

  &.not-approved {
    background: #c3302b;
  }
}

.user-guide {
  z-index: 2;

  & > button {
    background-color: #047cc0;
    width: 48px;
    height: 48px;
    position: fixed;
    right: 3%;
    bottom: 8%;
    border: none;
    border-radius: 100%;
    padding: 0;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);

    &:hover,
    &:focus,
    &.active {
      background-color: #2990c9;
    }

    svg {
      width: 22px;
      height: 22px;
      margin-top: 1px;
      left: 13px;
    }

    .zh-icon-guide-close {
      margin: 2px 0 0 2px;
    }

    &.ant-popover-open {
      background-color: #047cc0;
    }
  }
}

.guide-popover {
  border-radius: 7px;
  overflow: hidden;

  .ant-popover-title {
    color: #fff;
    font-size: 14px;
    background-color: #047cc0;
    padding: 19px 25px;

    svg {
      margin: 0 10px 0 0;
      width: 19px;
      height: 19px;
      vertical-align: middle;
    }
  }

  .ant-popover-arrow {
    display: none;
  }

  .guide-menu {
    border-right: none;

    .ant-menu-item {
      padding: 0 13px;

      a {
        color: #047cc0;
        font-size: 13px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
