@import '../helpers';

.unavailable-data-popover {
  .ant-popover-inner {
    border: 1px solid #e5edf9;
    border-radius: 7px;
    box-shadow: 0px 12px 27.3px 2.7px rgba(25, 39, 52, 0.17);
  }
}

.unavailable-data-popover-section {
  max-width: 275px;

  > span {
    color: #000;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 600;
    display: block;
  }

  > a > button {
    margin-top: 20px;
  }
}
