@import './helpers';

.container-width {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
}

// company-logo
.company-logo {
  tbody tr {
    td {
      &:first-child {
        position: relative;
        width: 98px;
        height: 98px;

        @include mq($until: small-desktop) {
          width: 50px;
          height: 50px;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 98px;
          height: 98px;
          display: block;
          border: 1px solid #f5f8fb;
          border-radius: 4px;

          @include mq($until: small-desktop) {
            width: 50px;
            height: 50px;
          }
        }

        div {
          position: absolute;
          left: 0;
          top: 0;
          width: 98px;
          height: 98px;
          display: block;
          background: #f5f8fb;
          border-radius: 4px;

          @include mq($until: small-desktop) {
            width: 50px;
            height: 50px;
          }

          > span {
            font: 25px 'opensans-bold';
            position: absolute;
            @extend %margin-absolute;
            color: #7a9cc8;
          }
        }

        .company-logo-loader {
          background: #f5f8fb;

          i {
            font: 25px 'opensans-bold';
            position: absolute;
            @extend %margin-absolute;
            z-index: 2;
            color: #7a9cc8;
          }
        }
      }

      &:last-child {
        padding-left: 20px;
        h1 {
          font: 22px 'opensans-semibold';
          margin: 0 0 5px;

          @include mq($until: small-desktop) {
            font-size: 0.875rem;
          }

          span {
            color: #fff;
            text-transform: uppercase;
            border-radius: 3px;
            padding: 3px 7px;
            margin: 0 5px;
            vertical-align: 4px;
            font: 10px 'opensans-semibold';
            background: #20ba85;
            display: inline-block;

            @include mq($until: small-desktop) {
              vertical-align: 1px;
            }

            @include mq($until: small-desktop) {
              font-size: 0.5rem;
            }

            &.inactive {
              background: #f86b45 !important;
            }
          }
        }

        p {
          margin: 0;
          color: #244b63;
        }
      }
    }
  }
}

.logo-text-small {
  font: 18px 'opensans-semibold' !important;

  span {
    font: 10px 'opensans-bold' !important;
    padding: 2.5px 6px !important;
  }
}

.company-logo-wrap {
  &.pull-left {
    @include mq($until: small-desktop) {
      float: none !important;
    }
  }
}

.company-logo-right {
  padding: 30px 0;

  @include mq($until: small-desktop) {
    padding: 10px 0;
  }

  button {
    &.zh-btn-secondary {
      height: 38px;
      background-color: #047cc0;
      color: #fff;
      min-width: 140px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 10px;
      line-height: 12px;
      border: none;

      svg {
        top: 50%;
        margin-left: 5px;

        path {
          fill: #fff;
        }
      }

      &:hover {
        background-color: #78b8dc;
      }

      @include mq($until: small-desktop) {
        margin-left: 0;
      }
    }
  }
}

// company info
.company-info {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .zh-icon {
    font-size: 15px;
    fill: rgb(52, 111, 147);
  }
  .zh-icon-company-mail {
    vertical-align: -2px;
  }

  .zh-icon-company-website {
    font-size: 14px;
  }

  .company-info-label {
    display: block;
    font: 12px 'opensans-semibold';
    margin: 0 0 10px;
    text-transform: uppercase;
    color: #244b63;
  }

  > a.desc {
    margin-top: 10px;
    margin-left: 20px;
    display: inline-block;
  }

  p {
    font: 14px 'opensans-regular';
    margin: 0 0 5px;
    color: #152935;
    word-break: break-word;

    svg {
      width: 15px;
      margin-right: 5px;
      vertical-align: -2px;
      padding-right: 14px;
    }

    &.blue {
      color: #047cc0;
    }
  }

  .company-info-more {
    color: #737576;
    margin-top: 15px;
    display: block;
    cursor: pointer;

    svg {
      fill: #737576;
      margin-left: 5px;
      vertical-align: -5px;
      width: auto;
    }

    &:hover {
      color: #3cafff;

      svg {
        fill: #3cafff;
      }
    }
  }
}

.company-info-halves {
  display: flex;

  @include mq($until: small-desktop) {
    display: block;
  }

  .company-info {
    width: 100%;
    min-width: 0px;

    @include mq($until: small-desktop) {
      margin-bottom: 20px;
    }
  }

  &:last-child {
    .company-info {
      margin-bottom: 0px;

      @include mq($until: small-desktop) {
        margin-bottom: 20px;
      }
    }
  }
}

.ant-table-wrapper.ui.table {
  border: none;

  .ant-table-thead {
    .text-blue {
      span {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  .ant-table .ant-table-body {
    border-bottom: none;
  }
}

// company row
.company-segment-row {
  width: 50%;
  padding: 17px 28px;

  @include mq($until: small-desktop) {
    width: 100%;
  }
  &:not(:last-child) {
    @include mq($until: small-desktop) {
      border-bottom: 1px solid #a3adb3;
    }
  }

  small {
    font: 12px 'opensans-semibold';
    color: #416278;
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
  }

  p {
    font: 18px 'opensans-semibold';
    color: #152935;
    margin: 0;
  }

  &:nth-child(odd) {
    border-right: 1px solid #e2eefa;
  }

  &:not(:nth-child(-n + 2)) {
    border-top: 1px solid #e2eefa;
  }

  .ant-progress-bg {
    height: 14px !important;
    transition: width 2s ease-in-out;
    background-color: #1fa3ff;
  }

  &.row-noborder {
    border: none !important;
  }

  .ant-progress {
    .ant-progress-outer {
      float: right;
      margin-right: 0;
      width: calc(100% - 65px);
    }

    .ant-progress-text {
      float: left;
      margin-left: 0;
      margin-right: 15px;
      text-align: right;
      color: #152935;
      font-family: 'opensans-semibold';
      width: 50px;
      margin-top: 6px;
    }

    .ant-progress-inner,
    .ant-progress-bg {
      border-radius: 0 !important;
    }
  }
}

.company-founding .company-segment-row {
  text-align: center;

  .ant-progress-text {
    .anticon {
      display: none;
    }
  }

  .progress-social {
    display: flex;
  }
}

.ant-table {
  .ant-table-content {
    .ant-table-body {
      table {
        td {
          max-width: 380px;
          border-bottom: none;

          &.date-column {
            width: 100px;
          }

          &.type-column {
            text-transform: capitalize;
            width: 170px;
          }

          svg {
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }
}

.company-tabs {
  padding-top: 14px;

  .ant-tabs-tab {
    padding: 0;
    margin: 0 37px 0 0;

    li.disabled {
      cursor: not-allowed !important;
      color: #ccc !important;
      padding-bottom: 15px;
    }

    .company-tab-disabled {
      padding-bottom: 15px;
    }

    a {
      display: block;
      position: relative;
      color: #5a6872;
      border-bottom: 1px solid rgba(4, 123, 192, 0);
      padding-bottom: 15px;
      font-family: opensans-semibold;
      transition: 0.3s ease;
    }
  }
  .ant-tabs-ink-bar {
    background-color: #047cc0;
  }
  .active {
    a {
      color: #152935;
    }
  }
  .ant-tabs-tab-prev-icon,
  .ant-tabs-tab-next-icon {
    top: 31%;
  }
}

.company-header {
  &.sticky-header {
    > .container-width {
      @include mq($until: small-desktop) {
        padding: 5px 22.5px;
      }

      > div {
        @include mq($until: small-desktop) {
          padding: 0;
        }

        button {
          @include mq($until: small-desktop) {
            height: 100%;
          }
        }
      }
    }
  }

  > .container-width {
    display: flex;

    > div {
      @include mq($until: small-desktop) {
        padding: 0;
      }

      &:first-child {
        width: 100%;
      }

      &:last-child {
        width: auto;
      }
    }
  }
}

.sticky-header {
  background-color: #fff;
  box-shadow: 0 7px 27px 3px rgba(31, 105, 179, 0.05);
  border-bottom: 1px solid #eaf0f6;
  left: 0;
  top: -100%;
  width: 100%;
  transition: 0.3s;
  position: fixed;
  z-index: 1;

  &.scroll-up {
    top: 50px;
  }

  h1 {
    font: 14px 'opensans-semibold' !important;

    span {
      font: 8px 'opensans-semibold' !important;
      padding: 2px 5px !important;
    }
  }

  .pull-left {
    padding: 5px 0;
  }

  .company-logo {
    min-width: revert;

    tbody tr td:first-child {
      width: 45px;
      height: 45px;

      div {
        width: 45px;
        height: 45px;
        left: revert;
        right: 0;
      }

      img {
        width: 45px;
        height: 45px;
      }
    }
  }

  .company-logo-right {
    padding: 8px 0;
  }
}

.add-company-modal {
  .ant-modal {
    @include mq($until: tablet) {
      margin: 10px;
    }
  }
}

// company-shelude
.company-shelude {
  tbody tr {
    @include mq($until: small-desktop) {
      border-bottom: none !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      box-shadow: none !important;
    }

    td {
      padding: 14px !important;
      font: 14px 'opensans-semibold';
      color: rgba(0, 0, 0, 0.87);
      border-bottom: none;

      &:first-child {
        background: #f5f8fb !important;
        border-right: 1px solid #e2eefa;
      }
    }

    &:not(:first-child) td {
      border-top: 1px dashed #cdd2d6 !important;
    }

    &.today td {
      background: #dee1e5 !important;

      &:first-child {
        border-right-color: #cfd0d2;
      }

      &:last-child {
        background: #f5f8fb !important;
      }
    }
  }
  .ant-table-body {
    border-bottom: none;
  }
}

.segment-border-top {
  border-top: 1px solid #e2eefa !important;
}

.segment-border-bottom {
  border-bottom: 1px solid #1fa3ff;
}

// company segment with map
.company-with-map {
  .company-segment {
    width: 50%;

    .dashboard-manager-footer {
      margin: 20px -20px -20px;
    }

    @include mq($until: small-desktop) {
      width: 100%;
    }

    .title {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:first-child {
      margin-right: 0;
      border-right: 0;

      @include mq($until: small-desktop) {
        border: 1px solid #e2eefa;
      }
    }

    &:last-child {
      margin-left: 0;
      border-left: 0;

      @include mq($until: small-desktop) {
        border: 1px solid #e2eefa;
      }

      .content {
        padding: 0;
      }
    }
  }
}

.ant-breadcrumb {
  margin-top: 50px;
  background-color: #fff;
  padding: 15px;
  border-bottom: 1px solid #eaf0f6;

  .ant-breadcrumb-link {
    color: #152935;
    font-size: 15px;
    font-family: 'opensans-regular';

    a {
      color: inherit;

      &:hover {
        color: #1fa3ff;
      }
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 20px;
    text-align: center;
    position: relative;
    top: 3px;
    left: 2px;
  }
}
