@mixin skeleton {
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  animation: pulse 2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}

.skeleton,
.skeleton-auto {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);

  height: 100%;
  width: 100%;
  @include skeleton;
}

.skeleton-fluid {
  @include skeleton;
}

.skeleton-auto {
  height: auto !important;
}

.skeleton-text {
  color: transparent !important;

  > * {
    opacity: 0;  
  }
}

.skeleton-list-loading {
  > div {
    width: calc(100% - 40px);
    margin: 14px 20px 22.5px;
    border-radius: 4px;
    height: 15px;

    &:nth-child(2) {
      width: calc(90% - 40px);
    }

    &:nth-child(3) {
      width: calc(96% - 40px);
    }

    &:nth-child(4) {
      width: calc(87% - 40px);
    }

    &:nth-child(4) {
      width: calc(54% - 40px);
    }
  }
}

.skeleton-header {
  height: 50px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}

.skeleton-left-tools {
  position: fixed;
  height: 100%;
  width: 50px;
  border-right: 1px solid #e2eefa;
  background: #fff;
  z-index: 1;
  padding: 112.5px 0 0;

  > div {
    padding: 10.5px;
    height: 40px;
    position: relative;

    > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }
  }
}

.skeleton-layout {
  position: relative;
  max-width: 1360px;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 70px 60px 50px;
  border-left: 1px solid #e2eefa;
  border-right: 1px solid #e2eefa;
  background: #fff;
}

.skeleton-user-guide {
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #047cc0;
  height: 48px;
  width: 48px;
  z-index: 3;

  > div {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.skeleton-tabs {
  border-bottom: 1px solid #e8e8e8;
  height: 49px;
  display: flex;

  > div {
    width: 100%;
    max-width: 80px;
    height: 19px;
    margin: 15px 0 11px;
    margin-right: 32px;
  }
}

.skeleton-auth {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  height: 325px;
  text-align: left;
  border: 1px solid #e2eefa;
}
