.zh-user-cart {
  padding-left: 5px !important;
  clear: both;

  .small-avatar {
    width: 34px;
    height: 34px;
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    float: left;
    background: #e8f0f6;
    box-shadow: 0px 3px 5px 0.48px rgba(21, 41, 53, 0.1);
  }

  > div {
    float: left;
  }

  > .text {
    margin-left: 19px;
    margin-top: -4px;

    > span {
      margin-bottom: 5px;
      display: block;
      font: 16px 'opensans-semibold' !important;
      color: #152935 !important;
    }
  }

  small {
    display: block;
    font-size: 14px;
    color: #5a6872;
  }

  &.cursor {
    cursor: pointer;

    &:hover {
      > span {
        color: #047cc0 !important;
      }
    }
  }
}

.zh-user-cart-tag {
  font-size: 8px;
  text-transform: uppercase;
  color: #5d6a74;
  padding: 2.5px 5px;
  border-radius: 2px;
  display: inline-block;
  background: #ccc;
  border: 1px solid #ccc;
  margin-left: 7.5px;
  vertical-align: 2.5px;

  &.site-admin {
    color: #5d6a74;
    background: #e9f0f6;
    border: 1px solid #7c8791;
  }

  &.not-approved {
    color: #fff;
    background: #c3302b;
    border: 1px solid #c3302b;
  }
}
