@import '../helpers';

@include mq($until: tablet) {
  .ant-radio-wrapper {
    margin: 0 !important;
  }

  .ant-radio-group {
    display: flex !important;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    margin-top: 15px !important;
  }

  .billing-back {
    margin-bottom: 10px;
  }

  .form-half {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item {
    width: 100% !important;
  }

  .form-half .ant-form-item:nth-child(even) {
    margin-left: 0px !important;
  }

  .block-title span {
    font-size: 20px !important;
  }

  .info-block > .info {
    font-size: 14.5px !important;
  }

  .card-block--visa {
    margin: 0 6px;
  }

  .payment-block > .title {
    font-size: 16px !important;
  }
}
.card-block--visa {
  .zh-icon {
    font-size: 48px;
    fill: rgb(84, 121, 152);
  }

  .zh-icon-payment-icons-visa {
    height: 15px;
  }

  .zh-icon-payment-icons-master,
  .zh-icon-payment-icons-maestro {
    font-size: 60px;
    height: 36px;
  }
}
