@import '../../vars';

// ZH-BTN
.zh-btn {
  position: relative;
  cursor: pointer;
  font: 14px 'opensans-semibold';
  padding: 0 30px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  transition: 0.3s;
  height: 36px;

  &:focus {
    outline: 0 !important;
  }

  > svg,
  > .anticon {
    position: absolute;
    // top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  &.zh-btn-prefixed {
    padding-left: 35px;
  }

  &.zh-btn-disabled {
    background: #ccc !important;
    border-color: #ccc !important;
    color: #fff !important;
    cursor: not-allowed !important;

    svg path {
      fill: #fff;
    }
  }

  &.zh-btn-primary {
    border-color: #047cc0;
    background: #047cc0;
    color: #fff;

    &.zh-btn-aqua {
      color: #047cc0;
    }

    svg,
    i {
      color: #fff;
      fill: #fff;
    }

    &:hover {
      background: #78b8dc;
      border-color: #78b8dc;
      color: #fff;

      svg,
      i {
        color: #fff;
        fill: #fff;
        transition: 0s;
      }
    }

    &.zh-btn-clicked {
      animation: click-primary 1s infinite;
    }
  }

  &.zh-btn-reset {
    color: #363839;
    font-size: 14px;
    font-family: 'opensans-semibold';
    border: 1px solid #5e6061;
    background-color: #fff;
    height: 36px;

    &:hover {
      background-color: #5e6061;
      color: #fff;
    }
  }

  &.zh-btn-secondary {
    border-color: #5e6061;
    background: transparent;
    color: #434546;

    &.zh-btn-aqua {
      color: #047cc0;
    }

    svg,
    i {
      color: #434546;
      fill: #434546;
    }

    &:hover {
      background: #5e6061;
      color: #fff;

      svg,
      i {
        color: #fff;
        fill: #fff;
        transition: 0s;
      }
    }

    &.zh-btn-clicked {
      animation: click-secondary 1s infinite;
    }
  }

  &.zh-btn-warning {
    border-color: $yellow;
    background: #fff;
    color: #434546;

    svg,
    i {
      color: #434546;
      fill: #434546;
    }

    &:hover {
      background: transparent;
      color: #fff;

      svg,
      i {
        fill: $yellow;
        transition: 0s;
      }
    }

    &.zh-btn-clicked {
      animation: click-secondary 1s infinite;
    }
  }

  &.zh-btn-block {
    display: block;
    width: 100%;
  }

  &.zh-btn-long {
    padding-left: 45px;
    padding-right: 45px;
  }

  &:hover {
    > svg,
    > .anticon {
      fill: #fff;
      color: #fff;
      transition: 0s;
    }

    color: #fff;
  }
}

.zh-btn {
  &.zh-btn-smooth-primary {
    border-color: #e2eefa;
    background: #fff;
    color: #363839;
    border-radius: 4px;

    &.zh-btn-aqua {
      color: #047cc0;
    }

    svg,
    i {
      color: #2ca8ff;
      fill: #2ca8ff;
    }

    &:hover {
      background: #1fa3ff;
      border-color: #1fa3ff;
      color: #fff;
      box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.12);

      svg,
      i,
      svg path {
        color: #fff;
        fill: #fff;
        transition: 0s;
      }
    }

    &.zh-btn-clicked {
      animation: click-primary 1s infinite;
    }
  }

  &.zh-btn-smooth-secondary {
    border-color: #e2eefa;
    background: #fff;
    color: #363839;
    border-radius: 4px;

    &.zh-btn-aqua {
      color: #047cc0;
    }

    svg,
    i {
      color: #434546;
      fill: #434546;
    }

    &:hover {
      background: #5e6061;
      color: #fff;
      box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.12);

      svg,
      i {
        color: #fff;
        fill: #fff;
        transition: 0s;
      }
    }

    &.zh-btn-clicked {
      animation: click-secondary 1s infinite;
    }
  }

  &.zh-btn-smooth-warning {
    border-color: #e2eefa;
    background: transparent;
    color: #363839;
    border-radius: 4px;

    svg,
    i {
      color: #434546;
      fill: #434546;
    }

    &:hover {
      background: $yellow;
      color: #434546;
      box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.12);

      svg,
      i {
        color: #434546;
        fill: #434546;
        transition: 0s;
      }
    }

    &.zh-btn-clicked {
      animation: click-secondary 1s infinite;
    }
  }

  &.zh-btn-smooth-success {
    border-color: #e2eefa;
    background: transparent;
    color: #363839;
    border-radius: 4px;

    svg,
    i {
      color: #434546;
      fill: #434546;
    }

    &:hover {
      background: $green;
      color: #fff;
      box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.12);

      svg,
      i {
        color: #fff;
        fill: #fff;
        transition: 0s;
      }
    }

    &.zh-btn-clicked {
      animation: click-secondary 1s infinite;
    }
  }
}
