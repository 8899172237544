@import '../../helpers';

.saved-lists {
  display: flex;
  width: 100%;

  .ant-radio-group {
    margin-top: 10px;
    display: flex;
    width: 100%;

    @include mq($until: small-desktop) {
      display: block;
    }

    .ant-radio-wrapper {
      width: 50%;
      border: 1px dashed #e2eefa;
      padding: 2px 7px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq($until: small-desktop) {
        height: auto;
        width: 100%;
        padding: 15px;

        &:not(:first-child) {
          border-top: none;
        }

        > span {
          display: block;
          width: 100%;
        }
      }

      .ant-radio {
        opacity: 0;
        position: absolute;
      }

      &.ant-radio-wrapper-disabled {
        .saved-lists-item {
          .circle {
            background: #f8fafe !important;

            svg path {
              fill: #ccc;
            }
          }

          h2 {
            color: #ccc;
          }
        }
      }

      &:hover {
        background: #0475b5;
        border-color: #0475b5;

        .hint-icon path {
          fill: #fff;
        }

        h2,
        p {
          color: #fff;
        }

        .saved-lists-item {
          .circle {
            background: #0475b5;

            svg {
              fill: #fff;
            }
          }
        }
      }

      .saved-lists-item {
        text-align: center;

        @include mq($until: small-desktop) {
          display: flex;
          text-align: left;
        }

        .circle {
          position: relative;
          width: 60px;
          height: 60px;
          background: #f8fafe;
          border: 1px solid #f8fafe !important;
          border-radius: 50%;
          margin: 0 auto;

          @include mq($until: small-desktop) {
            margin: 0 15px 0 0;
          }

          svg {
            position: absolute;
            @extend %margin-absolute;
            fill: #5a6872;
            font-size: 25px;

            &.hint-icon {
              top: 1px;
              right: -1px;
              left: auto;
              transform: none;
            }
          }
        }

        h2 {
          font: 14px 'opensans-semibold';
          margin: 18px 0 4px;

          @include mq($until: small-desktop) {
            margin: 10px 0 5px;
          }
        }

        p {
          font: 12px 'opensans-regular';
          margin: 0;
        }
      }
    }

    .ant-radio-wrapper-checked {
      border: 1px solid #0475b5 !important;
      background: #0475b5 !important;

      .saved-lists-item {
        h2,
        p {
          color: #fff !important;
        }
        .hint-icon path {
          fill: #fff;
        }
        .circle {
          background: #0475b5 !important;
          border: 1px solid white !important;

          svg {
            fill: #fff !important;
          }
        }
      }
    }
  }
}
