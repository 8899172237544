@import '../helpers';

.avatar-manager-title {
  color: #afafaf;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.half-sections {
  .profile-avatar {
    width: 250px;
    max-width: 100%;
  }
}
.avatar-column {
  .title {
    margin-top: 25px;
    color: #20323f;
    font-size: 18px;
    display: block;
  }
  .advise {
    color: #047cc0;
    display: block;
    text-transform: uppercase;
    margin-top: 5px;
  }
}

.top-container {
  border-bottom: 1px solid #eaf0f6;
  .title,
  .sub-title {
    text-align: center;
    display: block;
    padding: 0 30px;
  }
  .title {
    margin-bottom: 15px;
    text-align: center;

    span {
      font-size: 26px;
      vertical-align: middle;
    }
    svg {
      margin-right: 10px;
      vertical-align: middle;
      font-size: 30px;
    }
  }
  .sub-title {
    text-align: center;
  }
}

.support-contacts {
  margin: 6px auto 11px auto;
  display: flex;
  vertical-align: bottom;
  padding: 0 33px;

  li {
    flex: auto;
    display: block;
    margin: 22.5px 0;

    &:not(:last-child) {
      margin-right: 22.5px;
    }

    span,
    a,
    .copy-link {
      vertical-align: middle;
    }

    a,
    .copy-link {
      color: #2083be;
    }

    .zh-icon {
      margin-right: 10px;
      vertical-align: middle;
      font-size: 20px;
      fill: rgb(84, 121, 152);
    }
  }
}

.support-wrapper {
  max-width: 600px;
  margin: 30px auto 0;
  .title {
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-size: 18px;
  }
}

.help-page {
  padding-bottom: 20px;
}

.support-sent {
  .header-wrapper {
    text-align: center;

    svg {
      margin-bottom: 25px;
    }

    .title {
      font: 18px 'opensans-semibold';
    }

    .text {
      font: 14px 'opensans-semibold';
      color: #6b6b6b;
    }
  }
}

.help-form {
  .wrap-columns {
    display: flex;
    float: left;
    width: 100%;

    @include mq($until: desktop) {
      display: block;
    }

    .column {
      display: block;
      float: left;
      width: 50%;
      border: 1px solid #e2eefa;

      & + .column {
        margin-left: 20px;

        @include mq($until: desktop) {
          margin: 20px 0 10px 0;
        }
      }

      @include mq($until: desktop) {
        width: 100%;
      }

      .sub-title {
        color: #152935;
        font-size: 16px;
        padding: 16px 17px;
        border-bottom: 1px solid #e2eefa;
      }

      .profile-avatar {
        width: 100%;
        text-align: left;
        padding: 25px 19px 10px 19px;

        .avatar-alt,
        img {
          height: 48px;
          width: 48px;
          margin: 0 15px 0 0;
          float: left;

          > span {
            font-size: 12px;
          }
        }

        .title {
          display: inline-block;
          margin: 2px 0 1px 0;
          font-size: 16px;
          color: #20323f;
        }

        .advise {
          display: block;
          margin: 0;
          color: #244b63;
          font-size: 13px;
          text-transform: capitalize;
        }
      }

      .info {
        width: 100%;
        float: left;
        padding: 0 19px;

        .item {
          float: left;
          width: 50%;
          color: #262626;
          font-size: 16px;
          font-family: 'opensans-semibold';
          padding: 10px 0;

          @include mq($until: extra-extra-wide) {
            width: 100%;
          }

          .sub-title {
            color: #152935;
            font-size: 14px;
            padding: 0;
            border: none;
            font-family: 'opensans-regular';
            margin-bottom: 2px;
          }
        }
      }

      .note {
        border-top: 1px solid #e2eefa;
        background-color: #faf9f5;
        color: #262626;
        font-size: 14px;
        padding: 15px 19px;
        float: left;
        width: 100%;
      }

      .support-form {
        .sub-title {
          border-bottom: none;
        }

        .ant-row {
          padding: 0 19px;

          label {
            color: #5a6872;
            font-size: 11px;
            margin-bottom: 7px;
            display: block;
          }
        }

        .form-footer {
          border-top: 1px solid #e2eefa;
          margin-top: 20px;
          padding-bottom: 15px;

          button {
            height: 36px;
            font-size: 14px;

            &.ant-btn-primary {
              background-color: #047cc0;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@include mq($until: tablet) {
  .half-sections {
    flex-direction: column;
    .ant-spin-nested-loading:first-child {
      width: 100%;
    }
    .ant-spin-nested-loading:last-child {
      width: 100%;
    }
  }

  .support-contacts {
    flex-direction: column;
    li:not(:last-child) {
      margin-right: 0;
    }
  }
}
