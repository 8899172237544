@import '../helpers';

.modal-add-counterparty {
  .ant-modal-body {
    padding-bottom: 0;
  }

  .search-field-group {
    margin-top: 0;

    .center-search {
      .search-box {
        border-left: 1px solid #ced9e4;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
      }
    }
  }

  .ant-modal-close {
    top: 0px;
    right: 5px;
    color: #5e6061;
  }

  .ant-modal-title {
    color: #262626;
    font-size: 16px;
    font-family: 'opensans-semibold';
  }

  .section-wrap {
    border-bottom: 1px solid #e2eefa;
    margin: 0 -25px;
    padding: 0 25px 10px 25px;
  }

  .ant-tag {
    color: #244b63;
    background-color: #f5f8fb;
    height: 28px;
    padding: 0 8px;
    border-radius: 3px;
    border: 1px solid #e2eefa;
    line-height: 28px;
    margin: 15px 7px 5px 0;
    font-family: 'opensans-semibold';

    .anticon-cross {
      font-size: 19px;
      position: relative;
      top: 3px;
    }
  }

  .filelist-wrap {
    padding-top: 15px;
    padding-bottom: 15px;

    .left {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 150px);
      color: #262626;
      font-size: 12px;

      .title {
        color: #262626;
        font-size: 16px;
        font-family: 'opensans-semibold';
        width: 100%;
        margin-bottom: 15px;
      }

      .button-wrap {
        margin-right: 18px;
        width: 150px;
        float: left;

        button {
          padding-left: 43px;
          border: 1px solid #047cc0;
          border-radius: 4px;
          background-color: #fff;
          line-height: 14px;

          &:hover {
            background-color: #047cc0;
            color: #fff;

            svg {
              path {
                fill: #fff;
              }
            }
          }

          svg {
            width: 19px;
            margin-right: 12px;

            &.zh-icon-list-plus-modal {
              top: 18px;
            }
          }
        }
      }

      .description {
        float: left;
        width: calc(100% - 170px);
      }
    }

    .right {
      display: inline-block;
      vertical-align: top;
      width: 150px;
      color: #363839;
      font-size: 14px;
      font-family: 'opensans-semibold';
      text-align: right;

      .example-button {
        cursor: pointer;
        color: #363839;
        font-size: 14px;
        position: relative;
        top: 5px;

        &:hover,
        &.active {
          color: #4071b0;

          svg {
            path.check {
              stroke: #fff;
            }
          }
        }

        svg {
          width: 15px;
          margin-right: 12px;
          position: relative;
          top: 2px;

          path {
            fill: transparent;
            stroke: #4071b0;
          }
        }
      }
    }

    .files-list {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          position: relative;

          .list-counterparty-wrap {
            width: 36px;
            height: 36px;
            position: relative;
            background-color: #f5f8fb;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;

            svg {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }

          .file-info {
            display: inline-block;
            vertical-align: middle;
            margin-left: 15px;

            .name {
              color: #152935;
              font-size: 16px;
              font-family: 'opensans-semibold';
            }

            .meta-info {
              color: #5a6872;
              font-size: 14px;
              font-family: 'opensans-regular';

              .dot {
                margin: 0 10px;
              }
            }
          }

          .remove-file {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            cursor: pointer;
            height: 16px;
            margin: auto;

            svg {
              width: 16px;
              height: 16px;
            }

            &:hover {
              svg {
                path {
                  fill: #5a6872;
                }
              }
            }
          }
        }
      }
    }
  }

  .apply-wrap {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;

    .left {
      width: 50%;
      float: left;

      .apply-button {
        border: 1px solid #047cc0;
        border-radius: 4px;
        width: 157px;
        text-align: center;
        padding: 0;

        &:hover {
          background-color: #047cc0;

          path {
            fill: #fff;
          }
        }

        svg {
          left: 10px;
          top: 27px;
          width: 30px;
          height: 30px;

          path {
            fill: #047cc0;
          }
        }

        &.zh-btn-disabled {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .right {
      width: 50%;
      float: right;
      text-align: right;
      font-size: 14px;
      color: #5a6872;
      line-height: 40px;
    }
  }

  .section-example {
    margin: 0 -25px;
    display: flex;
    background-color: #f5f8fb;
    align-items: flex-end;
    transition: all 0.2s ease-in-out;
    display: none;
    padding: 25px;

    &.active {
      display: flex;
    }

    .left {
      width: 50%;
      float: left;
      font-family: 'opensans-semibold';
      color: #262626;

      .title-1 {
        font-size: 20px;
      }
      .note-1 {
        color: #152935;
        font-size: 13px;
        margin-top: 10px;
      }
      .note-2 {
        color: #5e6061;
        font-size: 13px;
        font-family: 'opensans-regular';
      }
      .title-2 {
        font-size: 16px;
        margin-top: 15px;
      }
      .check-list {
        font-size: 14px;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          padding-left: 28px;
          position: relative;
          margin: 10px 0;

          svg {
            position: absolute;
            left: 0;
            top: 1px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .right {
      float: right;
      width: 50%;
      text-align: right;

      img {
        max-width: 100%;
      }
    }
  }
}
