@import '../../helpers';

.table-layout-fixed table {
  table-layout: fixed;
}

.wout-hover {
  .ant-table {
    .ant-table-footer {
      @extend %clearfix;
    }

    thead tr th {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600 !important;
    }

    tr:hover > td {
      background: #ffff !important;
    }
  }
}
.no-border {
  .ant-table .ant-table-body {
    border-bottom: 1px solid #e2eefa !important;
  }
}

.ant-table-wrapper {
  .ant-table-title {
    padding: 0;
  }

  &.single-line {
    white-space: nowrap;
  }
}

.ant-table {
  .ant-table-footer {
    @extend %clearfix;
  }

  thead tr th {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600 !important;
  }

  tr:hover > td {
    background: #fafafa !important;
  }

  .ant-table-scroll {
    table {
      width: 100%;
    }

    .ant-table-body {
      max-height: calc(100vh - 161px) !important;
    }
  }

  .ant-table-body {
    border-bottom: 1px solid #1fa3ff;

    & tr > .align.left {
      text-align: left;
    }
    & tr > .align.right {
      text-align: right;
    }

    .ant-table-thead {
      tr {
        th {
          border-bottom: 1px solid #1fa3ff;
        }
      }
    }

    .ant-table-tbody,
    .ant-table-thead {
      tr > th,
      tr > td {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        padding: 12px 10px;

        &.td-key,
        &.tr-key {
          padding-right: 0;
          font-size: 14px;
          font-family: opensans-semibold;
        }
      }

      & > tr > th {
        background: transparent;
      }
    }

    .ant-table-thead {
      & > tr > th {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 600 !important;
        padding: 12px 10px;
      }
      & > tr > th.center,
      & > tr > .align.center {
        text-align: center;
      }
    }
  }
}

.table-filter-search {
  tr td {
    .title-disabled {
      cursor: not-allowed;
      color: #063467;
      font-family: 'opensans-semibold';
    }

    a {
      color: #063467;

      &:hover {
        color: #1784c7;
      }

      &.title {
        font-family: 'opensans-semibold';
      }
    }
  }

  //   th:first-child,
  //   td:first-child {
  //     width: 40px;
  //   }

  th:nth-child(2),
  td:nth-child(2) {
    width: 300px;
  }
}

.table-clean {
  thead > tr > th {
    border-bottom: 2px solid #e2eefa !important;
    font-family: 'opensans-regular';
    color: #152935;
    padding: 12px 10px;
  }

  tbody tr td {
    svg {
      margin-right: 5px;
      vertical-align: 0px;
      fill: rgb(52, 111, 147);

      &.fix-svg {
        vertical-align: -4px;
      }
    }
  }

  &.table-header {
    thead > tr > th {
      background: #f5f8fb !important;
      padding: 15px !important;
      font-family: 'opensans-semibold' !important;
    }

    tbody > tr {
      > td {
        padding: 15px !important;
      }

      &:last-child td {
        border-bottom: 1px solid #1fa3ff !important;
      }
    }
  }

  &.table-no-header > tbody > tr {
    &:first-child td {
      border-top: 1px solid #eaf0f6 !important;
    }

    &:last-child td {
      border-bottom: 1px solid #1fa3ff !important;
    }
  }

  &.table-odd tbody tr {
    &:nth-child(even) {
      background: #fafbfd;
    }
  }

  &.table-saved-lists {
    margin-top: 0 !important;
    margin-bottom: 15px !important;

    .anticon-search {
      font-size: 18px;
      color: rgb(4, 124, 192);
      margin-right: 4px;
    }

    > tbody > tr {
      &:first-child td {
        border-top: 0 !important;
      }

      td {
        padding: 15px !important;

        &:nth-child(2) {
          padding-left: 0 !important;
        }
      }
    }
  }

  &.hover {
    tbody tr {
      &:not(.editing) {
        &:hover {
          background-color: #e9f2fb;
        }
      }
    }
  }
}

.ant-table-wrapper.table-blue {
  thead > tr > th {
    border-bottom: 0 !important;
    font-family: 'opensans-regular' !important;

    &:first-child {
      .ant-checkbox-wrapper {
        vertical-align: 3px !important;
      }
    }
  }

  tr td:first-child,
  tr th:first-child {
    padding-left: 15px !important;
    padding-right: 0 !important;
    text-align: center;
  }

  tbody > tr {
    &:first-child td {
      border-top: 1px solid #1fa3ff !important;
    }

    &:nth-child(even) {
      background: #fafbfd;
    }

    &:last-child td {
      border-bottom: 1px solid #1fa3ff !important;
    }

    &.selected {
      background-color: #fefef4;
    }
  }

  th.collapsing .ant-checkbox-wrapper {
    margin-bottom: 7.5px;
  }
}

.ant-table-wrapper.basic .ant-table-thead th {
  background: transparent;
  border-left: none;
}
.ant-table-wrapper.table-clean .ant-table-tbody tr {
  background: transparent;
}

.billing-body .billing-table-details + .table-grey {
  margin-top: 0 !important;
}

.table-grey {
  margin: 20px 0 !important;
  border-bottom: none;

  tr td {
    background: #f5f8fb !important;
    padding: 12.5px 45px !important;
    border-top: 2px solid #fff !important;
    font-family: 'opensans-semibold';

    &:first-child {
      border-right: 2px solid #fff !important;
    }
  }
  .ant-table-body {
    border-bottom: none;
  }
}
.table-grey.table-price-bottom {
  margin-top: 0 !important;
}

.ant-table td.collapsing,
.ant-table th.collapsing {
  width: 1px;
  white-space: nowrap;
}

.billing-table .ant-table-body {
  border-bottom: none;
}

.ant-table-wrapper[class*='very basic']:not(.sortable):not(.striped) td:first-child,
.ant-table-wrapper[class*='very basic']:not(.sortable):not(.striped) th:first-child {
  padding-left: 0;
}
.ant-table-wrapper[class*='very basic']:not(.sortable):not(.striped) td:last-child,
.ant-table-wrapper[class*='very basic']:not(.sortable):not(.striped) th:last-child {
  padding-right: 0;
}
.ant-table-wrapper td {
  padding: 0.78571429em;
}

.ant-table-wrapper tbody tr td svg {
  vertical-align: 0;

  &.zh-time {
    width: 39px;
  }
}
.fix-width {
  .zh-time {
    width: 18px !important;
  }
}

.zh-export-preview {
  .ant-table {
    overflow: visible;

    tr {
      td,
      th {
        font-size: 12px !important;
      }
    }
    .ant-table-body {
      border-bottom: none;
    }
  }
}

.basic {
  .ant-table-fixed-header {
    .ant-table-scroll {
      .ant-table-header {
        overflow: visible;
        padding-bottom: 20px !important;
        margin-bottom: -20px !important;
      }
    }
  }
}
