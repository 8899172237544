@import '../helpers';

.container.dashboard-container {
  border-left: none;
  border-right: none;
  padding: 60px 5px;

  @include mq($until: extra-wide) {
    padding: 60px 20px;
  }
}

.dashboard-header {
  font: 16px 'opensans-semibold';
  margin: 7.5px 0 25px;
  padding: 0;
}

.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  padding: 2px;

  > .dashboard-card {
    background: #f5f8fb;
    width: calc(50% - 6px);
    padding: 25px;
    margin: 3px;

    small {
      display: block;
      color: #152935;
      font: 14px 'opensans-regular';
      margin-bottom: 10px;
    }

    span {
      display: block;
      color: #152935;
      font: 22px 'opensans-semibold';

      &.small {
        font: 14px 'opensans-semibold';
      }
    }
  }
}

.dashboard-manager .person-card-data {
  margin-bottom: 17.5px;
}

.dashboard-manager-contacts {
  display: flex;
  flex-wrap: wrap;

  > .dashboard-manager-contact {
    width: 50%;

    > small,
    > span,
    > a {
      display: block;
    }

    > small {
      font: 14px 'opensans-regular';
    }

    > span,
    > a {
      font: 16px 'opensans-regular';
    }

    > span {
      color: #000;
    }

    .manager-email {
      color: #1890ff;
    }
  }
}

.dashboard-month-activity {
  // normalize into block
  margin: 0 -2px -2px -1px;
  width: calc(100% - 20px);

  .ant-table-body {
    border-bottom: none;
  }

  .ant-table-expanded-row {
    td {
      padding-top: 0;
      padding-bottom: 0;
    }

    .dashboard-month-activity-desc {
      display: flex;
      margin: 10px 0;

      > span {
        color: #807f7f;
        display: block;
        width: 100%;

        &:last-child {
          color: #262626;
          font: 14px 'opensans-semibold';
          width: auto;
          white-space: nowrap;
        }
      }
    }
  }

  .ant-table-tbody td {
    width: auto;
    word-break: normal;
  }

  .ant-table-tbody td:first-child {
    max-width: 50px;
    width: 50px;
  }

  .ant-table-tbody > tr > td:nth-child(2) {
    padding-left: 0;
    width: calc(100%);
  }

  .ant-table-tbody td:last-child,
  .ant-table-tbody > tr > td:nth-child(3) {
    max-width: 100px;
    width: 100px;
  }

  .ant-table-row-expand-icon {
    width: 25px;
    height: 25px;
    font-size: 18px;
    line-height: 25px;
    font-family: 'opensans-bold';
    line-height: 23px;
    border-color: #bcc3c8;
    border-radius: 3px;
  }

  .ant-table-row-expanded:after {
    content: '-';
    top: -2px;
    position: relative;
  }

  .ant-table-row {
    td:nth-child(2),
    td:nth-child(3) {
      border-bottom: 1px dashed #c8ced2 !important;
    }

    td:nth-child(3) {
      padding-right: 0 !important;
    }

    &:last-child {
      td:nth-child(2),
      td:nth-child(3) {
        border-bottom: none !important;
      }
    }
  }
}

.dashboard-month-activity-info {
  padding: 15px 12.5px;
  background: #f5f8fb;
  display: flex;

  > div {
    width: 100%;
    font: 14px 'opensans-regular';
    color: #152935;

    &:nth-child(2) {
      text-align: right;
    }
  }
}

.dashboard-bold {
  color: #262626;
}

.dashboard-manager-footer {
  margin: 0;
  padding: 20px;
  border-top: 1px solid #e2edfa;
  background: #fbf9f2;
  color: #000;
}

.dashboard-last-activities {
  .dashboard-last-activity {
    padding: 12.5px 0;
    // cursor: pointer;
    display: flex;
    position: relative;

    .icon-wrap {
      width: 50px;
      text-align: center;
      padding: 6px 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .right {
      width: calc(100% - 50px);

      .zh-icon-long-arrow {
        position: absolute;
        right: 20px;
        width: 24px;
        height: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    > span {
      cursor: pointer;
      color: #036;
      font: 16px 'opensans-semibold';

      &:hover {
        color: #047cc0;
      }
    }

    p {
      margin: 7.5px 0;
    }

    &:hover {
      background: #fafbfd;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e2edfa;
    }

    .dashboard-last-activity-tags {
      .dashboard-last-activity-type {
        color: #036;
        font: 14px 'opensans-semibold';

        &:hover {
          color: #047cc0;
        }
      }

      .iconed-text {
        display: inline-block;

        svg {
          margin-right: 5px;
          margin-top: 0;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

.support-header {
  display: flex;

  > span {
    display: block;

    &:first-child {
      width: 100%;
    }

    &:last-child {
      width: auto;
      white-space: nowrap;
      font: 13px 'opensans-regular';

      a {
        color: #5e6061;
        transition: 0.1s;

        &:hover {
          color: #000;
        }
      }

      i {
        margin-left: 5px;
      }
    }
  }
}
