.feedback-modal {
  .ant-modal-header {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
  }

  .ant-modal-footer {
    border-top: 1px solid #e8e8e8;

    button:first-child {
      float: left;
    }
  }

  .ant-modal-body {
    background: #f7fafc;
  }

  textarea {
    background-color: #fff;
  }

  .feedback-form-title {
    text-align: center;
    font: 18px 'opensans-semibold';
    margin-bottom: 12.5px;
  }

  .ant-rate {
    display: block;
    text-align: center;
    margin-bottom: 25px;

    li i {
      font-size: 26px;
    }

    .ant-rate-star:focus {
      outline: none;
    }
  }
}
