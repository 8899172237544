@import '../helpers';

@include mq($until: small-tablet) {
  .form-inline {
    flex-direction: column;
    > .ant-select {
      width: 100%;
    }
  }
  .table-above {
    display: flex;
    flex-direction: column;
  }
  .table-above .form-inline > *:not(:first-child) {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .info-content {
    font-size: 0.93em !important;
  }
  .info-wrapper > .label {
    font-size: 0.93em !important;
  }
}

@include mq($until: desktop) {
  .table-above .pull-right {
    float: none !important;
  }
  .form-inline {
    > .ant-input-search {
      max-width: 200px;
    }
    > .ant-input-select {
      max-width: 200px;
    }
    .form-inline-label {
      font-size: 15px;
    }
  }
}
