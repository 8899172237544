.tree-popup {
  .ant-design-draggable-modal-title {
    padding: 0;
  }

  .modal-footer-center {
    padding: 5px 0 20px;
  }

  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-modal-header {
    padding: 17px 25px;
    border-bottom: solid 1px #e4eefa;
  }

  .ant-modal-body {
    padding: 0;
    overflow: hidden;
    height: 100%;
    max-height: 100%;

    .fade-in {
      max-height: 100%;
      height: 100%;
    }
  }

  .ant-modal-footer {
    text-align: center;
  }

  .autocomplete {
    padding: 0;
    margin: 20px;
    width: calc(100% - 40px);
  }

  .selected-tags-box {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 0px 20px 10px;
    margin-top: -10px;

    .ant-tabs-tab .anticon {
      margin-right: 3px;
    }

    .selected-tags-list {
      display: flex;
      overflow: hidden;
      max-width: 100%;
      align-items: center;
      max-height: 63px;

      .zh-filter-tags {
        .ant-tabs-nav-container-scrolling {
          padding-left: 20px;
          padding-right: 25px;
        }

        .ant-tabs-tab-prev {
          width: 20px;

          > span {
            left: 2.5px;
          }
        }

        .ant-tabs-tab-next {
          width: 25px;

          > span {
            right: -2.5px;
          }
        }

        .ant-tabs-bar {
          margin: 0;
          border-bottom: 0;
        }

        .ant-tabs-ink-bar {
          display: none !important;
        }

        .ant-tabs-tab {
          margin: 0;
          padding: 0 0 2px;
        }

        .ant-tabs-tab,
        .ant-tabs-tab-active,
        .ant-tabs-tab-active:hover {
          font-weight: unset;
          color: unset;
        }
      }
    }

    .filter-tag {
      display: inline-block;
      border-radius: 4px;
      margin: 6px 12px 0 0px;
      padding: 3px 7.5px;
      position: relative;
      animation: fade-in-pulse 0.2s forwards cubic-bezier(0.8, 0.02, 0.45, 0.91);
      display: flex;
      align-items: center;

      .tag-name {
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        max-width: 190px;
        margin-right: 32px;

        strong {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.filter-tag {
  svg {
    fill: #41698c;
    font-family: 'opensans-bold';
    margin-left: 3px;
  }
}

.tree-popup-content {
  height: 100%;

  .zh-tree {
    height: 100%;

    .zh-tree-scroll {
      border-top: 1px solid #ebf1f6;
      padding: 5px 5px 20px 20px;
      max-height: calc(100% - 77.5px) !important;
    }
  }

  li {
    padding: 4px 0 !important;

    &:first-child {
      margin-top: 2px !important;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ebf1f6;
    }
  }

  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 60px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #416a8d;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
