@import '../helpers';

@include mq($until: tablet) {
  .packages-list {
    flex-direction: column;
  }
  .package-price {
    .package-price-price {
      font-size: 42px;
    }
    .package-price-currency {
      font-size: 18px;
    }
    .package-price-frequency {
      font-size: 21px;
    }
  }

  .zh-monthly-metric,
  .zh-daily-metric {
    font-size: 15px;
  }
  .zh-metrics .zh-metric-update {
    font-size: 13px;
  }

  .progress-bar-container .progress-indicator {
    font-size: 12px;
  }
}

@include mq($until: desktop) {
  .progress-bar-container .progress-indicator {
    font-size: 12px;
  }
}
