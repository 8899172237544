@import '../helpers';

.container.legal-bulletin-container {
  max-width: 100%;
  display: block;
  padding: 0;
  padding-top: 50px;
  padding-bottom: 0;
  min-height: auto;

  .filter-btn {
    margin: 15px 10px;
    background-color: #047cc0;
    font-size: 14px;
    color: #fff;
    font-family: 'opensans-semibold';
    border: none;
    width: 115px;
    padding: 0 20px 0 0;
    text-align: right;
    height: 38px;
    float: none;

    svg {
      path {
        stroke: #fff;
      }
    }
  }

  .navigation {
    padding: 8px 6px;
    height: 48px;
    width: 100%;

    .ant-radio-button-wrapper {
      color: #244b63;
      font-size: 14px;
      font-family: 'opensans-semibold';
      background-color: #eff2f5;
      height: 28px;
      text-align: center;
      min-width: 165px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 0;
      border: none;
      position: relative;
      transition: all 0.2s ease-in-out;

      &:not(:first-child)::before {
        display: none;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: #047cc0;
        color: #fff;
        height: 32px;
        top: -2px;
        border-radius: 4px;
      }
    }
  }

  .middle-container {
    display: flex;
    width: 100%;
    border-top: 1px solid #e2eefa;
  }
}

@media screen and (max-width: 645px) {
  .container.legal-bulletin-container {
    .middle-container {
      display: block;
    }
  }
}
