@import '../helpers';

.informer-status {
  font-family: 'Open Sans';
  font-weight: 600;
  padding: 2.5px 5px;
  border-radius: 2px;
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;

  &.informer-status-hold {
    background: #467bbc;
  }

  &.informer-status-active {
    background: #65c085;
  }

  &.informer-status-deleted {
    background: #da012f;
  }

  &.informer-status-removed {
    background: #deba2c;
  }

  @include mq($until: wide) {
    display: inline-block;
  }
}
