// add-company-list
.add-company-modal {
  .no-results-container {
    .no-results {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      background: #edf8fd;
      margin: 20px auto 20px;
      line-height: 100px;
      text-align: center;

      svg {
        width: 22px;
        height: 32px;
      }
    }

    h3 {
      text-align: center;
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .ant-alert,
  .ant-input-affix-wrapper {
    margin: 0 0 15px;
  }

  .ant-modal-body {
    padding: 20px 0;
  }

  .ant-modal-footer {
    padding: 20px 25px;

    p {
      color: #5a6872;
      font: 14px 'opensans-semibold';
      margin: 9px 0px;
      float: right;
    }
  }
  .radio-flex-content {
    flex-grow: 1;
  }
  .ant-modal-close {
    top: 0px;
    right: 5px;
    color: #616263;
  }
  section > input[type='text'] {
    color: #152935;
    margin-left: 25px;
    margin-right: 25px;
    max-width: calc(100% - 50px);

    &::placeholder {
      color: #152935;
      font: 14px 'opensans-italic';
    }

    &:-ms-input-placeholder {
      color: #152935;
      font: 14px 'opensans-italic';
    }

    &::-ms-input-placeholder {
      color: #152935;
      font: 14px 'opensans-italic';
    }
  }
}
