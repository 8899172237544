// export
.export-price {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #e4eefa;
  margin-top: 50px;

  > span {
    display: block;
  }

  .total {
    text-transform: uppercase;
    margin-bottom: 2.5px;
    font: 14px 'opensans-semibold';
  }

  .price {
    text-transform: uppercase;
    font: 16px 'opensans-semibold';
    color: #003366;
  }
}

.zh-modal-export {
  .ant-input {
    background: #fff;
    margin-bottom: 20px;
  }
  .ant-modal-footer {
    @include mq($until: tablet) {
      height: 160px;
    }
  }
}

.export-footer {
  text-align: center;

  button {
    @include mq($until: tablet) {
      margin-top: 10px;
    }
  }

  > div {
    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }

    @include mq($until: small-tablet) {
      float: none;
      width: 100%;
      margin-bottom: 10px;
    }

    button {
      @include mq($until: small-tablet) {
        width: 100%;
      }
    }
  }
}
.export-option {
  .ant-radio {
    display: none;
  }
}

.export-columns-wrapper,
.export-column-wrapper {
  display: flex;

  @include mq($until: small-desktop) {
    display: block;
  }

  .export-left-column {
    width: 58%;
    background: #f7fafc;

    @include mq($until: small-desktop) {
      width: 100%;
    }
  }

  .export-right-column {
    width: 42%;
    border-left: 1px solid #eaf0f6;
    background-color: #eff6f8;

    @include mq($until: small-desktop) {
      width: 100%;
    }

    .modal-footer-alert {
      border-radius: 4px;
      background: #f1ecde;
      border: 1px solid #ccc;
      margin: 0 10px;
      font-size: 12px;
      padding: 10px 12px;
    }

    .link {
      cursor: pointer;
      color: #009be9;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .title {
      padding: 19px 0;
      border-bottom: 1px solid #eaf0f6;
      display: block;
      text-align: center;
      font-family: 'opensans-bold';
      font-size: 16px;
      color: #20323f;
    }

    span {
      color: #395c79;
    }

    .sum-wrapper {
      padding: 1.25rem 0;
      flex-grow: 1;

      .ant-alert {
        margin-left: 10px;
        margin-right: 10px;
        padding: 8px 10px !important;
        display: block !important;

        span {
          width: 100% !important;
          margin: 0;
        }
      }

      .credit-badge {
        font-size: 11px;
        background: #00bb83;
        text-transform: uppercase;
        padding: 2.5px 5px;
        border-radius: 3px;
        margin-left: 2.5px;
        color: #fff;
      }

      .info-badge {
        font-size: 9px;
        background: #00bb83;
        text-transform: uppercase;
        border-radius: 100%;
        margin-left: 10px;
        color: #fff;
        display: inline-block;
        vertical-align: top;
        width: 13px;
        height: 13px;
        text-align: center;
        cursor: default;

        &.default {
          background: #108ee9;
        }
      }

      .sum-payment {
        margin: 25px 0 15px;
        border-top: 1px solid #cbd6e2;

        .wrap-alerts {
          display: block;
          margin: 10px 0 25px 0;
        }

        > div {
          padding-top: 5px;
          display: flex;

          &.price > span {
            font-size: 16px;
            color: #20323f;
          }

          &.price {
            &.price-note {
              margin-top: -5px;
              margin-bottom: -5px;

              & > span:first-child {
                color: #f81d22;
                font-size: 12px;
                margin-top: 3px;
              }
              & > span:last-child {
                font-size: 14px;
              }
            }
          }

          > span:first-child {
            text-align: left;
          }

          > span:last-child {
            text-align: right;
          }

          > span {
            padding: 2.5px 15px;
            display: block;
            width: 100%;
            font-size: 12px;
            color: #5a5a5a;

            &:first-child {
              width: 150%;
            }
          }
        }
      }

      .sum-title {
        text-align: center;
        display: block;
        text-transform: uppercase;
        font-family: 'opensans-bold';
        font-size: 0.8125rem;
        color: #395c79;
      }
    }

    .sum {
      text-align: center;
      display: block;
      margin-bottom: 1.25rem;
      font-family: 'opensans-bold';
      font-size: 1.875rem;
      color: #20323f;
      line-height: 1.1;
    }

    .credits-list {
      padding: 0 10px;

      li {
        list-style: none;
        position: relative;

        .clearfix {
          margin: 8px 0;
        }
        svg {
          top: 0;
        }
      }
    }
  }
}

.export-column-wrapper .export-right-column {
  width: 100%;
}

.export-title {
  font: 16px 'opensans-semibold';
  padding: 20px 25px;
  border-bottom: 1px solid #e4eefa;
}

.export-subtitle {
  font: 14px 'opensans-semibold';
  padding: 0 0 10px;
  color: #003366;
}

.export-fields {
  @extend %scroll;

  min-height: 163px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #e2eefa;
  padding: 10px 5px 0;
  margin-bottom: 20px;
}

.zh-export-preview {
  @extend %scroll;

  width: 100%;
  overflow-x: auto;
  border: 1px solid #e2eefa;

  .empty-block {
    height: auto;
    margin-top: 10px;
    .icon {
      margin: 0 auto 5px;
      position: relative;
      height: 90px;
      width: 90px;
    }
    h2 {
      font-size: 15px;
    }
  }

  .table {
    border: 0;
  }

  tr {
    font-size: 12px;

    th,
    tr:nth-child(2n) {
      background: #eaf0f6 !important;
    }

    th,
    td {
      white-space: nowrap;
      padding: 5px 15px !important;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}

.export-list {
  padding: 0;
  margin: 0 0 15px;

  li {
    list-style: none;

    &.grey {
      color: #ccc;
    }

    &:not(:last-child) {
      margin-bottom: 2.5px;
    }

    i {
      font-size: 16px;
      margin-right: 5px;

      &.anticon-check {
        color: #44a800;
      }

      &.anticon-close {
        color: #f00;
      }
    }
  }
}
