@import '../helpers';

.container.profile-container {
  display: flex;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 50px;
    position: absolute;
  }

  .ant-table-footer {
    background-color: #ffff !important;
  }

  .content {
    padding-top: 20px !important;
  }

  .sidebar {
    width: 220px;
    min-width: 220px;
    min-height: 80vh;
    border-right: 1px solid #e2eefa;
    position: relative;
    padding-top: 20px;

    @include mq($until: desktop) {
      display: none;
    }

    li {
      list-style-type: none;

      &.item-exit {
        position: absolute;
        left: 0;
        right: 0;

        a,
        .link {
          cursor: pointer;

          &:hover {
            .svg-container {
              svg {
                path {
                  fill: transparent;
                  stroke: #fff;
                }

                &.zh-icon-shipment-box {
                  path {
                    stroke: #fff;
                    fill: transparent;
                  }
                }
              }
            }
          }

          .svg-container {
            svg {
              path {
                fill: transparent;
                stroke: #244b63;
              }
            }
          }
        }
      }

      &.active {
        a,
        .link {
          background-color: #047cc0;
          color: #fff;

          .svg-container {
            svg {
              path {
                fill: #fff;
              }

              &.zh-icon-shipment-box {
                path {
                  stroke: #fff;
                  fill: transparent;
                }
              }

              &.zh-icon-help-menu {
                fill: transparent !important;
                stroke: #fff;

                path {
                  fill: transparent !important;
                  stroke: #fff;
                }
              }
            }
          }
        }
      }

      a,
      .link {
        padding: 8px 20px;
        color: #263745;
        font-size: 14px;
        display: block;
        transition: none;

        &:hover {
          background-color: #047cc0;
          color: #fff;

          .svg-container {
            svg {
              path {
                fill: #fff;
              }

              &.zh-icon-shipment-box {
                path {
                  stroke: #fff;
                  fill: transparent;
                }
              }

              &.zh-icon-help-menu {
                fill: transparent !important;
                stroke: #fff;

                path {
                  fill: transparent !important;
                  stroke: #fff;
                }
              }
            }
          }
        }

        .svg-container {
          display: inline-block;
          vertical-align: middle;
          margin-right: 17px;

          svg {
            width: 23px;
            height: 20px;
            position: relative;
            top: 1px;

            path {
              fill: #244b63;
            }

            &.zh-icon-user-section-team {
              width: 25px;
              left: 1px;
            }

            &.zh-icon-shipment-box {
              path {
                fill: transparent;
              }
            }

            &.zh-icon-help-menu {
              fill: transparent !important;
              stroke: #244b63;
              font-size: 13px;
              left: 1px;

              path {
                fill: transparent !important;
                stroke: #244b63;
              }
            }
          }
        }
      }
    }
  }

  .company-container {
    .company-name {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #1890ff;

      @include mq($until: desktop) {
        .zh-company-cart > .text {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
      @include mq($until: small-desktop) {
        display: block;
      }
      .company-name-left {
        padding: 12px 10px;
      }

      .company-name-right {
        padding: 12px 10px;
      }
    }

    .company-name:nth-child(1) {
      border-top: 1px solid #e8e8e8;
    }

    .company-name-items {
      @include mq($until: desktop) {
        .zh-user-cart > .text > span {
          margin-top: 10px;
        }
      }
      > div:last-child {
        border-bottom: 1px solid #1890ff;
      }
      .company-name-item {
        min-height: 68px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8;
        width: 100%;

        .company-name-item-left {
          padding: 12px 10px;
        }
        .company-name-item-right {
          padding: 12px 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .content {
    max-width: 1360px;
    width: calc(100% - 220px - 15%);
    margin: 0 auto;
    padding: 0 60px;
    border-left: 1px solid #e2eefa;
    border-right: 1px solid #e2eefa;

    @include mq($until: 1600px) {
      width: calc(100% - 5%);
      margin: 0 auto;
    }

    .close {
      svg {
        position: absolute;
        top: 6px;
        left: 6px;
      }
    }

    .title {
      color: #152935;
      font-size: 16px;
      font-family: 'opensans-semibold';
      margin: 10px 0 30px 0;
    }

    & > .half-sections {
      border-top: 1px solid #f5f8fb;
      height: auto;

      .ant-spin-nested-loading {
        &:last-child {
          margin-left: 20px;
          border-left: 1px solid #e2eefa;

          @include mq($until: desktop) {
            border-left: none;
            margin-left: 0;
          }
        }

        .ant-spin-container {
          .profile-avatar {
            width: 180px;

            .change-avatar {
              & > .close {
                &:hover {
                  background-color: #047cc0;

                  svg {
                    path {
                      fill: #fff;
                      stroke: #fff;
                    }
                  }
                }

                svg {
                  position: relative;
                  top: 5px;
                  left: 2px;

                  path {
                    fill: #5a6872;
                  }
                }
              }
            }

            .atn-file,
            .zh-btn-secondary {
              border-radius: 4px;
            }
          }

          .ant-form {
            .ant-form-item-label {
              label {
                color: #5a6872;
                font-size: 11px;
                font-family: 'opensans-semibold';
              }
            }

            .ant-select-selection--single {
              height: 36px;

              .ant-select-selection__rendered {
                line-height: 34px;
              }
            }

            .password-seaction {
              border-top: 1px solid #e2eefa;
              margin: 15px -20px;
              padding: 10px 20px;

              .title {
                margin-bottom: 10px;
              }
            }
          }
        }

        .form-footer {
          border-top: 1px solid #e2eefa;
          margin: 15px -20px 0 -20px;

          & > button {
            margin-right: 0;
          }
        }

        .edit-form-footer {
          border-top: 1px solid #e2eefa;
          margin: 15px -20px 0 -20px;
          display: flex;
          justify-content: space-between;

          .footer-leftside {
            > button {
              margin-left: 20px;
            }
            > button:nth-child(2) {
              margin-left: 10px;
              margin-top: 20px;
            }
          }

          .remove-selection {
            display: flex;
            margin-top: 30px;
            height: 20px;
            margin-right: 20px;
            color: #5a6872;
            font-size: 14px;
            font-family: 'opensans-semibold';
            float: right;
            cursor: pointer;

            svg {
              margin-right: 10px;
              height: 20px;
            }

            span {
              display: block;
              margin-top: -2px;
            }

            .zh-icon-trash {
              width: 16px;
              height: 16px;
              margin-right: 6px;
              vertical-align: middle;
              position: relative;
              top: 0px;
            }

            &:hover {
              color: #047cc0;
            }
          }

          & > button {
            margin-right: 0;
          }
        }
      }
    }
  }
  .edit-form-footer {
    border-top: 1px solid #e2eefa;
    margin: 15px -20px 0 -20px;
    display: flex;
    justify-content: space-between;

    .footer-leftside {
      > button {
        margin-left: 20px;
      }
      > button:nth-child(2) {
        margin-left: 10px;
        margin-top: 20px;
      }
    }

    .remove-selection {
      display: flex;
      margin-top: 30px;
      height: 20px;
      margin-right: 20px;
      color: #5a6872;
      font-size: 14px;
      font-family: 'opensans-semibold';
      float: right;
      cursor: pointer;

      svg {
        margin-right: 10px;
        height: 20px;
      }

      span {
        display: block;
        margin-top: -2px;
      }

      .zh-icon-trash {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        vertical-align: middle;
        position: relative;
        top: 0px;
      }

      &:hover {
        color: #047cc0;
      }
    }

    & > button {
      margin-right: 0;
    }
  }
  .packages-area {
    display: flex;
    width: 100%;
    padding-top: 60px;

    .column {
      width: 100%;
      min-width: 250px;

      @include mq($until: 1420px) {
        min-width: 200px;
      }

      & + .column {
        margin-left: 9px;
      }

      .heading {
        height: 138px;
        position: relative;

        .title {
          color: #152935;
          font-size: 32px;
          font-family: 'opensans-bold';
        }
      }

      &.heading-column {
        @include mq($until: 1190px) {
          display: none;
        }

        .heading {
          margin-bottom: -10px;
        }
      }

      &:not(.heading-column) {
        .heading {
          border: 1px solid #e2eefa;
          border-radius: 4px 4px 0 0;
          text-align: center;

          .sticker {
            height: 27px;
            padding: 0 10px;
            border-radius: 3px;
            position: relative;
            top: -16px;
            margin: 0 auto;
            background-color: #007efe;
            color: #fff;
            font-size: 13px;
            font-family: 'opensans-semibold';
            text-transform: uppercase;
            line-height: 26px;
            display: inline-block;

            &:before {
              content: '';
              display: block;
              height: 7px;
              width: 10px;
              position: absolute;
              left: -8px;
              top: 6px;
              border-left: 10px solid #003e7c;
              border-top: 6px solid #fff;
              transform: rotate(-90deg);
            }
            &:after {
              content: '';
              display: block;
              height: 7px;
              width: 10px;
              position: absolute;
              right: -8px;
              top: 6px;
              border-right: 10px solid #003e7c;
              border-top: 6px solid #fff;
              transform: rotate(90deg);
            }
          }

          .plan {
            color: #346f93;
            font-size: 11px;
            font-family: 'opensans-semibold';
            text-transform: uppercase;
            margin-top: -4px;
          }

          .title {
            font-size: 36px;
            margin: 1px 0;
          }

          .price {
            color: #152935;
            font-size: 17px;
            font-family: 'opensans-semibold';
          }
        }
      }

      .cell {
        height: 82.2px;
        color: #152935;
        font-size: 16px;
        font-family: 'opensans-semibold';
        background-color: #fff;

        .responsive-title {
          font-size: 12px;
          font-family: 'opensans-regular';
          margin: -25px 0 10px 0;
          color: #444545;
          display: none;

          @include mq($until: 1190px) {
            display: block;
          }
        }

        .tag {
          color: #fff;
          font-size: 11px;
          font-family: 'opensans-semibold';
          height: 17px;
          padding: 0 5px;
          border-radius: 3px;
          background-color: #2689fe;
          text-transform: uppercase;
        }

        &:not(.headinng-cell) {
          border: 1px solid #e2eefa;
          border-top: none;
          text-align: center;
          padding: 33px 10% 0 10%;

          &:nth-last-of-type(2) {
            border-radius: 0 0 4px 4px;
          }

          &:last-child {
            border: none;
            height: 120px;
          }

          .circle-icon {
            height: 36px;
            width: 36px;
            background-color: #ebfaef;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -5px;

            svg {
              position: relative;
              top: 9px;
              left: auto;
              bottom: auto;

              path {
                fill: #00ac28;
              }
            }

            &.red {
              background-color: #faebeb;

              svg {
                path {
                  fill: #eb1414;
                }
              }
            }
          }
        }

        &.headinng-cell {
          background-color: #f5f8fb;
          border-radius: 4px;
          height: 80px;
          margin: 2px 0;
          padding: 27px 5px 0 8%;

          @include mq($until: 1420px) {
            padding: 33px 5px 0 8%;
          }

          .circle-icon {
            text-align: center;
            display: inline-block;
            width: 36px;
            height: 36px;
            background-color: #fff;
            border-radius: 100%;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
            position: relative;
            vertical-align: middle;
            margin-right: 7%;
            top: -3px;

            @include mq($until: 1420px) {
              display: none;
            }

            svg {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;

              path {
                stroke: #2d5269;
              }

              &.zh-icon-search,
              &.zh-icon-man {
                path {
                  fill: #2d5269;
                  stroke: transparent;
                }
              }

              &.zh-icon-man {
                width: 17px;
                height: 17px;
              }
            }
          }

          .info-link {
            vertical-align: middle;
            margin-left: 6px;
          }
        }

        .ant-btn {
          height: 47px;
          width: 100%;
          background-color: #047cc0;
          border: 2px solid #047cc0;
          color: #fff;
          font-size: 16px;
          font-family: 'opensans-semibold';
          max-width: 240px;
          position: relative;
          padding-left: 40px;

          @include mq($until: 1300px) {
            padding: 0;
          }

          .circle-icon {
            width: 25px;
            height: 25px;
            font-size: 13px;
            line-height: 22px;
            position: absolute;
            top: 10px;
            left: 12px;

            @include mq($until: 1300px) {
              display: none;
            }
          }

          &.active {
            background-color: #fff;
            border-color: #edeff1;
            color: #152935;
          }

          &:not(.active) {
            &:hover {
              background-color: #2990c9;
              border-color: #2990c9;
            }

            .circle-icon {
              background-color: transparent;

              path {
                fill: #fff;
              }
            }
          }
        }
      }

      &:not(.column-primary) {
        position: relative;

        @include mq($until: 1155px) {
          min-width: 195px;
        }

        .heading {
          .price {
            font-family: 'opensans-bold';

            .num {
              font-size: 28px;
              margin-right: 6px;

              sup {
                position: relative;
                right: -6px;
                top: -10px;
                font-size: 16px;
              }
            }

            .per {
              font-size: 14px;
              font-family: 'opensans-regular';
            }

            .description {
              font-size: 10px;
              font-family: 'opensans-regular';
            }
          }
        }
      }

      &.column-primary {
        .cell {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(8) {
            background-color: #fafbfd;
          }
        }
      }

      &.column-business {
        top: -30px;

        .heading {
          background-color: #132239;
          color: #fff;
          height: 165px;
          border-color: #00b2d3;
          border-bottom: 5px solid #00b2d3;

          .plan,
          .title,
          .price {
            color: #fff;
          }

          .sticker {
            background-color: #00b2d3;

            &::before {
              border-left-color: #005767;
            }

            &::after {
              border-right-color: #005767;
            }
          }
        }

        .cell {
          border-left-color: #00b2d3;
          border-right-color: #00b2d3;
          box-shadow: 0 10px 10px rgba($color: #000000, $alpha: 0.05);

          &:nth-last-of-type(2) {
            border-bottom-color: #00b2d3;
          }

          &:last-child {
            box-shadow: none;
            background-color: transparent;
          }

          .tag {
            background-color: #00b2d3;
          }

          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(7),
          &:nth-child(9) {
            background-color: #fafbfd;
          }
        }
      }

      &.column-enterprise {
        top: -15px;

        .heading {
          height: 150px;

          .title {
            margin: -2px 0 0 0;
          }

          .sticker {
            background-color: #f68d38;

            &::before {
              border-left-color: #79451c;
            }

            &::after {
              border-right-color: #79451c;
            }
          }
        }

        .cell {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(8) {
            background-color: #fafbfd;
          }
        }
      }
    }
  }

  .package-info {
    .title {
      float: left;
      width: 100%;
    }

    .edit-package-ntn {
      color: #363839;
      font-size: 14px;
      height: 37px;
      padding: 0 60px;
      border-color: #047cc0;
      float: right;

      .zh-icon-pencil {
        width: 17px;
        height: 17px;

        path {
          transition: all 0.2s ease-in-out;
        }
      }

      &:hover {
        background-color: #2990c9;
        color: #fff;
        border-color: #2990c9;

        .zh-icon-pencil {
          path {
            fill: #fff;
          }
        }
      }
    }

    .rows {
      display: grid;
      grid-template-columns: repeat(4, minmax(180px, 1fr));
      margin-bottom: 30px;
      float: left;
      width: 100%;
      gap: 15px;

      @include mq($until: 1100px) {
        grid-template-columns: repeat(2, minmax(180px, 1fr));
      }

      & > div {
        background-color: #f5f8fb;
        max-width: 100%;
        min-width: 180px;
        color: #152935;
        font-size: 14px;
        padding: 26px 25px 26px 33px;
        font-family: 'opensans-semibold';

        @include mq($until: 1100px) {
          margin: 0 4px 4px 0;
        }

        & + div {
          margin-left: 4px;

          @include mq($until: 1100px) {
            margin: 0 4px 4px 0;
          }
        }

        .info {
          font-size: 22px;
          font-family: 'opensans-bold';
          margin-top: 10px;

          small {
            color: #45535e;
            font-size: 12px;
            vertical-align: middle;
            margin: 0 0 0 0;
            position: relative;
            top: -1.5px;
            display: inline-block;
          }
        }
      }

      &.metrics-rows {
        grid-template-columns: repeat(5, minmax(180px, 1fr));

        @include mq($until: 1280px) {
          display: block;
        }

        & > div {
          background-color: #fff;
          padding: 20px 18px;
          color: #262626;
          font-size: 14px;
          font-family: 'opensans-regular';
          outline: 1px solid #e2eefa;
          margin: 0;
          min-width: 200px;

          @include mq($until: 1280px) {
            width: 100%;
          }

          .sub-title {
            strong {
              color: #2e6080;
            }
          }

          .info {
            color: #262626;
            font-size: 16px;
          }

          .line {
            height: 6px;
            width: 100%;
            background-color: #e2eefa;
            border-radius: 4px;
            margin: 10px 0;
            overflow: hidden;

            & > span {
              display: block;
              height: inherit;
              background-color: #047cc0;
            }
          }
        }
      }
    }
  }
}

.add-company-container {
  .section-settings {
    .company-logo {
      .change-avatar {
        .avatar-alt {
          svg {
            width: 36px;
            height: 36px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }
    }

    .field-minus-btn {
      padding: 0;
      width: 36px;
      height: 36px;
      border: 1px solid #ced9e4;
      margin-left: 10px;

      svg {
        margin-top: 1px;
        path {
          fill: #047cc0;
        }
      }

      i {
        color: #047cc0;
      }

      &:hover {
        background-color: #047cc0;
        svg {
          path {
            fill: #fff;
          }
        }
        i {
          color: #fff;
        }
      }
    }
    .field-plus-btn {
      padding: 0;
      width: 36px;
      height: 36px;
      border: 1px solid #ced9e4;
      margin-left: 10px;

      svg {
        path {
          fill: #047cc0;
        }
      }

      i {
        color: #047cc0;
      }

      &:hover {
        background-color: #047cc0;
        svg {
          path {
            fill: #fff;
          }
        }
        i {
          color: #fff;
        }
      }
    }

    .ant-input {
      height: 36px;
    }

    .form-footer {
      button {
        & + button {
          margin-left: 10px;
        }
      }
    }

    .half-title {
      font-size: 16px;
      color: #2e404f;
      font-family: 'opensans-regular';
      margin: 0 0 15px 0;
    }

    .company-profile-form {
      border-top: 1px solid #e2eefa;
      border-bottom: 1px solid #e2eefa;
      background-color: #f8fafe;
      margin: 20px -20px;
      padding: 20px;

      .ant-input {
        background-color: #fff;
        border: 1px dashed #c3c8c9;
      }

      .ant-input::placeholder {
        color: black;
      }

      .ant-form-item:nth-child(odd) {
        margin-left: 10px;
      }

      .remove-selection {
        color: #5a6872;
        font-size: 14px;
        font-family: 'opensans-semibold';
        float: right;
        cursor: pointer;

        .zh-icon-trash {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          vertical-align: middle;
          position: relative;
          top: -2px;
        }

        &:hover {
          color: #047cc0;
        }
      }
    }

    .edit-company-profile-form {
      border-bottom: 1px solid #e2eefa;
      background-color: #ffff;
      margin: -20px -20px;
      padding: 20px;

      .ant-input {
        background-color: #f5f7fa;
        border: 1px dashed #c3c8c9;
      }

      .ant-input::placeholder {
        color: black;
      }

      .ant-form-item:nth-child(odd) {
        margin-left: 10px;
      }

      .remove-selection {
        color: #5a6872;
        font-size: 14px;
        font-family: 'opensans-semibold';
        float: right;
        cursor: pointer;

        .zh-icon-trash {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          vertical-align: middle;
          position: relative;
          top: -2px;
        }

        &:hover {
          color: #047cc0;
        }
      }
    }

    .contact-information-form {
      .add-field-group {
        .ant-input {
          width: calc(100% - 46px);
        }
        .add-field-group-input {
          .ant-input {
            margin-top: 5px;
          }
        }
      }

      .form-half {
        .ant-form-item {
          width: calc(50% - 10.5px);

          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }

    .edit-contact-information-form {
      margin-top: 40px;

      .add-field-group {
        .ant-input {
          width: calc(100% - 46px);
        }
        .add-field-group-input {
          .ant-input {
            margin-top: 5px;
          }
        }
      }

      .ant-input::placeholder {
        color: black;
      }

      .form-half {
        .ant-form-item {
          width: calc(50% - 10.5px);

          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }

    .ant-form-item:nth-child(even),
    .ant-form-item:nth-child(odd) {
      margin-left: 0;
      margin-right: 0;
    }

    .ant-form-item-label {
      label {
        color: #5a6872 !important;
      }
    }
  }
}

@include mq($until: tablet) {
  .section-settings {
    width: 100%;
    flex-direction: column;
  }
  .ant-spin-nested-loading {
    width: 100% !important;
  }
  .form-half {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item {
    width: 100% !important;
  }
  .ant-form-item:nth-child(even) {
    margin-left: 0 !important;
  }
  .profile-approved.approved {
    right: 0;
  }
}
