@import '../../helpers';

@include mq($until: small-tablet) {
  .ant-modal-footer > .pull-left {
    float: none !important;
    display: flex;
    flex-direction: column;
    :nth-child(1) {
      margin-right: 0;
    }
    :nth-child(2) {
      margin: 10px 0px 0px 0px;
    }
  }

  .ant-modal-footer > .pull-right {
    margin-top: 20px;
    float: none !important;
    display: flex;
    justify-content: center;
  }
  .ant-modal-close {
    right: -3.5px;
    top: -29px;
  }
  .license-table--whitespace {
    white-space: pre-line;
  }
}

@include mq($until: desktop) {
  .ant-modal-close {
    right: -3.5px;
    top: -29px;
  }
}
