@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.zh-drawer {
  border-right: 1px solid #e2eefa;
  background: #fafbfd;
  width: 80%;
  position: fixed;
  z-index: 5;
  overflow-y: auto;
  top: 0;
  animation: all 0.2s ease-in-out;
  -webkit-animation: all 0.2s ease-in-out;

  &.zh-drawer-right {
    animation: fadeInRightConst 0.2s;
    right: 0;
  }

  &.zh-drawer-left {
    animation: fadeInLeft 0.2s;
    left: 0;
  }

  &.zh-drawer-top {
    animation: fadeInTop 0.2s;
    top: 0;
  }

  &.zh-drawer-bottom {
    animation: fadeInBottom 0.2s;
    bottom: 0;
  }

  &.zh-drawer-right,
  &.zh-drawer-left {
    height: 100%;
    max-width: 256px;
  }

  &.zh-drawer-top,
  &.zh-drawer-bottom {
    width: 100%;
    max-height: 256px;
  }

  .drawer-menu {
    padding: 50px 0;
    margin-left: 0;

    li {
      color: #263745;
      padding: 0;
      font: 14px 'opensans-semibold';
      list-style-type: none;

      a {
        display: block;
        padding: 11px 30px;
        padding-left: 65px;
        color: #263745;
        position: relative;

        &.active {
          background: #244b63;
          color: #fff;

          svg path {
            fill: #fff;
          }
        }
      }

      svg {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);

        path {
          fill: #263745;
        }
      }
    }
  }
}

.zh-drawer-wrapper-fadein {
  .with-mask {
    display: block;
  }
}

.zh-drawer-wrapper-fadeout {
  display: none;
}
