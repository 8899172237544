.zh-company-cart {
  padding-left: 5px !important;
  clear: both;

  .small-avatar {
    width: 34px;
    height: 34px;
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    float: left;
    background: #e8f0f6;
    box-shadow: 0px 3px 5px 0.48px rgba(21, 41, 53, 0.1);
  }

  > div {
    float: left;
  }

  > .text {
    margin-left: 19px;
    margin-top: 6px;

    > span {
      margin-bottom: 5px;
      font: 16px 'opensans-semibold' !important;
      color: #152935 !important;
    }

    .name {
      color: #152935;
      font-size: 16px;
      font-family: 'opensans-semibold';
    }

    .edit-link {
      color: #5a6872;
      font-size: 14px;
      margin-left: 20px;

      .zh-icon-pencil {
        width: 16px;
        height: 16px;
        position: relative;
        top: 2px;
        margin-right: 7px;
      }

      &:hover {
        color: #047cc0;
      }
    }
  }
}
