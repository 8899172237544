.popover-sign-up {
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dfe4ec;
  border-radius: 12px;
  box-shadow: 0px 3px 12px #222d3812;
  text-align: left;
  position: absolute;
  top: 620px;
  left: 0;

  &:after {
    content: ' ';
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    background-color: #fff;
    left: -25px;
    top: calc(50% - 50px);
    border-left: 1px solid #dfe4ec;
    border-bottom: 1px solid #dfe4ec;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 100%;
    transform: rotate(45deg);
  }

  @media screen and (min-width: 480px) {
    padding: 20px;
  }
  @media screen and (min-width: 1325px) {
    width: 380px;
    position: absolute;
    top: 130px;
    left: 520px;
  }
  @media screen and (max-width: 1325px) {
    width: 480px;
    position: absolute;
    top: calc(100% + 50px);
    left: 0px;

    &:after {
      content: ' ';
      display: block;
      width: 50px;
      height: 50px;
      position: absolute;
      background-color: #fff;
      left: calc(50% - 25px);
      top: -25px;
      border-left: 1px solid #dfe4ec;
      border-top: 1px solid #dfe4ec;
      border-bottom: none;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 100%;
      border-top-right-radius: 0;
      transform: rotate(45deg);
    }
  }

  @media screen and (max-width: 520px) {
    width: 100%;
  }

  &__box-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-size: 18px;
    line-height: 26px;
    color: #262626;
    display: block;
    margin-bottom: 10px;
  }

  .icon-size-small {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
