@import '../helpers';

.person-detail {
  .sub-title {
    color: #2e404f;
    font-size: 16px;

    &.sub-first-title {
      margin-bottom: 5px;
    }

    &.sub-title-2 {
      font-size: 14px;
      line-height: 15px;
      margin-top: 15px;
    }
  }

  .access-settings-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    label {
      width: 150px;
    }
    span {
      color: #152935;
      font-size: 13px;
      font-family: 'opensans-regular';
      margin: 5px 0;
    }
    .ant-checkbox-wrapper {
      & + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
  }

  .main-info {
    border-top: 1px solid #e2eefa;
    border-bottom: 1px solid #e2eefa;
    margin: 20px -20px 10px -20px;
    padding: 10px 20px;
  }

  .form-footer {
    text-align: left;
    padding: 20px 20px 0 20px;

    .remove-person {
      color: #5a6872;
      font-size: 14px;
      font-family: 'opensans-semibold';
      float: right;
      cursor: pointer;
      margin-top: 8px;

      .zh-icon-trash {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        vertical-align: middle;
        position: relative;
        top: -2px;
      }

      &:hover {
        color: #047cc0;
      }
    }
  }
}

@include mq($until: tablet) {
  .team-section {
    width: 100%;
    flex-direction: column;
  }

  .ant-spin-nested-loading {
    width: 100% !important;
  }
  .form-half {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item {
    width: 100% !important;
  }
  .ant-form-item:nth-child(even) {
    margin-left: 0 !important;
  }
}
