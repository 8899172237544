@import '../helpers';

.top-company-settings-container {
  display: flex;

  &__leftside {
    margin-right: 150px;

    span {
      display: block;
      font-size: 16px;
      margin: 0;
      font: 16px 'opensans-bold';
      line-height: 37.5px;
      margin-right: 20px;
    }

    .ant-input {
      margin-top: 60px;
      margin-bottom: 30px;
    }

    .checkbox-top {
      display: flex;
      margin-bottom: 30px;

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .submit-top-btn {
      height: auto;
      span {
        margin: 0;
      }
    }
  }

  &__rightside {
    span {
      font-size: 16px;
      margin: 0;
      font: 16px 'opensans-bold';
      line-height: 37.5px;
      margin-right: 20px;
    }

    .top-list {
      margin-top: 50px;
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
          display: block;
        }

        .ant-btn {
          height: auto;
          span {
            margin: 0;
          }
        }
      }
    }
  }
}
