@import '../helpers';

.statistic-settings-container {
  &__content {
    .submit-top-btn {
      height: auto;
      margin-top: 30px;

      span {
        margin: 0;
      }
    }

    span {
      display: block;
      margin: 0;
      font: 16px 'opensans-bold';
      line-height: 37.5px;
      margin-bottom: 20px;
    }

    .content__item {
      .title {
        display: block;
        font-size: 22px;
        font-weight: 400;
        margin: 15px 0px;
        line-height: 28px;
      }

      span {
        display: block;
        font-weight: 400;
        margin: 15px 0px;
        font: 16px 'opensans-bold';
        line-height: 28px;
      }

      input {
        width: 50%;
      }
    }
  }
}
