.table-filter-search-wrapper {
  @include mq($until: small-desktop) {
    padding: 70px 15px 15px;
  }

  .view-more {
    display: none;
    margin: 20px auto 5px;
    text-align: center;

    @include mq($until: small-desktop) {
      display: block;
    }
  }
}

.table-footer {
  margin: 15px 0px;
}

.responsive-table {
  font-family: 'opensans-regular';

  .ant-checkbox-wrapper {
    position: relative;
    padding: 0;
    border: 1px solid #eaf0f6;
    border-radius: 7px;
    box-shadow: 0px 7px 27px 3px rgba(31, 105, 179, 0.05);
    background-color: #fff;
    display: block;
    cursor: default;
    margin: 10px 0px 0px 0px !important;

    &.selected-item {
      border-color: #009be9;
      background-color: #e8f6ff;
    }

    > span:last-child {
      display: block;
      width: 100%;
    }

    .ant-checkbox-checked + span {
      border-color: #009be9;
      background-color: #e8f6ff;
      transition: all 0.3s ease;
      font-family: 'opensans-regular';
    }

    .item-id {
      padding: 22px 0 0 22px;
      float: left;
      position: absolute;
    }
    .item-info {
      display: flex;
      flex-wrap: wrap;
      margin: 0 3.125rem 0 3.125rem;
      padding: 0.625rem 0;
      padding: 0.625rem 3.125rem;
      margin: 0;
      border-bottom: 1px solid #eaf0f6;

      &:last-child {
        border: none;
      }

      .titles {
        .title {
          display: block;
          margin-bottom: 0.3125rem;
          padding: 0;
          text-align: left;
          font-size: 0.875rem;
          color: #003366;
        }

        .info-content {
          display: block;
          font-size: 0.8125rem;
          color: #45535e;
          word-break: break-word;
          padding-right: 5px;
        }
      }

      .info-wrapper {
        width: 100%;
        margin-bottom: 0;

        @include mq($until: 1600px) {
          // padding: 10px 50px;
          margin: 0 auto;
        }

        .label {
          margin-bottom: 0.3125rem;
          font-size: 0.6875rem;
          color: #20323f;
          font-family: 'opensans-bold';
        }

        .info-content {
          display: block;
          font-size: 0.8125rem;
          color: #45535e;
          word-break: break-word;
          padding-right: 5px;
          margin: 8px 0;

          .iconed-text svg ~ span {
            margin-left: 5px;
          }

          svg {
            position: relative;

            &.zh-time {
              top: -1px;
            }

            &:not(.zh-time) {
              top: 2px;
            }
          }
        }
        &.email-svg {
          svg {
            top: 0;
          }
        }
      }

      .ant-checkbox-wrapper {
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }

    .item-checkbox-wrap {
      margin: 0 15px;
      border-bottom: none;
      padding: 0;

      @include mq($until: 1600px) {
        border-bottom: 1px solid #eaf0f6;
        margin: 0;
        padding: 10px 50px;
      }

      .ant-checkbox-inner {
        top: -2px;
      }

      .info-wrapper {
        margin-bottom: 0;

        .label,
        .info-content {
          margin: 0;
          padding: 0;
        }

        .label {
          @include mq($until: 1600px) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
