@import '../helpers';

.contacts {
  position: relative;
  padding-left: 20px;
  color: #063467;
  font-family: 'Open Sans';
  font-weight: 600;
  cursor: pointer;
  transition: 0.1s;

  .anticon-phone:before {
    content: none !important;
  }

  .anticon-mail:before {
    content: none !important;
  }

  &:hover {
    color: #0f62c2;

    .contacts-icon {
      color: #0f62c2;
    }
  }

  &.contacts-empty {
    cursor: not-allowed;
    color: #ccc !important;

    i {
      color: #ccc !important;
    }
  }

  .contacts-icon {
    position: absolute;
    left: 0px;
    top: 2px;
  }
}
