@import '../helpers';

.modal-company-information {
  overflow: hidden;

  .ant-drawer-wrapper-body {
    overflow: hidden;
  }

  .ant-table-body {
    border-bottom: none;
  }
  .ant-drawer-body {
    overflow: hidden;
    max-width: 99%;
    padding: 50px 5px 20px 20px;
  }

  .header-info {
    padding: 25px;
    display: flex;
    border-bottom: 1px solid #e9f1fa;
    margin: 0 -25px;

    .wrap-logo {
      float: left;
      width: 90px;
      border: 1px solid #f5f8fb;
      border-radius: 4px;

      img {
        margin: 0;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .company-name {
      margin: 16px 0 0 19px;

      .name {
        color: #20323f;
        font-size: 18px;
        font-family: 'opensans-semibold';
      }

      .note {
        color: #244b63;
        font-size: 13px;
        margin-top: 1px;
      }
    }
  }

  .header-list {
    padding: 20px 0;
    border-bottom: 1px solid #e9f1fa;
    display: flex;

    .left {
      width: 50%;
      float: left;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
    }

    .right {
      width: 50%;
      float: left;
      text-align: right;

      .link-more {
        svg {
          margin-left: 8px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .wrap-table {
    max-height: 70vh;

    table {
      width: 100%;

      .ant-table-thead {
        th {
          background-color: transparent !important;
          border-bottom: 1px solid #1fa3ff !important;
        }
      }

      tr {
        td {
          .zh-icon-clock {
            position: relative;
            top: 2px;
          }
        }

        &:last-child td {
          border-color: #e8e8e8 !important;
        }
      }
    }
  }
}
