.email-page {
  .ant-form-item {
    .ant-select-selection {
      background-color: #ffff;

      &__rendered {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 4px;
      }
    }
  }

  &__contact-us {
    position: relative;
    margin-top: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    span {
      @include fontPoppins;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }

    .zh-btn.zh-btn-link {
      padding: 0 5px;
      font-size: 16px;
      color: #047cc0;
    }
  }

  &__contact-us::after {
    top: -40px;
    position: absolute;
    content: '';
    width: 480px;
    height: 1px;
    background: #dfe4ec;

    @include mq($until: tablet) {
      display: none;
    }
  }

  .ant-select.ant-select-combobox.ant-select-disabled .ant-select-selection {
    background-color: #f5f5f5;
  }

  .ant-form-item-control .ant-input:disabled,
  .ant-form-item-control .ant-input:-webkit-autofill:disabled,
  .ant-form-item-control .ant-input:-internal-autofill-selected:disabled,
  .ant-form-item-control .ant-input:-webkit-autofill:hover:disabled,
  .ant-form-item-control .ant-input:-webkit-autofill:focus:disabled,
  .ant-form-item-control .ant-input:-webkit-autofill:active:disabled {
    background: none !important;
    background-color: #f5f5f5 !important;
  }
}

.email-page-disabled {
  background-color: #f5f5f5;
  min-height: 100%;
  height: auto;
  @media screen and (max-width: 1325px) {
    padding-bottom: 400px;
    height: auto;
  }

  .public-footer {
    display: none;
  }

  .auth-block {
    background-color: #f5f5f5;
  }
}
