// fitler tag
.filter-active-tags {
  width: 100%;
  @extend %clearfix;
  position: relative;

  > .anticon-close {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      color: #f86b45;
    }
  }
}

.container.filter-container {
  padding: 50px 0px 25px;
  left: -1px;
  border-left: none;
  border-right: none;

  &.filter-list-container {
    padding: 50px 25px;
  }

  h1 {
    color: #152935;
    font-size: 16px;
    font-family: 'opensans-semibold';
    margin: 30px 0;
  }

  @include mq($until: small-desktop) {
    padding-top: 50px;
  }

  .ant-alert {
    margin: 10px 10px 0;
  }

  .table-above.zh-search-header {
    margin-bottom: 0;
    padding: 0.5px 10px;
    border-bottom: 1px solid #e2eefa;
    width: 100%;
    display: flex;
    margin-bottom: 0;

    @include mq($until: small-desktop) {
      position: fixed;
      width: calc(100% - 60px);
      top: 50px;
      left: 60px;
      top: auto;
      z-index: 1;
      padding: 0 17px;
      background-color: #fafbfd;
      border-top: 1px solid #eaf0f6;
    }

    .pull-left {
      float: none !important;
      width: 100%;
    }

    .pull-right {
      float: none !important;
      width: auto;
      text-align: right;
      display: flex;
    }

    @include mq($until: tablet) {
      .clear-all-button {
        height: 38px;
        background: transparent;
        border: none;
        padding: 0 18px;

        i,
        svg,
        path {
          color: #547998 !important;
          fill: #547998 !important;
        }

        span {
          display: none;
        }
      }
    }
    @include mq($until: small-tablet) {
      .pull-right {
        .zh-btn {
          min-height: 38px;
          height: 100%;
          background: transparent;
          border: none;
          padding: 0 18px;

          i,
          svg {
            color: #547998 !important;
            fill: #547998 !important;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-body {
    border-bottom: none;
  }

  .ant-table-wrapper.table-blue tbody > tr:first-child td {
    border-top: none !important;
  }

  .ant-table-thead th {
    border-bottom: 1px solid #1fa3ff !important;
  }

  .ant-table {
    table {
      table-layout: fixed;
    }
  }

  .exploration-info {
    display: flex;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 25px;
    }

    @include mq($until: extra-wide) {
      display: block;
    }

    .wrap-list {
      border: 1px solid #e2eefa;
      width: 100%;
      background-color: #f5f7fa;

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;

        @include mq($until: extra-wide) {
          margin-top: 20px;
          margin-left: 0px;
        }
      }

      &.mr-0 {
        margin: 0;
      }

      .title {
        border-bottom: 1px solid #e2eefa;
        color: #152935;
        font-size: 16px;
        font-family: 'opensans-semibold';
        padding: 20px 22px;
        background-color: #fff;

        svg {
          margin-right: 22px;
          width: 22px;
          height: 19px;
          position: relative;
          top: 2px;

          path {
            stroke: #047cc0;
          }
        }
      }

      .no-list {
        padding: 40px 22px 60px 22px;
        text-align: center;

        .wrap-loop {
          height: 92px;
          width: 92px;
          background-color: #fff;
          border-radius: 100%;
          margin: 20px auto 20px auto;
          display: block;
          position: relative;

          svg {
            width: 50px;
            height: 50px;
            margin: auto;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }

        .sub-title {
          color: #047cc0;
          font-size: 20px;
          font-family: 'opensans-semibold';
          margin: 15px 0;
        }

        .description {
          color: #152935;
          font-size: 14px;
          font-family: 'opensans-regular';
          line-height: 22px;
        }
      }

      .saved-list {
        background-color: #fff;

        .column-date {
          width: 130px;
        }

        .column-tools {
          width: 320px;

          @include mq($until: wide) {
            width: auto;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            position: relative;
            border-bottom: 1px solid #eaf0f6;
            padding: 17px 0;
            margin: 0 24px;

            .list-counterparty-wrap {
              width: 34px;
              height: 34px;
              position: relative;
              background-color: #f5f7fa;
              border-radius: 4px;
              display: inline-block;
              vertical-align: middle;

              svg {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;

                path {
                  stroke: #047cc0;
                }
              }
            }

            .list-info {
              display: inline-block;
              vertical-align: middle;
              margin-left: 15px;
              width: calc(100% - 49px);

              .name {
                color: #152935;
                font-size: 16px;
                font-family: 'opensans-semibold';

                a {
                  color: #152935;

                  &:hover {
                    color: #047cc0;
                  }
                }
              }

              .meta-info {
                color: #5a6872;
                font-size: 14px;
                font-family: 'opensans-regular';

                .dot {
                  margin: 0 8px;
                }
              }

              .ant-dropdown-trigger {
                float: right;
                cursor: pointer;

                .zh-icon-dots {
                  path {
                    fill: #749cb5;
                    stroke: #4b7f9f;
                  }

                  &:hover {
                    path {
                      fill: #047cc0;
                      stroke: #047cc0;
                    }
                  }
                }
              }
            }
          }
        }

        .ant-table {
          .ant-table-thead {
            background-color: #f5f8fb;
          }

          th,
          td {
            border-bottom: 1px solid #e2eefa;
          }
        }
      }

      .list-footer {
        border-top: 1px solid #1fa3ff;
        text-align: center;
        padding: 14px 0;
        background-color: #fff;

        a {
          color: #047cc0;
          font-size: 14px;
          font-family: 'opensans-semibold';

          .zh-icon-arrow-right {
            position: relative;
            top: 3px;
            margin-left: 7px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .links-tool {
      a {
        color: #5a6872;
        font-size: 14px;
        font-family: 'opensans-regular';

        & + a {
          margin-left: 20px;

          @include mq($until: wide) {
            margin: 3px 0;
          }
        }

        &:hover {
          color: #047cc0;
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }

        .zh-icon-feedback {
          path {
            stroke: #047cc0;
          }
        }

        @include mq($until: wide) {
          display: block;
        }
      }
    }
  }
}

.saved-list-tools {
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }
}

// filter closed
.root.filter-open {
  width: calc(100% - 500px);
  margin-left: 500px;
  transition: 0.5s ease;

  @include mq($until: wide) {
    width: calc(100% - 60px);
    margin-left: 60px;
  }
}

.root.filter-open .filter-container {
  margin-bottom: 0;
  padding-bottom: 0;
}

.filter-closed {
  position: fixed;
  height: 100%;
  width: 50px;
  border-right: 1px solid #e2eefa;
  background: #fff;
  transition: 0.5s ease;
  z-index: 1;

  &.filter-closed-unactive ul li {
    cursor: not-allowed;
  }

  ul {
    padding: 112.5px 0 0;
    margin: 0;

    li {
      position: relative;
      padding: 10.5px;
      cursor: pointer;

      svg {
        fill: #152935;
        position: absolute;
        @extend %margin-absolute;
        width: auto;
        height: 20px;
      }

      &:hover,
      &.active {
        background: #f5f8fb;

        svg {
          fill: #047cc0;
        }
      }
    }
  }
}

.filter-general {
  display: flex;
  background-color: #fff;
  border-right: 1px solid #e2eefa;
  left: 0;
  top: 0;
  position: fixed;
  width: 500px;
  height: 100%;
  padding-top: 50px;
  overflow-y: auto;
  z-index: 1;

  &.filter-general-mobile-open {
    @include mq($until: wide) {
      width: 100%;
    }
  }

  @include mq($until: wide) {
    padding-top: 50px;
    z-index: 2;
    overflow: visible;
    border-right: none;
    width: 60px;
  }

  .zh-filter-buttons {
    text-align: center;
    width: calc(100% - 100px);
    display: flex;
    position: fixed;
    background: #fff;
    padding-bottom: 15px;
    left: 80px;

    button:not(:last-child) {
      margin-right: 15px;
    }

    .zh-btn-secondary {
      border-color: #5e6061;
      background: transparent;
      color: #434546;
      width: 100%;

      &.zh-btn-primary {
        border-color: #047cc0;
        background: #047cc0;
        color: #fff;
      }
      &.zh-btn-primary:hover {
        background: #78b8dc;
        border-color: #78b8dc;
        color: #fff;
      }
    }
    .zh-btn-secondary:hover {
      background: #5e6061;
      color: #fff;
    }
  }

  .filter-block {
    position: relative;
    height: 100%;

    .filter-subdivision-check {
      padding: 15px 15px 10px;
      font: 14px 'opensans-semibold';
      color: #263745;

      @include mq($until: wide) {
        display: none;
      }

      .ant-checkbox {
        + span {
          padding-left: 15px;
          color: #696969;
        }
      }

      .ant-checkbox-inner {
        width: 18px;
        height: 18px;
        border-color: #ced9e4;
      }

      .ant-checkbox-checked .ant-checkbox-inner:after {
        width: 6px;
        height: 11px;
      }
    }

    &:first-child {
      width: 45%;
      border-right: 1px solid #e2eefa;

      .top-comapnies-block {
        padding: 10px 15px;

        @include mq($until: wide) {
          display: none;
        }

        .filter-header {
          font: 16px 'opensans-bold';
          color: #152935;
          margin: 0;
          padding: 25px 15px 15px 0px;
        }

        .company-list {
          list-style-type: none;
          padding: 0px;

          &__item {
            color: #047cc0;
            margin-bottom: 15px;
          }

          &__item:hover {
            cursor: pointer;
            font: 14px 'opensans-bold';
          }
        }
      }

      @include mq($until: wide) {
        width: 60px;
      }
    }

    &:last-child {
      width: 55%;

      @include mq($until: wide) {
        border-right: 1px solid #e2eefa;
        background: #fafbfd;
        width: 100%;
      }

      &.filter-block-close {
        @include mq($until: wide) {
          display: none;
        }
      }

      &.filter-block-open {
        display: block;
      }
    }

    .go-back {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 18px;
      width: 16px;
      height: 16px;
      opacity: 0.3;
      z-index: 10;
      font-size: 20px;
      display: none;

      @include mq($until: small-desktop) {
        display: inline;
      }
    }
  }
}

.filter-animation {
  transition: 0.5s ease;
  animation: filter-general 0.5s forwards;
}

.filter-header {
  font: 16px 'opensans-bold';
  color: #152935;
  margin: 0;
  padding: 25px 15px 15px;

  @include mq($until: wide) {
    display: none;
  }
}

.filter-header-border {
  padding: 15px;
  border-bottom: 1px solid #e2eefa;
}

.filter-count {
  font: 16px 'opensans-bold';
  color: #152935;
  margin: 0;
  padding: 21px 15px 5px 15px;

  > div {
    margin-top: 10px;
  }

  svg {
    margin-right: 7.5px;
    transition: 0.2s ease;

    &.zh-icon-menu-filter-bag,
    &.zh-icon-menu-filter-staff {
      fill: #5a6872;
      font-size: 17px;
    }

    &.zh-icon-menu-filter-arrow {
      vertical-align: 2px;
      fill: #c3c8c9;
      font-size: 8px;
    }
  }
}

.filter-filter {
  color: #263745;
  padding: 11px 15px;
  padding-left: 50px;
  font: 14px 'opensans-semibold';
  position: relative;
  cursor: pointer;

  @include mq($until: wide) {
    padding: 11px 9px;
    text-align: center;
    height: 59px;
  }

  svg {
    fill: #263745;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
    width: auto;

    path {
      fill: #263745;
    }

    @include mq($until: wide) {
      left: auto;
    }

    &.zh-icon-menu-filter-general {
      left: 32px;
    }
  }

  i {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    margin-left: 35px;
  }

  &:hover {
    color: #1585c4;

    svg {
      fill: #1585c4;

      path {
        fill: #1585c4;
      }
    }
  }

  &.active {
    background: #346f93;
    color: #ffffff;

    svg {
      fill: #ffffff;

      path {
        fill: #ffffff;
      }
    }
  }
}

.filter-recent {
  @include mq($until: small-desktop) {
    display: none;
  }

  a {
    padding: 5px 15px;
    display: block;
    font: 14px 'opensans-semibold';
    color: #073568;
    transition: 0.3s ease;

    &:hover {
      color: #1784c7;
    }

    small {
      display: block;
      color: #707c85;
      font-family: 'opensans-regular';
    }
  }
}

.filter-section {
  border-bottom: 1px solid #e2eefa;
  padding: 15px;
}

.filter-droplist {
  border-bottom: 1px solid #e2eefa;

  .filter-droplist-title {
    position: relative;
    font: 14px 'opensans-regular';
    color: #244b63;
    display: block;
    padding: 15px;
    padding-right: 30px;
    cursor: pointer;
    user-select: none;

    .svg {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(-50%, -50%);

      svg {
        fill: #ced9e4;
        transition: 0.2s ease;
      }
    }
  }

  &.filter-droplist-loading {
    .filter-droplist-title {
      cursor: not-allowed;
      color: #ccc;
    }

    .filter-droplist-children {
      display: none;
    }
  }

  .filter-droplist-children {
    color: #152935;
    font: 13px 'opensans-regular';
    padding: 5px 15px 15px;
    // display: none;

    .extra {
      display: block;
      font-size: 12px;
      padding: 10px;
      margin-bottom: 12.5px;
      background: #e9f0f6;
      color: #517797;
    }

    label:not(.ant-checkbox-wrapper) {
      display: block;
    }

    .ant-tree {
      max-height: 220px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        height: 4px;
        background: #e2eefa;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #047cc0;
        border-radius: 10px;
      }
    }

    .filter-checkbox-wrapper > div {
      max-height: 220px;
      overflow-y: auto;

      label {
        font: 14px 'opensans-regular';
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 6px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        .checkbox {
          cursor: pointer;
          position: relative;
          display: block;
          float: left;
          height: 18px;
          width: 18px;
          border: 1px solid #ccc;
          border-radius: 3px;
          margin-right: 7px;

          input {
            position: absolute;
            display: none;
          }

          &.checkbox-active::after {
            display: block;
            font-family: 'anticon' !important;
            content: '\E632';
            color: #308dc8;
            color: #308dc8;
            position: absolute;
            font-size: 12px;
            font-weight: 1000;
            @extend %margin-absolute;
          }
        }

        .title {
          display: block;
          padding-left: 25px !important;
        }

        &:hover .checkbox {
          border-color: #308dc8;
        }

        @extend %clearfix;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background: #e2eefa;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #047cc0;
        border-radius: 10px;
      }
    }

    > .ant-checkbox-wrapper,
    .ant-radio-wrapper {
      display: block;
      margin: 2.5px 0;
    }

    .ant-input-number,
    .ant-calendar-picker {
      width: calc(50% - 10px);

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  &.open {
    .filter-droplist-title {
      font: 14px 'opensans-semibold';

      .svg .zh-icon-menu-filter-arrow-right {
        fill: #047cc0;
        transform: rotate(90deg);
        font-size: 11px;
      }
    }

    .filter-droplist-children {
      display: block;
    }
  }

  .ant-checkbox-wrapper {
    display: block;
  }
}

.zh-iconed-text {
  position: relative;
  padding-left: 25px;

  &.grey {
    color: #ccc;
  }

  i,
  svg {
    position: absolute;
    left: 0;
    top: 3.5px;
    margin: 0;
  }
}

.filter-tag {
  float: left;
  border-radius: 4px;
  border: 1px solid #e2eefa;
  background: #f5f8fb;
  margin: 6.5px 12px 0 0px;
  padding: 5px 30px 5px 7.5px;
  position: relative;
  animation: fade-in-pulse 0.2s forwards cubic-bezier(0.8, 0.02, 0.45, 0.91);
  cursor: default;

  .filter-tag-text {
    cursor: pointer;

    small {
      display: block;
      color: #758089;
      font: 11px 'opensans-regular', serif;
    }

    span {
      color: #244b63;
      font: 12px 'opensans-regular', serif;
      font-weight: 600;
    }

    &:hover span {
      color: #40a9ff !important;
    }
  }

  .zh-icon-download-close {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    fill: #244b63 !important;

    &:hover {
      fill: #40a9ff !important;
    }
  }

  @extend %clearfix;
}

.zh-filter-tags {
  .ant-tabs-tab {
    margin: 0;
    padding: 0 0 2px;
  }
  .ant-tabs-tab-prev {
    width: 20px;
  }
  .ant-tabs-tab-next {
    width: 25px;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-tab-active,
  .ant-tabs-tab-active:hover {
    font-weight: unset;
    color: unset;
  }
  .ant-tabs-bar {
    margin: 0;
    border-bottom: 0;
  }
  .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 0;
  }
}

.filter-tag-more {
  color: #7a7c7d;
  float: left;
  margin: 9px 12.5px 0px 2.5px;
  padding: 13.5px 0;
  cursor: pointer;
  transition: 0.1s ease;

  svg {
    margin-left: 7px;
    fill: #aaa;
    vertical-align: -1px;
  }

  &:hover {
    transform: translateY(0);
    transform: translateX(-5px);
    color: #00aefa;

    svg {
      fill: #00aefa;
    }
  }

  @extend %clearfix;
}

.filter-header-button-group {
  white-space: nowrap;
  margin: 15px 0;

  > a {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
}

h1.table-results {
  margin: 15px 0;
}

.filter-header-button {
  margin: 12.5px 0;
  display: flex;

  > .btn-icn > button,
  > button,
  > span > button {
    display: block;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #e2eefa;
    color: #363839;
    font: 14px 'opensans-semibold';
    padding: 8.5px 30px 8.5px 35px;

    i {
      position: absolute;
      margin: 0;
      left: 10px;
    }

    .zh-icon-filter-list-plus {
      font-size: 16px;
      top: 50%;
    }

    &.zh-btn:hover {
      background: #5e6061;
      border-color: #5e6061;
      color: #ffffff;

      &.zh-btn:first-child {
        background: #1fa3ff;
        border-color: #1fa3ff;
      }
    }
  }

  > button,
  > .btn-icn,
  > span {
    &:first-child {
      margin-right: 12.5px;

      @include mq($until: small-desktop) {
        margin: 0 12.5px;
      }
    }
  }
}

.filter-select {
  width: calc(100% - 15px);
  margin-top: 5px !important;
}
#turnover-year .ant-dropdown-placement-bottomLeft {
  position: fixed;
}
// .turnover-year {
//   position: fixed !important;
// }

.tag {
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  padding: 0 4px;
  color: #fff;
  font-weight: 'opensans-bold';
  font-size: 10px;
  border-radius: 2px;
  background-color: #07a1bd;

  &.tag-failed {
    background-color: #de4747;
  }

  &.tag-processing {
    background-color: #5a6872;
  }
}
