.list-details-selected {
  width: 100%;
  display: flex;
  align-items: center;

  .counter-section {
    border-right: 1px solid #e2eefa;
    display: inline-flex;
    padding: 22px 15px 22px 0;

    svg {
      margin-right: 10px;
      margin-left: 3.5px;
      fill: rgb(84, 121, 152);
      font-size: 19px;
    }

    .counter {
      color: #547998;
      font-family: 'opensans-bold';
    }
  }

  .actions {
    display: flex;
    margin: 0px 10px;

    .action {
      margin: 0px 10px;
      cursor: pointer;
      align-self: center;
    }

    button {
      color: #547998;
      margin-left: 10px;

      svg path {
        fill: #547998;
      }

      span {
        margin-left: 5px;
      }

      &:hover {
        background: #547998;
        color: #fff;

        svg path {
          fill: #fff;
        }
      }
    }
  }
}

.alerts .list-details-selected {
  padding: 5.5px 0;
  display: flex;
  align-items: center;

  .counter-section {
    margin-left: 22.5px;
  }
}
.action {
  .zh-icon {
    fill: rgb(84, 121, 152);
    font-size: 16px;
  }
  .zh-icon-save-list {
    font-size: 21px;
    vertical-align: -5px;
  }
  .zh-icon-export {
    font-size: 19px;
  }
  .zh-icon-invert {
    font-size: 18px;
  }
}
