.table-price {
  font: 16px 'opensans-semibold';
}

.table-clean {
  > thead > tr > th {
    border-bottom: 2px solid #e2eefa !important;
    font-family: 'opensans-regular';
    color: #152935;
  }

  > tbody tr td {
    .zh-icon-search {
      margin-right: 5px;
      vertical-align: 0px;
      font-size: 18px;

      &.fix-svg {
        vertical-align: -4px;
      }
    }
  }

  &.table-header {
    > thead > tr > th {
      background: #f5f8fb !important;
      padding: 15px !important;
      font-family: 'opensans-semibold' !important;
    }

    > tbody > tr {
      > td {
        padding: 15px !important;
      }

      &:last-child td {
        border-bottom: 1px solid #1fa3ff !important;
      }
    }
  }

  &.table-no-header > tbody > tr {
    &:first-child td {
      border-top: 1px solid #eaf0f6 !important;
    }

    &:last-child td {
      border-bottom: 1px solid #1fa3ff !important;

      @include mq($until: desktop) {
        border-bottom: none !important;
      }
    }
  }

  &.table-odd tbody tr {
    &:nth-child(even) {
      background: #fafbfd;
    }
  }

  &.table-blue {
    > thead > tr > th {
      border-bottom: 0 !important;
      font-family: 'opensans-regular' !important;

      &:first-child {
        .ant-checkbox-wrapper {
          vertical-align: 3px !important;
        }
      }
    }

    tr td:first-child,
    tr th:first-child {
      padding-left: 15px !important;
      padding-right: 0 !important;
      text-align: center;
    }

    > tbody > tr {
      &:first-child td {
        border-top: 1px solid #1fa3ff !important;
      }

      &:nth-child(even) {
        background: #fafbfd;
      }

      &:last-child td {
        border-bottom: 1px solid #1fa3ff !important;
      }

      &.selected {
        background-color: #fefef4;
      }
    }

    th.collapsing .ant-checkbox-wrapper {
      margin-bottom: 7.5px;
    }
  }

  &.ui.table {
    tr {
      td {
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 14px;
      }

      &:last-child td {
        border-bottom: 2px solid #e2eefa;
      }
    }
  }

  &.table-saved-lists {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    position: relative;

    .anticon-search {
      font-size: 18px;
      color: rgb(4, 124, 192);
      margin-right: 4px;
    }

    > tbody > tr {
      position: relative;

      .responsive2 {
        max-width: 100px !important;
      }

      & > td {
        @include mq($until: small-desktop) {
          margin-bottom: 10px !important;
        }

        &.collapsing,
        &.in-two-rows {
          @include mq($until: small-desktop) {
            display: inline-block !important;
          }
        }
      }

      &:first-child td {
        border-top: 0 !important;
      }
    }

    .wrap-popover {
      @include mq($until: small-desktop) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &.hover {
    > tbody tr {
      &:not(.editing) {
        &:hover {
          background-color: #e9f2fb;
        }
      }
    }
  }
}

.table-grey {
  margin: 20px 0 !important;

  tr th {
    padding: 12.5px 45px !important;
  }

  tr td {
    background: #f5f8fb !important;
    padding: 12.5px 45px !important;
    border-top: 2px solid #fff !important;
    font-family: 'opensans-semibold';

    &:first-child {
      border-right: 2px solid #fff !important;
    }
  }
}

.table-filter-search {
  tr td {
    .title-disabled {
      cursor: not-allowed;
      color: #063467;
      font-family: 'opensans-semibold';
    }

    a {
      color: #063467;

      &:hover {
        color: #1784c7;
      }

      &.title {
        font-family: 'opensans-semibold';
      }
    }
  }

  th:first-child,
  td:first-child {
    width: 40px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 300px;

    div {
      display: inline;
    }
  }
}

.table-similar-company {
  th:first-child,
  td:first-child {
    width: 300px;
  }
}

.table-alerts-company {
  .collapsing {
    width: 46px !important;
  }
}

.table-action-text {
  color: #5a6872;
  transition: 0.3s ease;

  &:hover {
    color: #047cc0;
  }

  svg,
  .zh-history-spin {
    position: relative;
    top: 2px;
    margin-right: 5px;
    fill: #047cc0;
  }

  .zh-icon-edit {
    font-size: 17px;
  }

  .zh-history-spin {
    top: 0;
  }
}

.table-footer {
  button {
    padding: 0 30px;
    font-family: 'opensans-semibold';
  }

  .table-footer-left button {
    margin-right: 10px;
  }

  .ant-select-selection--single {
    height: 31px;
    border: 1px solid #ced9e4;
    background: #f5f7fa;
  }

  .ant-select-selection__rendered {
    line-height: 30px;
  }

  .ant-select-selection-selected-value,
  .ant-select-arrow {
    color: #152935;
  }

  .pagination-filter {
    font-size: 12px;
    padding-left: 10px;
    color: #5a6872;
  }

  .pagination-filter-reverse {
    padding-right: 10px;
    padding-left: 0;
  }

  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

.table-above {
  margin-bottom: 20px;

  .ant-select {
    min-width: 220px;
  }

  .form-inline {
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  h1 {
    font-size: 16px;
    margin: 0;
    font: 16px 'opensans-bold';
    line-height: 37.5px;
    margin-right: 20px;
  }

  button {
    border-color: #047cc0;
    color: #363839;
    font-family: 'opensans-semibold';

    &:disabled i {
      color: rgba(0, 0, 0, 0.25) !important;
    }

    i {
      color: #047cc0;
      font-weight: normal;
      margin-right: 5px;
    }

    &:hover {
      background: #047cc0;
      border-color: #047cc0;

      color: #fff;
      box-shadow: 0px 10px 21.12px 0.88px rgba(21, 41, 53, 0.12);

      i {
        color: #fff;
      }
    }
  }

  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

.table-cell-contacts {
  width: 210px;
}

.table-cell-function {
  width: 185px;
}
