.public-header {
  width: 100%;

  &__content {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding-top: 27px;
    display: flex;
    justify-content: space-between;

    @include mq($until: extra-wide) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include mq($until: small-tablet) {
      display: block;
    }

    .auth-logo {
      @include mq($until: small-tablet) {
        display: block;
        text-align: center;
      }
    }

    .phone-number {
      display: flex;
      align-items: center;

      @include mq($until: small-tablet) {
        display: block;
        margin-top: 20px;
        text-align: center;
      }

      svg {
        margin-right: 12px;
      }

      a {
        @include fontPoppins;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #262626;
      }
    }
  }
}
