@import '../helpers';

@include mq($until: desktop) {
  .item-checkbox-wrap > .info-wrapper {
    margin-top: 10px;
    margin-left: 34px;
    :last-child {
      margin-top: 10px;
    }
  }
}
