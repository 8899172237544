@media print {
  .filter-closed,
  header,
  .user-guide-button,
  .company-logo-right,
  .social-media,
  .filter-general,
  .juridical-tabs,
  .tabs {
    display: none !important;
  }

  body {
    -webkit-text-stroke: 0px !important;
  }

  .container {
    padding: 20px !important;
  }

  .filter-open {
    width: 100% !important;
    transform: translateX(0) !important;
  }
}
