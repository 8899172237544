.juridical-content {
  padding: 15px;
}

.juridical-info {
  h2 {
    font: 16px 'opensans-semibold';
    margin: 0 0 20px;
  }

  .table-footer {
    padding-top: 17.5px;
    border-top: 1px solid #eaf0f6;
    margin-top: 22.5px;

    .pagination {
      margin: 0;
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    padding: 17px 20px;
    padding-left: 60px;
    position: relative;
    border-bottom: 1px solid #eaf0f6;

    .ant-modal-title > div > span {
      position: absolute;
      left: 20px;
      top: 20px;
      color: #047cc0;
      font: 16px 'opensans-semibold';
    }

    h1 {
      font: 16px 'opensans-semibold';
      color: #152935;
      margin: 0 0 2.5px;
    }

    p {
      font: 14px 'opensans-regular';
      color: #244b63;
      margin: 0;
    }
  }

  .ant-modal-body {
    background: #fff;
    padding: 20px 17.5px;
  }
}

.juridical-info-tabs {
  display: flex;
  padding: 15px 15px 20px;
  border-bottom: 2px solid #eaf0f6;
  background: #fafbfd;

  @include mq($until: small-desktop) {
    display: block;
  }

  li {
    width: 100%;
    position: relative;
    display: block;
    padding: 12.5px 5px;
    padding-left: 50px;
    color: #152935;
    font: 13px 'opensans-semibold';
    border: 1px dashed rgb(226, 238, 250);
    box-shadow: 0px 3px 11.52px 0.48px rgba(21, 41, 53, 0.07);
    transition: 0.1s;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 20px;
    }

    svg {
      position: absolute;
      left: 12.5px;
      top: 50%;
      transform: translateY(-50%);
      fill: #047cc0;
      font-size: 22px;
    }

    .zh-icon-juridical-info-generals {
      font-size: 21px;
    }

    .zh-icon-juridical-info-process {
      font-size: 24px;
    }

    .zh-icon-juridical-info-meeting,
    .zh-icon-juridical-info-solution {
      font-size: 19px;
    }

    .zh-icon-juridical-info-ads {
      font-size: 22px;
    }

    &:hover {
      background: #f5f8fb;
      border-color: rgb(226, 238, 250);
      box-shadow: 0px 3px 11.52px 0.48px rgba(21, 41, 53, 0.07);
    }

    &.active {
      background: #047cc0;
      color: #fff;
      border: 1px solid rgb(226, 238, 250);

      svg {
        fill: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border-top: solid 5px #047cc0;
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;

        @include mq($until: small-desktop) {
          display: none;
        }
      }
    }

    &.unactive {
      background: #fff !important;
      border-color: #ccc;
      color: #ccc;
      cursor: not-allowed;

      svg {
        fill: #ccc;
      }
    }
  }
}

.juridical-card-section {
  display: flex;

  @include mq($until: desktop) {
    display: block;
  }

  .juridical-card,
  .juridical-card-number {
    width: 100%;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &.juridical-card-section-block {
    display: block !important;

    .juridical-card,
    .juridical-card-number {
      width: 100%;
    }
  }

  &.juridical-card-section-rows,
  &.juridical-card-section-rows-top {
    > div {
      margin: 0 !important;

      &:not(:first-child) {
        border-left: 0;
      }
    }
  }

  &.juridical-card-section-rows-top {
    border-top: 2px solid #1fa3ff;

    .juridical-card:first-child {
      border-left: 1px solid #e2eefa;
    }
  }
}

.juridical-card {
  width: 100%;
  border: 1px solid #e2eefa;
  border-left: 2px solid #1fa3ff;
  padding: 27.5px 17.5px;
  background: #fafbfd;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &.juridical-card-label-status,
  &.juridical-card-label-status-thin {
    h2 {
      color: #244b63;
      font: 14px 'opensans-semibold';
      margin: 0 0 2.5px;
    }

    p,
    a {
      display: block;
      font: 14px 'opensans-semibold';
      margin: 0;
    }

    p {
      color: #000;
    }
  }

  &.juridical-card-label-status-thin {
    padding: 17.5px;
  }
}

.juridical-card-section-rows {
  & > .juridical-card-number + .juridical-card.juridical-card-label-status-thin:last-child {
    @include mq($until: desktop) {
      width: calc(100% - 45px) !important;
      left: 45px !important;
      position: relative !important;
      border-left: 2px solid #1fa3ff !important;
    }
  }
}

.juridical-card-number {
  padding-left: 45px;
  position: relative;

  span {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #5a6872;
    font: 16px 'opensans-semibold';
  }
}

.juridical-schedule {
  @extend %clearfix;

  .juridical-schedule-time {
    float: left;
    width: calc(12.5% - 20px);
    margin-top: 7.5px;
    margin-right: 20px;
    position: relative;
    padding: 10px 5px;
    background: #fff;
    border: 1px solid #047cc0;
    z-index: 1;

    @include mq($until: small-desktop) {
      width: 100%;
    }

    &:after {
      content: ' ';
      position: absolute;
      height: 0;
      width: 0;
      left: 100%;
      top: calc(50% - 7.5px);
      border: 7.5px solid transparent;
      border-left: 7.5px solid #047cc0;

      @include mq($until: small-desktop) {
        display: none;
      }
    }

    > span {
      display: block;
      text-align: center;
      font: 14px 'opensans-semibold';
      color: #152935;
    }
  }

  .juridical-card-section {
    float: left;
    width: 87.5%;
    box-shadow: 0px 3px 11.52px 0.48px rgba(21, 41, 53, 0.07);

    @include mq($until: small-desktop) {
      width: 100%;
    }

    .juridical-card {
      border-left: 1px solid #eaf0f6;
    }
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &.active {
    .juridical-schedule-time {
      background: #047cc0;

      span {
        color: #fff;
      }
    }

    .juridical-card-section .juridical-card {
      border-bottom: 2px solid #047cc0;
    }
  }
}

.juridical-schedule-block {
  position: relative;

  &:before {
    clear: both;
    display: block;
    content: ' ';
    height: calc(100% - 40px);
    width: 2px;
    background: #e2eefa;
    position: absolute;
    top: 40px;
    left: 80px;

    @include mq($until: small-desktop) {
      display: none;
    }
  }
}

.solution-instance {
  h2 {
    background: #047cc0;
    color: #fff;
    font: 16px 'opensans-semibold';
    padding: 20px;
    margin: 15px 0 0;
  }

  div {
    padding: 20px;
    border: 2px solid #047cc0;

    pre {
      margin: 0;
      font: 14px 'opensans-regular';
    }

    > span {
      cursor: pointer;
      color: #047cc0;

      i {
        color: #047cc0;
      }

      &:hover {
        color: #72b5db;

        i {
          color: #72b5db;
        }
      }
    }
  }
}

.juridical-card-publication {
  padding: 20px;
  box-shadow: 0px 3px 11.52px 0.48px rgba(21, 41, 53, 0.07);

  small {
    font: 14px 'opensans-regular';
  }

  h2 {
    font: 14px 'opensans-regular';
    margin: 10px 0;
  }

  p {
    font: 16px 'opensans-semibold';
    margin: 0;
    color: #152935;
  }
}
