@import '../helpers';

.legal-bulletin-content {
  width: 100%;
  padding: 30px 95px;

  .delete-list-item-trash {
    svg {
      path {
        fill: #263745 !important;
        stroke: #263745 !important;
      }
    }
  }

  .page-header-bulletin {
    display: flex;
    width: 100%;
    padding: 0 0 20px 0;

    .left {
      float: left;
      width: 50%;

      .left-title {
        display: inline-block;
        vertical-align: middle;
        color: #152935;
        font-size: 16px;
        font-family: 'opensans-semibold';
        margin-right: 20px;
        line-height: 38px;
      }

      .ant-input-group {
        display: inline-block;
        vertical-align: middle;
        max-width: 225px;

        input {
          color: #152935;
          font-size: 14px;
          font-style: normal;
        }
      }
    }

    .right {
      float: right;
      width: 50%;
      display: flex;
      justify-content: right;
      align-items: center;

      button + button {
        margin-left: 15px;
      }

      .ant-btn {
        background-color: #047cc0;
        font-size: 14px;
        color: #fff;
        font-family: 'opensans-semibold';
        border: none;
        width: 130px;
        padding: 0 20px 0 0;
        text-align: right;
        height: 38px;
        line-height: 18px;

        svg {
          width: 19px;
          height: 14px;
          top: 51%;

          path {
            fill: #ffff;
          }
        }

        &:hover {
          background-color: #78b8dc;
        }
      }
    }
  }

  .bulletin {
    display: block;
    float: left;
    width: 100%;
    border: 1px solid #e2eefa;
    border-bottom: 2px solid #1fa3ff;
    margin: 7px 0;

    &.active {
      border: 1px solid #1fa3ff;

      .bulletin-header {
        background-color: #e9f2fb;

        .icon-wrap {
          background-color: #fff;
        }
      }
    }

    .bulletin-header:hover {
      cursor: pointer;
    }

    .bulletin-header {
      padding: 30px 23px;
      float: left;
      width: 100%;
      border-bottom: 1px solid #e2eefa;

      .icon-wrap {
        background-color: #eff6fc;
        border-radius: 4px;
        width: 49px;
        min-width: 49px;
        height: 49px;
        margin-right: 20px;
        position: relative;
        float: left;

        svg {
          width: 22px;
          height: 32px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }

      .title {
        color: #20323f;
        font-size: 22px;
        font-family: 'opensans-semibold';
        float: left;
        width: calc(100% - 70px);
        margin-top: -4px;

        .date {
          color: #152935;
          font-size: 12px;
          text-align: right;
          float: right;
          margin: 8px 0 8px 0;

          .zh-icon-clock {
            position: relative;
            top: 1px;
            margin-right: 7px;

            path {
              fill: #869aac;
            }
          }
        }
      }

      .bulletin-list-info {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          color: #5a6872;
          font-size: 13px;
          font-family: 'opensans-regular';
          list-style-type: none;
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          position: relative;

          // &:after {
          //   content: '•';
          //   display: inline-block;
          //   vertical-align: middle;
          //   color: #dae3eb;
          //   margin: 0 10px;
          // }

          &:last-child:after {
            display: none;
          }
        }

        .list-point {
          position: absolute;
          top: 7px;
          left: -12px;
          width: 6px;
          height: 6px;
          background-color: #d4d6d8;
          border-radius: 50%;
        }
      }
    }

    .wrap-industries {
      float: left;
      width: 100%;
      padding: 28px 25px;
      color: #244b63;
      font-family: 'opensans-semibold';
      font-size: 14px;

      .industries {
        color: #047cc0;
        margin-top: 4px;
        display: flex;
        justify-content: space-between;

        &__items {
          padding-right: 20px;
        }

        span {
          cursor: pointer;
        }
      }

      .bulletin-checkbox {
        float: right;
      }
    }

    .pse-table-close {
      display: none !important;
    }

    .pse-table {
      display: flex;
      width: 100%;
      border-top: 2px solid #1fa3ff;
      color: #152935;
      font-size: 14px;
      font-family: 'opensans-semibold';

      .key {
        color: #047cc0;
        width: 53px;
        min-width: 53px;
        border-right: 1px solid #e2eefa;
        text-align: center;
        position: relative;

        span {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          height: 20px;
        }
      }

      .wrap-items {
        width: 100%;
        display: grid;
        gap: 0px 0px;
        grid-template-columns: repeat(2, minmax(320px, 1fr));

        .item {
          padding: 17px 20px 20px 17px;
          border-right: 1px solid #e2eefa;
          border-bottom: 1px solid #e2eefa;

          .act-industries {
            color: #047cc0;
            cursor: pointer;
          }

          .read-more-block {
            max-height: 100px;
            overflow-y: hidden;
          }

          .first-row {
            max-height: 100% !important;
            overflow-y: auto !important;
          }

          .second-row {
            max-height: 100% !important;
            overflow-y: auto !important;
          }

          .third-row {
            max-height: 100% !important;
            overflow-y: auto !important;
          }

          .title {
            color: #244b63;
          }

          .read-more-btn {
            z-index: 5;
            color: #047cc0;
            cursor: pointer;
          }

          .read-more-btn:hover {
            color: #78b8dc;
          }

          p {
            margin: 5px 0;

            .gap {
              display: inline-block;
              width: 7px;
            }
          }

          ul {
            margin: 10px 0;
            padding: 0 0 0 18px;
            list-style: none;

            li {
              position: relative;

              &:before {
                content: '\2022';
                color: #78b8dc;
                font-weight: bold;
                display: inline-block;
                position: absolute;
                left: -18px;
              }
            }
          }

          .normative-act {
            display: block;
            line-height: 25px;
            padding: 5px 0;

            .left {
              float: left;
              margin-right: 5px;
            }

            .right {
              float: right;
              text-align: right;

              .normative-act-link {
                display: inline-block;
                color: #047cc0;
                font-size: 13px;
                font-family: 'opensans-semibold';
                height: 36px;
                padding: 0 13px;
                border: 1px solid #ced9e4;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;
                line-height: 33px;
                margin-top: 8px;

                &:hover {
                  border-color: transparent;
                  color: #fff;
                  background-color: #047cc0;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 1325px) {
        .wrap-items {
          grid-template-columns: repeat(1, minmax(320px, 1fr));
        }
      }
    }
  }

  .table-footer {
    margin-top: 50px;
    position: relative;
    float: left;
    width: 100%;

    &:after {
      content: '';
      display: block;
      height: 1px;
      background-color: #1fa3ff;
      position: absolute;
      top: -25px;
      left: -95px;
      right: -95px;
    }
  }

  &.legal-bulletin-content-list {
    .bulletin {
      border-bottom: 1px solid #e2eefa;
      border-top: 2px solid #1fa3ff;

      &.active {
        border: 1px solid #78b8dc;
        border-top: 2px solid #1fa3ff;
      }
    }
  }

  &.legal-bulletin-content-acts {
    .page-header-bulletin {
      padding: 0 0 5px 0;

      .right {
        color: #5a6872;
        font-size: 12px;

        .ant-select {
          margin: 0 20px 0 10px;
        }

        .zh-btn {
          height: 38px;
          width: 140px;
          font-size: 14px;
          padding: 0 15px 0 38px;
          width: auto;
          vertical-align: middle;

          svg {
            width: 17px;
            height: 17px;
            left: 11px;
            top: 18px;
          }
        }

        .ant-select {
          vertical-align: middle;

          .ant-select-selection--single {
            height: 38px;

            .ant-select-selection__rendered {
              line-height: 36px;
            }

            .ant-select-arrow:before {
              font-weight: 700;
              color: #152935;
            }
          }
        }
      }
    }

    .wrap-code-links {
      margin: 0;
      padding: 0;
      list-style-type: none;
      float: left;
      width: 100%;

      li {
        display: block;
        margin: 20px 0 0 0;
        font-family: 'opensans-semibold';
        position: relative;

        .act-item-check {
          position: absolute;
          top: 25px;
          left: -40px;
        }

        a {
          color: #20323f;
          font-size: 18px;
          display: block;
          border: 1px solid #e2eefa;
          border-bottom: 2px solid #78b8dc;
          padding: 24px 20px;
          transition: all 0.2s ease-in-out;
          position: relative;

          &:hover {
            border: 1px solid #78b8dc;
            border-bottom: 2px solid #047cc0;
            color: #047cc0;

            .zh-icon-bulletin-arrow-right-big {
              path {
                fill: #047cc0;
              }
            }
          }

          svg {
            vertical-align: top;
          }

          .zh-icon-bulletin-scales {
            width: 25px;
            height: 25px;
            margin-right: 22px;
          }

          .zh-icon-bulletin-arrow-right-big {
            position: absolute;
            right: 15px;
            top: 42%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .legal-bulletin-content {
    padding: 15px;

    .bulletin {
      .bulletin-header {
        .title {
          font-size: 17px;
        }

        .bulletin-list-info {
          li {
            font-size: 11px;
          }
        }
      }
    }

    .table-footer:after {
      left: 0;
      right: 0;
    }
  }
}

@media screen and (max-width: 790px) {
  .container.legal-bulletin-container {
    .page-header-bulletin {
      display: block;
      float: left;
      width: 100%;

      .left,
      .right {
        width: 100%;
        text-align: left;
        padding: 10px 0;

        .ant-input-group {
          width: 100%;
          max-width: 100%;
          margin-top: 15px;
        }

        .ant-calendar-picker {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 645px) {
  .container.legal-bulletin-container {
    .navigation {
      height: auto !important;

      .ant-radio-button-wrapper {
        margin: 5px 0;
        width: 100% !important;
      }
    }
  }
}

.table-menu-tools {
  min-width: 220px;

  li {
    color: #263745;
    font-size: 14px;
    font-family: 'opensans-semibold';
    border-bottom: 1px solid #e7f1fb;

    &:last-child {
      border-bottom: none;
    }

    svg {
      width: 17px;
      height: 16px;
      position: relative;
      top: 3px;
      margin-right: 20px;
    }

    .zh-icon-bulletin-unload {
      left: 2px;
    }

    .zh-icon-trash {
      fill: rgb(90, 104, 114);
    }
  }
}

.normative-act-modal {
  .ant-modal-close-x {
    line-height: 64px;
    font-size: 19px;
  }

  .ant-modal-header {
    padding: 17px 24px;
    color: #2e404f;
    font-size: 16px;
    font-family: 'opensans-regular';
  }

  .normative-act-form {
    max-width: 380px;
    margin: auto;

    label {
      text-align: center;
      color: #5a6872;
      font-family: 'opensans-semibold';
      display: block;

      input {
        font-size: 14px;
        margin-top: 5px;
      }

      & + label {
        margin-top: 15px;
      }
    }
  }

  .edit-act-modal {
    display: flex !important;
    justify-content: space-between;
    margin-top: 20px;

    .right-side {
      button + button {
        margin-left: 15px;
      }
    }
  }

  .modal-error-messages {
    display: block;
    color: red;
    font-size: 12px;
    margin-top: 15px;
    font-weight: 400;
  }

  .ant-modal-footer {
    text-align: center;
    padding: 21px 16px;

    .ant-btn {
      height: 38px;
      width: 120px;

      & + .ant-btn {
        margin-left: 10px;
      }
    }
  }
}
.industries-popover {
  max-width: 200px !important;
  color: #047cc0;
}

.legal-bulletin-detail-container {
  .navigation {
    padding: 8px 6px;
    height: 48px;
    width: 100%;

    .ant-radio-button-wrapper {
      color: #244b63;
      font-size: 14px;
      font-family: 'opensans-semibold';
      background-color: #eff2f5;
      height: 28px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      min-width: 165px;
      border-radius: 0;
      border: none;
      position: relative;
      transition: all 0.2s ease-in-out;

      &:not(:first-child)::before {
        display: none;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: #047cc0;
        color: #fff;
        height: 32px;
        top: -2px;
        border-radius: 4px;
      }
    }
  }
}
