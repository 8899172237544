@import '../helpers';

// messages all component
.monitoring-all {
  .table-above {
    padding: 0 20px;
    margin: 15px 0;

    .monitoring-count {
      color: #152935;
      font-size: 16px;
      font-family: 'opensans-semibold';
      line-height: 37px;
    }
  }

  .table-footer {
    padding: 0 20px;
    margin: 10px 0;

    .monitoring-count {
      color: #152935;
      font-size: 16px;
      font-family: 'opensans-semibold';
      line-height: 37px;
    }
  }

  .table-above {
    h1 {
      display: inline-block;
    }

    .ant-input-affix-wrapper {
      width: 200px;
      height: 36px;
      border-color: #e2eefa;

      input {
        color: #152935;
        font-size: 14px;
        font-family: 'opensans-regular';
        font-style: normal;
      }

      .ant-input-search-icon {
        color: #192c38;
      }
    }

    .delete-list-button {
      margin-right: 10px;
      transition: none;
    }

    .edit-list-button {
      transition: none;
      margin-right: 10px;
    }

    .add-contractors-button {
      transition: none;

      svg {
        position: relative;
        margin-right: 10px;
        top: 30%;
        left: 0;
        width: 21px;

        path {
          fill: #363839;
        }
      }
    }

    .add-contractors-button:hover {
      svg {
        path {
          fill: #ffff;
        }
      }
    }
  }

  .table-footer {
    .pagination {
      margin: 5px 0;
    }
  }

  .monitoring-table {
    color: #262626;
    font-size: 14px;
    font-family: 'opensans-regular';
    border-top: 1px solid #e2eefa;

    &.events-table {
      .name-column .name-wrap .name {
        cursor: pointer;
      }
    }

    .monitoring-table-item {
      border-top: 1px solid #e2eefa !important;
    }

    tr {
      border-bottom: 1px solid #e2eefa;

      &:hover,
      .ac {
        background-color: #f5fbf5;
      }

      td,
      th {
        vertical-align: top;
        padding: 20px !important;
        min-width: 150px;

        .zh-icon-clock {
          margin-right: 5px;
        }

        .updated-count {
          display: inline-block;
          vertical-align: middle;
          line-height: 18px;
          font-size: 14px;
          color: #262626;
          padding: 0 5px;
          border: 1px solid #dfe6ee;
          margin: 0 4px;
          border-radius: 100%;
          position: relative;
          top: -1px;
          font-family: 'opensans-semibold';
        }
      }

      &:nth-child(2n) {
        background-color: #fafbfd;
      }
    }

    th {
      td {
        font-family: 'opensans-semibold';
        color: #152935;
      }
    }

    .name-column {
      width: 30%;
      border-top: 1px solid #e2eefa !important;

      .name-wrap {
        display: flex;

        .name {
          margin-left: 15px;
          margin-top: 8px;
          width: calc(100% - 50px);
          cursor: pointer;
          font-family: 'opensans-semibold';

          &:hover {
            color: #047cc0;
          }
        }

        .tag-new {
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          padding: 0 4px;
          margin: 0 7px;
          color: #fffeff;
          font-size: 14px;
          background-color: #68ba8a;
          border-radius: 4px;
          line-height: 19px;
        }
      }
    }

    .idno-column {
      border-top: 1px solid #e2eefa;
    }

    .products-services-column {
      border-top: 1px solid #e2eefa;

      .text-check {
        color: #047cc0;
        cursor: pointer;
        font-weight: 500;
      }
    }

    .type-column {
      border-top: 1px solid #e2eefa;
    }

    .edited-column {
      border-top: 1px solid #e2eefa;
    }

    .date-column {
      border-top: 1px solid #e2eefa;
    }

    .tools-column {
      width: 20px;
      text-align: right;
      border-top: 1px solid #e2eefa;

      svg {
        cursor: pointer;

        path {
          fill: #749cb5;
        }

        &:hover {
          path {
            fill: #66afd9;
          }
        }
      }
    }
  }
}

.ant-dropdown {
  svg {
    width: 16px;
    height: auto;
    vertical-align: middle;

    &.zh-icon-add-list-plus {
      width: 20px;

      path {
        fill: #047cc0;
      }
    }

    &.zh-icon-remove-trash {
      path {
        stroke: #047cc0;
      }
    }
  }
}

.table-menu-tools {
  font-size: 14px;
  color: #152935;
  padding: 5px 0;
  border-radius: 4px;
  min-width: 150px;

  svg {
    margin-right: 9px;
  }
}
