.public-forget-password {
  .ant-form-item-control {
    .ant-form-item-children {
      display: flex;
      justify-content: space-between;

      .pull-right {
        margin: 0px;
      }
    }
  }
}
