@import '../helpers';

.list-settings {
  .form-top {
    max-width: 820px;
  }

  .notification-method {
    max-width: 380px;
  }

  .title {
    color: #152935;
    font-size: 16px;
    font-family: 'opensans-semibold';
  }

  .link-back-wrap {
    .link-back {
      cursor: pointer;
      color: #047cc0;
      font-size: 14px;
      font-family: 'opensans-semibold';

      &:hover {
        text-decoration: underline;
      }

      svg {
        margin-right: 9px;
        position: relative;
        top: 2px;
      }
    }

    .title {
      color: #152935;
      font-family: 'opensans-semibold';
      font-size: 16px;
    }
  }

  .settings-list-wrap {
    display: flex;

    & > .column {
      width: 200px;
      min-width: 200px;
    }
  }

  .section {
    border-top: 1px solid #e2eefa;
    padding: 25px;

    .input-group {
      margin-bottom: 10px;
    }

    .title {
      color: #2e404f;
      font-size: 16px;
      font-family: 'opensans-regular';
      margin-bottom: 10px;
    }

    .main-title {
      margin-top: 10px;
      margin-bottom: -10px;
    }

    label {
      color: #5a6872;
      font-size: 11px;
      line-height: 30px;
    }

    .input-group {
      display: flex;

      .name-column {
        width: calc(100% - 68px);
        padding-right: 20px;
      }

      .color-column {
        width: 68px;
      }
    }
  }

  .settings-list-wrap {
    color: #152935;
    font-size: 14px;

    .ant-checkbox-group {
      grid-template-columns: repeat(3, minmax(120px, 240px));
      display: grid;

      .ant-checkbox-inner {
        background-color: #f5f7fa;
        border: 1px solid #d9d9d9;
      }
    }

    .column {
      max-width: 240px;

      .title {
        color: #263745;
        font-size: 14px;
        font-family: 'opensans-semibold';
        margin-bottom: 10px;
      }

      .zh-icon-ring,
      .zh-icon-ring-no {
        height: 22px;
        width: 19px;
        vertical-align: middle;
        margin: 0 10px;
      }
      .zh-icon-ring-no {
        margin: 0 13px 0 7px;
      }

      ul {
        margin: 0 0 15px 0;
        padding: 0;

        li {
          padding: 0;
          color: #152935;
          font-size: 13px;
          list-style-type: none;

          label {
            font-size: 13px;
          }

          .ant-checkbox-inner:after {
            border: 2px solid #1890ff;
            border-top: 0;
            border-left: 0;
          }

          .ant-checkbox-wrapper {
            margin-right: 7px;
          }

          .ant-radio-wrapper-checked {
            & + svg {
              path {
                transition: all 0.3s ease-in-out;
                fill: #047cc0;
              }
            }
          }
        }
      }
    }
  }

  .list-footer {
    text-align: left;
    max-width: 920px;

    .btn {
      width: 102px;
      height: 38px;
      font-size: 14px;
      font-family: 'opensans-semibold';
    }

    .reset-btn {
      color: #363839;
      padding: 0px;

      &:hover {
        color: #ffff;
      }
    }

    .apply-btn {
      background-color: #047cc0;
      margin-left: 10px;
      padding: 0px;

      &:hover {
        background-color: #78b8dc;
      }
    }
  }
}

.color-picker {
  border: 1px solid #e2eefa;
  width: 62px;
  display: flex;

  .color {
    width: 36px;
    height: 36px;
    float: left;

    & > span {
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #e2eefa;
      margin: 7px 0 0 7px;

      &.blue {
        background-color: #047cc0;
      }
      &.pink {
        background-color: #ab47bb;
      }
      &.violet {
        background-color: #5c6bbf;
      }
      &.red {
        background-color: #dc3c2f;
      }
      &.aqua {
        background-color: #27c6da;
      }
      &.burgundy {
        background-color: #800020;
      }
      &.green {
        background-color: #5fb761;
      }
      &.light-green {
        background-color: #9dcc65;
      }
      &.orange {
        background-color: #ffa81f;
      }
      &.white {
        background-color: #f2f2f2;
      }
      &.yellow {
        background-color: #ffef58;
      }
      &.grey {
        background-color: #798f9c;
      }
      &.braun {
        background-color: #8d6e63;
      }
    }
  }

  .arrow {
    width: 25px;
    height: 36px;
    float: left;
    background-color: #f5f7fa;
    border: none;
    border-left: 1px solid #e2eefa;
    text-align: center;
    cursor: pointer;
    border-radius: 0;
    padding: 0;

    &:hover {
      background-color: #fafbfb;
    }
    &.active {
      background-color: #fafbfb;

      svg {
        transform: rotate(180deg);
        top: 8px;
        left: -1px;

        path {
          fill: #047cc0;
        }
      }
    }

    svg {
      position: relative;
      left: 2px;
      top: 12px;
    }
  }
}

.color-picker-title {
  color: #5e6061;
  font-size: 14px;
  font-family: 'opensans-regular';
  margin-bottom: 5px;
}
.color-picker-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 228px;

  li {
    display: inline-block;

    span {
      width: 36px;
      height: 36px;
      display: inline-block;
      vertical-align: top;
      border: 7px solid #fff;
      cursor: pointer;
      margin: 1px;

      &:hover {
        outline: 1px solid #047cc0;
      }

      &.blue {
        background-color: #047cc0;
      }
      &.pink {
        background-color: #ab47bb;
      }
      &.violet {
        background-color: #5c6bbf;
      }
      &.red {
        background-color: #dc3c2f;
      }
      &.aqua {
        background-color: #27c6da;
      }
      &.burgundy {
        background-color: #800020;
      }
      &.green {
        background-color: #5fb761;
      }
      &.light-green {
        background-color: #9dcc65;
      }
      &.orange {
        background-color: #ffa81f;
      }
      &.white {
        background-color: #f2f2f2;
      }
      &.yellow {
        background-color: #ffef58;
      }
      &.grey {
        background-color: #798f9c;
      }
      &.braun {
        background-color: #8d6e63;
      }
    }
  }
}
