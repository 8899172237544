.input-password-public {
  width: 100% !important;
  max-width: 380px !important;
  max-height: 48px !important;

  .ant-input {
    background: #ffff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    border: 1px solid #dae3eb;
    border-radius: 4px;
    height: 48px !important;
    padding: 4px 15px !important;
    box-sizing: border-box;
  }
}
