.product-access-list {
  background: #f5f8fb;
  border-top: 1px solid #eff6f8;
  padding: 20px 25px 25px;
  margin-top: 20px;

  .title {
    margin-bottom: 22.55px;
    margin-top: 5px;
    font-size: 20px;
    text-align: center;
  }

  > .packages-list {
    display: flex;
    justify-content: center;

    > .package-item {
      width: 100%;
      max-width: 400px;

      &:not(:last-child) {
        margin-right: 15px;
      }

      button {
        display: block;
        margin: 0 auto;
        text-align: center;
        padding: 7.5px 30px;
        margin-top: 10px;
        background: transparent;
        border-color: #010101;
        color: #010101;
        text-transform: uppercase;

        &:disabled {
          color: #ccc;
          border-color: #ccc;
        }
      }

      ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        background: #fff;
        padding: 15px;
        border-radius: 4px;
        border: 1px solid #e0e3e5;

        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }

          svg {
            margin-right: 7.5px;
            display: inline-block;
            font-size: 16px;
            vertical-align: -3px;

            path {
              fill: #80b374;
            }
          }
        }
      }
    }
  }
}

.zh-metrics {
  margin-top: 20px;
  border: 1px solid #e2eefa;

  .zh-metric-update {
    text-align: right;
    padding: 7.5px 20px;
    font-size: 12px;
    color: #ccc;
  }

  .zh-metrics-title {
    border-bottom: 1px solid #e2eefa;
    display: flex;

    > span {
      padding: 20px;
      color: #152935;
      font: 16px 'opensans-semibold';
      display: block;
      width: 100%;
      padding: 20px;

      &:not(:last-child) {
        border-right: 1px solid #e2eefa;
      }
    }
  }

  .zh-metric {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid #e2eefa;
    }

    &:nth-child(even) {
      background: rgb(250, 251, 253);
    }

    > div {
      padding: 20px;
      width: 100%;

      &:not(:last-child) {
        border-right: 1px solid #e2eefa;
      }

      .progress-bar-container {
        margin-top: 10px;
      }
    }
  }
}

.progress-bar-container {
  position: relative;

  .progress-indicator {
    margin-left: 15px;
    position: absolute;
    right: 0;
    color: #7a9cc8;
    font-size: 9px;
  }
}

.progress-bar-wrapper {
  background-color: #e9eff5;
  border-radius: 3px;

  .progress {
    height: 8px;
    border-radius: 3px;
    background-color: #7a9cc8;
    width: 0%;
    transition: 0.4s linear;
    transition-property: width, background-color;
  }
}

.package-price {
  margin: 15px 0;
  text-align: center;

  .package-price-currency {
    vertical-align: 15px;
    color: #262626;
    font-size: 20px;
  }

  .package-price-price {
    color: #262626;
    font-size: 45px;
    margin: 0 2.5px;
  }

  .package-price-frequency {
    color: #6a6f81;
    font-size: 20px;
  }
}

.metrics-wrapper {
  margin-bottom: 50px;

  .metric {
    border: 1px solid #eaf0f6;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0px 7px 27px 3px rgba(31, 105, 179, 0.05);

    &.metric-has-error {
      box-shadow: 0px 7px 27px 3px rgba(255, 31, 12, 0.2);
    }

    > .title {
      padding: 15px 25px;
      font-size: 18px;
    }

    > .subtitle {
      margin-top: -10px;
      padding: 0px 25px 10px 25px;
      font-size: 13px;
    }

    .metric-graphs {
      border-top: 1px solid #eff6f8;
      background: #f6f9fc;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #eff6f8;

      > div {
        padding: 15px 25px 25px;
        width: 25%;

        .title {
          display: block;
          font-size: 14px;
          text-transform: uppercase;
          color: #416a8d;
          margin-bottom: 10px;
        }

        .mertic-limit:last-child {
          margin-bottom: 0;
        }

        &:first-child {
          margin-right: 15px;
        }
      }
    }

    .metric-general {
      text-transform: uppercase;
      text-align: center;
      padding: 25px;
      background-color: #fbf9f2;

      > .title {
        display: block;
        color: #416a8d;
        margin-bottom: 15px;
        font-size: 16px;
      }

      .progress-indicator {
        position: absolute;
        right: 0;
        top: -14px;
        font-size: 9px;
        color: #ccc;
      }
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .progress-bar-container {
    float: inherit;
    width: 100%;
  }
}

.mertic-limit {
  margin-bottom: 15px;

  p {
    color: #686868;
    margin: 0 0 15px;
    padding: 0;
    font-size: 12px;
  }

  span {
    display: block;
    margin-bottom: 7.5px;
    font-size: 13px;
    &.mb-0 {
      margin-bottom: 0;
    }
  }

  .progress-indicator {
    position: absolute;
    right: 0;
    top: -14px;
    font-size: 9px;
    color: #ccc;
  }
}
.account-metric {
  .metric-graphs {
    .title {
      margin-bottom: 0 !important;
    }

    .sub-title {
      color: #686868;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .main-progressbar {
      margin-bottom: 10px;
    }

    > div {
      &:first-child {
        margin-right: 0 !important;
      }

      &:nth-child(even) {
        background: #fff;
      }

      &:not(:nth-child(4n)) {
        border-right: 1px solid #eaf0f6;
      }
      &:not(:nth-child(-n + 4)) {
        border-top: 1px solid #eaf0f6;
      }
    }
  }
}
