.wrapped-flex {
  display: flex;
  flex-wrap: wrap;
}

.billing-table {
  border: 1px solid #eaf0f6;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 7px 27px 3px rgba(31, 105, 179, 0.05);
  margin-bottom: 20px;

  .ant-table-thead > tr > th,
  .ant-table-footer {
    background: #f5f8fb;
  }
  .ant-table-placeholder {
    border-bottom: 1px solid #1fa3ff;
  }

  .table-above {
    padding: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #e8e8e8;
  }

  .title {
    font-size: 16px;
    color: #304251;
  }
  .zh-iconed-status,
  .ant-tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 45px;
  }
  .price-info {
    display: inline-block;
    vertical-align: middle;
  }
  .description {
    color: #304251;
    padding-top: 5px;
    font-size: 14px;
  }
}
.billing-header {
  @extend .wrapped-flex;
  padding: 15px 25px;
  justify-content: space-between;
}

.dropdown-head {
  margin-left: 10px;
}
.billing-subheader {
  background: #eaf0f6;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  .subheader-title {
    font-size: 14px;
    text-transform: uppercase;
  }
  .subheader-action {
    color: #2b89c1;
    font-size: 13px;
    cursor: pointer;
    text-transform: capitalize;
    user-select: none;
  }
}

.billing-body {
  border-top: 1px solid #eff6f8;

  &.wrapped-body {
    padding: 15px 25px;
  }

  .billing-table-details {
    border-bottom: 1px solid #e8e8e8;
    padding: 5px 0;
    background: #fff;

    + .table-grey {
      margin-top: 0 !important;
    }

    .dropdown {
      cursor: pointer;
      display: flex;
      width: 100%;
      align-items: center;
      padding: 12px 24px;

      &.active {
        border-bottom: solid 1px #a0def4;
      }

      i {
        font-size: 8px;
        vertical-align: middle;
        margin-bottom: 3px;
      }

      .dropdown-title {
        font-size: 14px;
        margin: 12px 6px;
        text-transform: uppercase;
      }

      .dropdown-details {
        margin-left: auto;
      }
    }

    .ant-table-content {
      margin: 0px;

      .ant-table-tbody > tr {
        &.ant-table-row-selected {
          background: #fafaf5;
        }

        &:hover > td {
          background-color: transparent;
        }

        > td {
          color: #45535e;
          background: transparent;
          font-size: 13px;
          padding: 12px 15px;

          &:nth-child(1) {
            padding-right: 0;
            padding-left: 0;

            > span {
              padding-left: 50px;
            }
          }
        }
        &:last-child > td {
          border-bottom: none;
        }
      }
    }
  }

  .billing-table-check {
    .ant-checkbox + span {
      flex: 1 auto;
    }
    .dropdown {
      .ant-checkbox {
        top: 0;
      }
      .dropdown-title {
        margin: 0;
        padding: 12px 6px;
      }
      .dropdown-details {
        float: right;
      }
    }
    &:after {
      clear: both;
    }
  }

  .billing-form {
    margin-top: 15px;
    .ant-form-horizontal {
      @extend .wrapped-flex;
      align-items: flex-end;
      .ant-form-item-label {
        padding-bottom: 0;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-row label {
        padding-bottom: 0.5rem;
      }
      .form-buttons {
        width: 310px;
        @extend .wrapped-flex;
        justify-content: space-between;

        button {
          margin-top: 10px;
          height: 38px;
          width: 150px;
          padding-top: 2px;
        }
      }
    }
    .ant-input {
      width: 310px;
      margin-right: 10px;
    }
  }
}

.billing-footer {
  text-align: right;
  padding: 0 12px 10px;
  font-size: 14px;

  &:after {
    clear: both;
    content: '';
    display: block;
  }

  .pagination {
    float: left;
    margin: 20px 15px;
  }

  button.zh-btn {
    text-transform: uppercase;
    padding: 9.5px 10px;
    font-size: 12px;
    margin: 5px;
    border-radius: 2px;
  }
}

.table-clean {
  .ant-table-thead > tr > th {
    background-color: #eaf0f6;
  }

  .ant-table-tbody tr:nth-child(2n) {
    background: #eff6f8;
  }
}

.billing-settings {
  @extend .wrapped-flex;
  align-items: baseline;

  &.right-position {
    justify-content: flex-end;
  }

  .settings-title {
    padding-right: 20px;
    color: #6c7486;
  }

  .separator {
    margin: 0 12px;
    color: #6c7486;
    font-size: 16px;
  }

  .types {
    @extend .wrapped-flex;

    .link {
      margin: 0;
    }

    .type {
      color: #2b89c1;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
      margin: 0;
    }
  }
}
