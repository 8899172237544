@import '../helpers';

// sidebar component
.sidebar {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  padding: 15px 0 0 0;
  border-right: 1px solid #e2eefa;

  .messages-menu {
    color: #244b63;
    font-size: 16px;
    font-family: 'opensans-semibold';
    border-right: 0;

    .ant-tree {
      li {
        span.ant-tree-switcher {
          width: 12px;
          line-height: 21px;
        }

        .ant-tree-node-content-wrapper {
          padding: 0 2px;
        }
      }
    }

    .tools {
      .zh-icon-monitoring-arrow {
        display: none;
      }

      & > span:hover {
        svg {
          path {
            fill: #047cc0 !important;
          }
        }
      }
    }

    a:hover {
      color: #047cc0;
    }

    svg {
      margin: 0 17px 0 0;
      position: relative;
      top: 2px;
      width: 18px;

      &.zh-icon-user-active {
        width: 13px;
        height: 15px;
        left: 2px;
      }

      path,
      rect,
      line,
      circle {
        transition: all 0.2s ease-in-out;
        stroke: #244b63;
        fill: transparent;
      }
    }

    .company-title {
      .ant-menu-submenu-title {
        height: auto;
        line-height: 16px;
        padding: 7px 18px !important;
      }
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      padding: 23px 18px !important;
      line-height: 0;
      height: auto;
      position: relative;

      &:hover {
        color: #fff;
      }
    }

    .ant-menu-item {
      padding-left: 18px !important;
      margin: 0;
      > a {
        color: #244b63;
      }
      > a:hover {
        color: #244b63;
      }
    }

    .ant-menu-submenu-arrow {
      transform: rotate(-90deg);
    }

    .ant-menu-submenu-arrow:before,
    .ant-menu-submenu-arrow:after {
      background: #ced9e4;
    }

    .ant-menu-sub {
      path,
      rect {
        stroke: transparent !important;
        fill: #244b63 !important;
      }

      .ant-menu-item {
        padding: 14px 18px !important;

        &:last-child {
          border-bottom: 1px solid #e2eefa;
        }
      }
    }

    .ant-menu-submenu:hover {
      background-color: #047cc0;
      color: #fff;

      .tag {
        background-color: #fff;
        color: #262626;
      }

      svg {
        path {
          stroke: #fff;
          fill: transparent;
        }
      }

      .ant-menu-sub {
        .tag {
          background-color: #047cc0;
          color: #fff;
        }
      }

      .ant-menu-submenu-arrow {
        transform: rotate(0deg);
      }
      .ant-menu-submenu-arrow:before,
      .ant-menu-submenu-arrow:after {
        background: #fff;
      }

      &.updates-wrap {
        background-color: #fff;
        color: #047cc0;

        .tag {
          background-color: #fff;
          color: #262626;
        }

        svg {
          path {
            stroke: #047cc0;
            fill: transparent;
          }
        }

        .ant-menu-sub {
          .tag {
            background-color: #047cc0;
            color: #047cc0;
          }
        }

        .ant-menu-submenu-arrow {
          transform: rotate(0deg);
          top: 49%;
        }
        .ant-menu-submenu-arrow:before,
        .ant-menu-submenu-arrow:after {
          background: #047cc0;
        }

        .ant-menu-submenu-title {
          color: #047cc0;

          svg {
            path,
            circle,
            polyline,
            line {
              stroke: #047cc0 !important;
            }
          }
        }
      }
    }

    .tag {
      display: inline-block;
      vertical-align: middle;
      padding: 0 6px;
      height: 20px;
      font-size: 14px;
      color: #fff;
      border-radius: 10px;
      background-color: #047cc0;
      line-height: 20px;
      position: absolute;
      right: 39px;
      transition: all 0.2s ease-in-out;
      top: 33%;
    }

    &.monitoring-menu {
      .ant-menu-submenu:hover {
        .ant-menu-submenu-title {
          .zh-icon-calendar-clock {
            path,
            circle,
            line,
            polyline {
              stroke: #fff;
            }
          }
        }
      }

      li {
        border-bottom: 1px solid #e2eefa;

        .link-list {
          color: rgba(0, 0, 0, 0.65);
        }

        &:first-child {
          border-top: 1px solid #e2eefa;
        }
        ul {
          li:last-child {
            border-bottom: none;
          }
        }
      }

      .zh-icon-unread-list-monitoring {
        path {
          stroke: #5d6b75 !important;
          fill: transparent !important;
        }
      }

      .ant-menu-sub {
        .active-item {
          background-color: #e6f7ff !important;
        }

        & > li {
          padding: 11px 68px 11px 18px !important;
          position: relative;

          &.company-title {
            padding: 0 !important;

            li {
              cursor: pointer;
            }

            &::before {
              display: none;
            }
          }

          &.add-list-item {
            padding: 11px 18px !important;
          }

          &.ant-menu-item-selected:after {
            display: none;
          }

          &.ant-menu-item-selected {
            a {
              color: #262626;
            }

            .tools {
              .gear,
              .plus {
                display: none;
              }

              .zh-icon-monitoring-arrow {
                display: block;
                margin-right: 8px;

                path {
                  fill: #047cc0 !important;
                }
              }
            }
          }

          &:before,
          &.blue:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 4px;
            background-color: #047cc0;
          }
          &.pink:before {
            background-color: #ab47bb;
          }
          &.violet:before {
            background-color: #5c6bbf;
          }
          &.red:before {
            background-color: #dc3c2f;
          }
          &.aqua:before {
            background-color: #27c6da;
          }
          &.burgundy:before {
            background-color: #800020;
          }
          &.green:before {
            background-color: #5fb761;
          }
          &.light-green:before {
            background-color: #9dcc65;
          }
          &.orange:before {
            background-color: #ffa81f;
          }
          &.white:before {
            background-color: #f2f2f2;
          }
          &.yellow:before {
            background-color: #ffef58;
          }
          &.grey:before {
            background-color: #798f9c;
          }
          &.braun:before {
            background-color: #8d6e63;
          }

          .tools {
            position: absolute;
            right: 0;
            top: 31%;

            svg {
              margin-right: 13px;

              path {
                stroke: #5d6b75;
              }
            }
          }

          svg {
            path {
              stroke: #5d6b75;
              fill: transparent;
            }
          }
          .zh-icon-task-list-approve {
            path {
              stroke: #5d6b75 !important;
              fill: transparent !important;
            }
          }
          .zh-icon-read-list {
            path {
              stroke: transparent !important;
              fill: #5d6b75 !important;
            }
          }
        }
      }

      .tag {
        background-color: transparent;
        color: #262626;
        border: 1px solid #dae3eb;
        line-height: 18px;
      }

      .add-list-item {
        padding: 15px !important;

        &:before {
          display: none !important;
        }

        .link-list {
          color: #047cc0;
          font-size: 13px;
          font-family: 'opensans-semibold';
          text-align: center;
          height: 28px;
          border: 1px dashed #c1e6fe;
          line-height: 26px;
          position: relative;
          transition: all 0.2s ease-in-out;
          border-radius: 2px;

          .zh-icon-plus {
            position: absolute;
            left: 10px;
            top: 6px;

            path {
              fill: #047cc0;
            }
          }

          &:hover {
            background-color: #8fc3e5;
            color: #fff;
            border-color: transparent;

            .zh-icon-plus {
              path {
                fill: #fff !important;
              }
            }
          }
        }
      }

      .date-picker-period {
        display: block;
        height: 38px;

        .ant-calendar-picker {
          display: flex;
          width: 48%;
          float: left;

          &:first-child {
            margin-right: 2%;
          }
          &:last-child {
            margin-left: 2%;
          }

          input {
            font-family: 'opensans-regular';
            font-style: italic;
          }
        }
      }

      .inner-list {
        padding: 0 15px;
      }

      .checks-wrap {
        padding: 15px 0;

        li {
          border: none !important;

          // .ant-tree-checkbox-inner {
          //   width: 18px;
          //   height: 18px;

          //   &::after {
          //     transform: scale(1.2);
          //   }
          // }

          // .ant-tree-checkbox-checked {
          //   & > .ant-tree-checkbox-inner {
          //     &::after {
          //       transform: rotate(45deg) scale(1.2);
          //     }
          //   }
          // }
        }
      }

      span.ant-tree-switcher.ant-tree-switcher_open:after {
        font-size: 14px;
      }

      .ant-tree-child-tree {
        li {
          padding: 4px 0;
          color: #152935;
          font-size: 13px;
          line-height: 21px;

          ul {
            span.ant-tree-checkbox {
              width: 16px;
              height: 16px;
              margin-top: 0;
            }
          }

          span.ant-tree-switcher {
            line-height: 23px;
          }
        }
      }
    }

    .ant-tree-node-selected {
      background-color: transparent !important;
    }

    .ant-tree-title {
      span {
        color: #152935 !important;
      }
    }
  }
}

.monitoring-popover {
  max-width: 260px;
  padding: 5px;
}
