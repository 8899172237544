@import '../../helpers';

$done-background: #20ba85;

.zh-filter-actions-tooltip {
  max-width: 450px;
  width: 100%;

  .ant-popover-inner {
    padding: 0;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.zh-tooltip-blue {
  .zh-icon-hint-icons-alert {
    font-size: 17px;
    fill: rgb(255, 192, 58);
  }
}

.zh-filter-actions {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;

  .action {
    position: relative;
    display: block;
    cursor: pointer;
    padding: (10px);
    padding-left: (45px);
    width: calc(50%);
    color: #263745;
    // @include latoSemibold;
    font-size: (14px);
    transition: 0.1s;

    @include mq($until: small-tablet) {
      padding-right: 30px;
    }

    &.has-hint {
      padding-right: (45px);
    }

    a {
      color: #263745;
    }

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px solid #e7f1fb;
    }
    &:nth-child(odd) {
      border-right: 1px solid #e7f1fb;
    }

    svg {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      font-size: 16px;

      &:first-child {
        left: 15px;

        path {
          fill: #547998;
        }
      }

      &:last-child {
        right: 15px;
        z-index: 10;
      }
    }

    .zh-icon-filter-icon-export {
      font-size: 19px;
    }

    &:hover {
      background-color: #eaf0f6;
      color: #047cc0;

      svg:first-child path {
        fill: #047cc0;
      }

      a {
        color: #083172;
      }
    }

    &.action-disabled {
      color: #ccc;
      border-color: #ccc;
      cursor: not-allowed;

      svg:first-child path {
        fill: #ccc !important;
      }

      svg:last-child {
        cursor: default;
      }
    }

    .zh-enrichment-tag {
      position: absolute;
      right: -1px;
      top: -1px;
      font-size: (9px);
      padding: (1px 5px);
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #fff;
      text-transform: uppercase;

      i {
        margin-right: 5px;
      }
    }

    &.zh-enrichment-dissolved {
      cursor: not-allowed;
      border-color: #2083be;
      color: #2083be;

      .zh-enrichment-tag {
        background: #2083be;
      }

      svg path {
        fill: #2083be;
      }
    }

    &.zh-enrichment-active {
      cursor: not-allowed;
      border-color: $done-background;
      color: $done-background;

      .zh-enrichment-tag {
        background: $done-background;
      }

      svg:first-child {
        path {
          fill: $done-background !important;
        }
      }
    }
  }
}
