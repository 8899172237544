@import '../helpers';

// header component
header {
  left: 0;
  top: 0;
  position: fixed;
  height: 50px;
  width: 100%;
  background: #0b0c0c;
  z-index: 1051;

  .menu-center {
    display: flex;
    position: absolute;
    width: calc(100% - 420px);
    max-width: 1359px;
    left: calc(50%);
    top: 50%;
    transform: translate(-50%, -50%);

    @include mq($until: small-desktop) {
      width: calc(100% - 225px);
      left: calc(50% - 60px);
    }

    .ant-tabs-tab {
      margin: 0 !important;
    }

    .search-box {
      margin-top: 7px;
      width: 100%;
      max-width: 377px;

      @include mq($until: small-desktop) {
        display: none;
      }
    }

    .top-menu-link {
      display: inline-block;
      vertical-align: middle;
      height: 50px;
      padding: 15px 30px;
      transition: 0.3s ease;
      color: #ffffff;
    }

    .menu-links {
      padding: 0;
      margin: 0 auto;
      padding-right: 25px;
      width: 100%;
      z-index: 0;

      .ant-tabs-tab {
        padding: 0;
      }

      .ant-tabs-tab-btn-disabled {
        color: rgba(255, 255, 255, 0.25) !important;
      }

      .ant-tabs-tab-arrow-show {
        color: #fff;
      }

      a {
        color: #fff;
        padding: 14.5px 17.5px;
        display: block;
        margin: 0;
        font: 14px 'opensans-regular';
        transition: 0.3s ease;

        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          margin-bottom: 4px;
          font-size: 19px;

          path {
            stroke: #fff;
            fill: none;
          }
        }

        &.disabled-link {
          color: #fff;

          &:hover {
            color: #fff;
          }
        }

        &:hover {
          color: #fff;
          background: #2c3e49;

          svg path {
            stroke: #fff;
          }
        }

        &.active {
          color: #152935;
          background: #fff;

          svg path {
            stroke: #047cc0;
          }
        }
      }
    }

    .menu-navigation-button {
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      padding: 0;
      border: none;
      margin-right: 30px;
      background-color: transparent;
      border-radius: 0;

      svg {
        left: 15.5px;
      }

      &:hover {
        background-color: #244b63;
      }

      &.active {
        background-color: #fff;

        svg {
          path {
            fill: #047cc0;
          }
        }
      }

      .zh-icon-menu-navigation {
        width: 26px;
        height: 23px;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        path {
          stroke: #fff;
        }
      }

      .zh-icon-menu-navigation-close {
        width: 20px;
        height: 20px;

        path {
          stroke: #000;
        }
      }
    }
  }

  .menu-right {
    padding: 0;
    margin: 0;

    li {
      float: left;
      position: relative;
      height: 50px;
      min-width: 50px;
      padding: 15px;
      margin-left: 19px;
      transition: 0.3s ease;
      cursor: pointer;
      @extend %clearfix;
      list-style: none;
      color: #ffffff;

      &.active {
        background: #244b63;
      }

      &:hover {
        background: #346f93;

        .zh-icon-alarm-bell {
          path {
            fill: rgb(255, 255, 255);
          }
        }
      }

      .svg-container {
        width: 22px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .zh-icon-updates-normal,
      .zh-icon-user-active,
      .zh-icon-user-normal,
      .zh-icon-alarm-bell {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 19px;
        fill: rgb(255, 255, 255);
      }
      .zh-icon-alarm-bell {
        fill: transparent;
        width: 20px;
        height: 20px;

        path {
          stroke: #fff;
        }
      }
    }
  }
}

.notification-svg,
.user-svg {
  transition: 0.3s ease;

  .notification-active,
  .zh-icon-user-active {
    opacity: 0;
  }

  &:hover .notification-active,
  &:hover .zh-icon-user-active {
    opacity: 1;
  }
  &:hover {
    .zh-icon-alarm-bell {
      fill: #fff;
    }
  }
  .zh-icon-user-normal,
  .zh-icon-user-active {
    font-size: 20px;
    fill: white;
  }
}

.notification-svg {
  &.disabled {
    cursor: not-allowed;

    svg path {
      fill: #4c4d4d;
    }

    .dot > div {
      background: #ccc;
    }

    &:hover {
      background: #1e3442;
    }
  }

  .dot {
    position: absolute;
    left: 65%;
    top: 37.5%;
    transform: translate(-50%, -50%);
    background: #152935;
    width: 12px;
    height: 10px;
    border-radius: 5px;

    > div {
      background: #f86b45;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      @extend %margin-absolute;
    }
  }

  &:hover .dot {
    background: #346f93;
  }
}

.menu-left {
  overflow: hidden;
  padding: 0;
  margin: 0;

  .logo-wrap {
    width: 155px;

    @include mq($until: small-desktop) {
      width: auto;
    }

    a {
      @include mq($until: small-desktop) {
        max-width: 38px;
        overflow: hidden;
        display: block;
      }
    }
  }

  li {
    float: left;
    position: relative;
    height: 50px;
    min-width: 50px;
    list-style: none;
    transition: 0.3s ease;
    cursor: pointer;
    @extend %clearfix;

    &:first-child {
      @include mq($until: small-desktop) {
        display: none;
      }
    }

    &.menu-left-no-filter {
      background: #2c3d49 !important;
      cursor: not-allowed;

      svg path {
        fill: #ccc;
      }
    }

    .zh-icon-logo-i {
      font-size: 24px;
      fill: white;
      width: 18px;
      height: 24px;
      @extend %margin-absolute;
    }

    .filter-close {
      background: #fff;
      width: 60px;
      height: 50px;
      z-index: 3;
      position: relative;
      transition: 0.5s ease;
      // animation: filter-close 0.2s forwards;

      .zh-icon-user-section-close-it {
        position: absolute;
        font-size: 16px;
        @extend %margin-absolute;
        fill: #244b63;
        transition: 0.3s ease;
      }

      &:hover .zh-icon-user-section-close-it {
        fill: #346f93;
      }
    }

    &:first-child {
      transition: 0.3s ease;
      border-right: 1px solid #313434;

      &:hover {
        background: #244b63;
      }
    }

    > a .logo-svg {
      height: 50px;
      padding: 5px 15px;
      font-size: 100px;
      svg {
        fill: #fff;
      }
    }
  }
}

.ant-dropdown-menu.shortcuts-menu {
  font-family: 'opensans-regular';
  font-size: 14px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  border: 1px solid #cbd6e2;
  border-radius: 4px;
  overflow: hidden;

  .shortcuts-menu-item {
    padding: 0 10px 0 0;
    position: relative;

    &.active {
      path {
        stroke: #263745;
      }

      a {
        color: #263745;
        font-family: 'opensans-semibold';
      }
    }

    &:hover {
      stroke: #047cc0;

      a {
        color: #047cc0;
      }
    }

    .zh-icon,
    svg path {
      fill: transparent;
      stroke: #5e6061;
    }
    .zh-icon-menu-check {
      path {
        fill: #047cc0;
        stroke: transparent;
      }
    }

    &:hover {
      background-color: #fff;
    }

    a {
      display: inline-block;
      padding: 10px 0 10px 58px;
      margin: 0;
      width: 249px;
      // border-right: 1px dashed #cacfd3;
      color: #5e6061;

      &:hover {
        svg {
          path {
            stroke: #047cc0;
          }
        }
      }

      svg {
        position: absolute;
        left: 20px;
        top: 13px;
        height: 18px;
      }
    }

    .custom-checkbox {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 48px;
      text-align: center;
      cursor: pointer;

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(-40%) translateY(20%);
      }

      // &:hover {
      //   background-color: #c5e5f7;
      // }
    }
  }
}
