@import '../helpers';

// search instruction component
.search-instruction {
  font-family: 'opensans-regular';
  display: flex;
  margin: 40px -60px 0 -60px;
  border-top: 1px solid #e2eefa;
  border-bottom: 1px solid #e2eefa;
  // min-height: 1200px;

  .title {
    color: #262626;
    font-family: 'opensans-semibold';
  }

  .left-column {
    width: 50%;
    float: left;
    background-color: #f5f8fb;
    border-right: 1px solid #e2eefa;
    padding: 30px 45px;

    .note-img {
      width: calc(100% + 30px);
    }
  }

  .right-column {
    width: 50%;
    float: left;
    padding: 30px 45px;

    .title {
      font-size: 24px;
    }

    .sub-title {
      margin-top: 20px;
    }

    .sub-title {
      font-size: 14px;
      color: #5a6872;

      &.active {
        color: #047cc0;
      }
    }

    .instruction-list {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 15px 0;

        li {
          border-bottom: 1px dashed #c9cfd3;
          padding: 12px 10px 12px 0;
          color: #262626;
          line-height: 19px;
          font-size: 15px;
          width: 100%;

          &:last-child {
            border-bottom: none;
          }

          .text {
            display: inline-block;
            vertical-align: middle;
            margin-left: 15px;
            width: calc(100% - 15px - 34px);

            .blue {
              svg {
                vertical-align: middle;
                margin: 2px 0 0 5px;
              }
            }
          }

          .num {
            font-size: 18px;
            font-family: 'opensans-semibold';
            display: inline-block;
            vertical-align: middle;
            border-radius: 100%;
            text-align: center;
            border: 1px solid #dbe4eb;
            width: 34px;
            height: 34px;
            line-height: 31px;
          }
        }
      }
    }

    .notification {
      background-color: #faf9f5;
      border: 1px solid #f6e7d1;
      border-radius: 4px;
      margin: 10px 0;
      color: #262626;
      font-size: 14px;
      padding: 15px 13px 12px 13px;

      .note {
        background-color: #fda862;
        color: #fff;
        font-size: 14px;
        border-radius: 4px;
        margin-right: 9px;
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        padding: 0 9px;
        line-height: 23px;
        font-family: 'opensans-regular';
        margin-top: -4px;
      }
    }
  }

  .search-field-group-instruction {
    margin-top: 7px;

    .country-btn {
      width: 100%;
      border-radius: 4px;
    }

    .countries-popup-overlay {
      padding: 0 20px;
    }
  }
}

@media (max-width: 780px) {
  .container.search-container {
    margin-bottom: 0;

    .search-instruction {
      margin-left: 0;
      margin-right: 0;
      display: block;
      min-height: auto;

      .left-column {
        display: none;
      }

      .right-column {
        width: 100%;
        padding: 30px 0;

        .title {
          font-size: 20px;
        }
      }
    }
  }
}
