@import '../helpers';

.container.team-container {
  padding-top: 50px;
  padding-bottom: 0px;
  min-height: 433px;
}

.team-page {
  .table-items {
    margin-top: -24px;
  }

  .action-buttons {
    width: 100px;
    svg {
      top: 10px !important;
      left: -10px !important;
    }
  }

  .table-above {
    .pull-left {
      h1 {
        font-size: 16px;
        color: #152935;
        font-family: 'opensans-semibold';
      }
    }

    .pull-right {
      .ant-btn {
        padding: 0 20px 0 38px;
        height: 36px;
        color: #363839;
        font-size: 14px;
        font-family: 'opensans-regular';

        .zh-icon-people-plus {
          width: 25px;
          height: 14px;
          left: 6px;
          top: 18px;

          path {
            transition: all 0.2s ease-in-out;
          }
        }

        &:hover {
          color: #fff;
          background-color: #78b8dc;
          border-color: #78b8dc;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .add-person-btn {
    background-color: #047cc0;
    font-size: 14px;
    font-family: 'opensans-regular';
    padding: 0 20px 0 38px;
    height: 36px;
    color: #fff;
    border: none;

    .zh-icon-profile-user-plus {
      width: 25px;
      height: 14px;
      left: 8px;
      top: 19px;

      path {
        transition: all 0.2s ease-in-out;
      }
    }

    &:hover {
      color: #fff;
      background-color: #78b8dc;
      border-color: #78b8dc;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .in-two-rows {
    padding-left: 0 !important;
  }

  .company-item {
    td {
      border-bottom: 1px solid #1fa3ff;
    }
  }

  .company_container {
    margin: 15px 0px;
  }

  .company_header {
    border-top: 1px solid #eaf0f6;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;

    .action-buttons {
      text-align: right;
    }
  }

  .company-items-row {
    border-top: 1px solid #1fa3ff;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .company-items-row:last-child {
    border-bottom: 1px solid #1fa3ff;
  }

  .more {
    padding-top: 12px;

    .popover-more {
      margin-right: 5px;
    }
  }
}

.ant-popover-inner-content {
  padding: 12px 8px;
}
.ant-popover-arrow {
  display: none;
}
.pop-action {
  padding: 7.5px 15px 7.5px 10px;
}

.popover-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
  &--button {
    display: none;
  }

  svg {
    position: relative;
    right: -10px;
    fill: #047cc0 !important;
  }
}

@include mq($until: tablet) {
  .zh-user-cart {
    display: flex;
    flex-direction: column;
    > .text {
      margin-top: 10px;
      margin-left: 0;
    }
  }
  .form-inline {
    flex-direction: column;
    > a > button {
      width: 100%;
      margin-left: 0;
    }
    > .ant-input-search {
      width: inherit;
    }
  }
  .table-above > .pull-left {
    float: none !important;
  }
  .table-above > .pull-right {
    float: none !important;
  }
  .ant-input-search-button {
    margin-left: 0 !important;
  }
  .text > small {
    margin-bottom: 10px;
  }

  .popover-more--button {
    font: 13px 'opensans-semibold';
    color: #4b7f9f;
    margin-left: 10px;
    display: block;
  }
  .ant-popover-placement-top {
    left: 20px !important;
  }
}
